import React, { useState, useEffect } from 'react';
import { config } from '../../../config';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Grid,
  Alert,
  CircularProgress,
  Snackbar,
  Card,
  CardContent,
  Switch,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { FileText } from 'lucide-react';
import PageHeader from '../../PageHeader';
import { useAuth } from '../../../context/AuthContext';
import apiClient from '../../../utils/apiUtils';

export default function ProductReviewsPage() {
  // Replace useAuth0 with useAuth
  const { authenticatedRequest, error: authError } = useAuth();
  
  // Keep all existing state management
  const [availableModels, setAvailableModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const [title, setTitle] = useState('');
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [includeDisclaimer, setIncludeDisclaimer] = useState(true);
  const [disclaimerText, setDisclaimerText] = useState(
    "As an Amazon Associate I earn from qualifying purchases."
  );
  const [disclaimerPosition, setDisclaimerPosition] = useState('top');
  const [linkHeadings, setLinkHeadings] = useState(true);
  const [includeCheckButton, setIncludeCheckButton] = useState(true);
  const [buttonColor, setButtonColor] = useState('#FF9900');
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [productAsins, setProductAsins] = useState('');
  const [validatedProducts, setValidatedProducts] = useState([]);
  const [includeCarousel, setIncludeCarousel] = useState(true);
  const [includeProductBoxes, setIncludeProductBoxes] = useState(true);
  const [includeProsConsSection, setIncludeProsConsSection] = useState(true);
  const [includeFeaturedImage, setIncludeFeaturedImage] = useState(false);
  const [featuredImageModel, setFeaturedImageModel] = useState('fal-ai/flux/schnell');
  const [productImageType, setProductImageType] = useState('none');
  const [availableImageModels, setAvailableImageModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [searchMode, setSearchMode] = useState('asin');
  const [searchQuery, setSearchQuery] = useState('');
  const [productCount, setProductCount] = useState(5);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('us-west-1');

  // Effects
  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (selectedSite) {
      fetchCategories();
    }
  }, [selectedSite]);

  // Updated fetch functions using authenticatedRequest
  const fetchInitialData = async () => {
    try {
      await authenticatedRequest(async (token) => {
        apiClient.setAuthToken(token);
        
        // Fetch sites, models, and image models in parallel
        const [sitesResponse, modelsResponse, imageModelsResponse] = await Promise.all([
          apiClient.getWordPressSites(),
          apiClient.getAvailableModels(),
          apiClient.getAvailableImageModels()
        ]);

        setSites(sitesResponse);
        if (sitesResponse.length > 0) {
          setSelectedSite(sitesResponse[0].id);
        }

        setAvailableModels(modelsResponse);
        if (modelsResponse.length > 0) {
          setSelectedModel(modelsResponse[0].value);
        }

        setAvailableImageModels(imageModelsResponse);
      });
    } catch (err) {
      setError('Failed to load initial data');
      console.error('Error fetching initial data:', err);
    }
  };

  const fetchCategories = async () => {
    try {
      await authenticatedRequest(async (token) => {
        apiClient.setAuthToken(token);
        const categoriesData = await apiClient.getCategories(selectedSite);
        setCategories(categoriesData);
      });
    } catch (err) {
      setError('Failed to load categories');
      console.error('Error fetching categories:', err);
    }
  };

  const handleSearch = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await authenticatedRequest(async (token) => {
        apiClient.setAuthToken(token);
        const response = await apiClient.searchProducts({
          query: searchQuery,
          maxResults: productCount,
          region: selectedRegion
        });
        
        setSearchResults(response.products);
        if (response.products.length > 0) {
          setSelectedProducts(response.products);
          setProductAsins(response.products.map(p => p.asin).join('\n'));
        }
      });
    } catch (err) {
      setError(err.message || 'Failed to search products');
    } finally {
      setIsLoading(false);
    }
  };

  const validateAndGatherProductData = async (asins) => {
    try {
      const response = await authenticatedRequest(async (token) => {
        apiClient.setAuthToken(token);
        return await apiClient.validateAndGatherProducts({ asins });
      });
      return response.products;
    } catch (error) {
      throw new Error(error.message || 'Failed to gather product data');
    }
  };

  const handleGenerateReview = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
  
    try {
      const asins = searchMode === 'search' 
        ? selectedProducts.map(p => p.asin)
        : productAsins.split('\n').map(asin => asin.trim()).filter(asin => asin);

      if (asins.length === 0) {
        throw new Error('Please enter at least one product ASIN');
      }

      if (asins.length > 10) {
        throw new Error('Maximum 10 products allowed');
      }
  
      const productData = await validateAndGatherProductData(asins);
  
      await authenticatedRequest(async (token) => {
        apiClient.setAuthToken(token);
        
        const response = await apiClient.generateProductReview({
          title,
          siteId: selectedSite,
          category: selectedCategory,
          model: selectedModel,
          products: productData,
          settings: {
            disclaimer: {
              include: includeDisclaimer,
              text: disclaimerText,
              position: disclaimerPosition
            },      
            productDisplay: {
              linkHeadings: true,
              includeCheckButton,
              buttonColor,
              includeDetailedOverview: true,
              paragraphsPerProduct: 3
            },
            affiIntegration: {
              includeCarousel,
              includeProductBoxes
            },
            includeProsConsSection: true,
            formatting: {
              includeIntroduction: true,
              useProductComparisons: true,
              includeUserExperiences: true
            },
            images: {
              featuredImage: {
                enabled: includeFeaturedImage,
                model: featuredImageModel
              },
              productImages: {
                type: productImageType
              }
            }
          }
        });

        setNotification({
          open: true,
          message: 'Product review generated successfully!',
          severity: 'success'
        });
      });
  
    } catch (error) {
      console.error('Error generating review:', error);
      setError(error.message || 'Failed to generate product review');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };
  return (
    <Box sx={{ p: 4 }}>
      <PageHeader 
        title="Create An Amazon Product Roundup Review" 
        icon={<FileText size={24} color="#E04C16" />} 
      />
      <Paper sx={{ p: 3 }}>
        <Typography variant="h5" gutterBottom>
          Product Review Generator
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}
        
        <form onSubmit={handleGenerateReview}>
          <Grid container spacing={3}>
            {/* Title Input */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Review Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                helperText="Enter a descriptive title for your product review"
              />
            </Grid>

            {/* WordPress Site Selection */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel>WordPress Site</InputLabel>
                <Select
                  value={selectedSite}
                  onChange={(e) => setSelectedSite(e.target.value)}
                >
                  {sites.map(site => (
                    <MenuItem key={site.id} value={site.id}>
                      {site.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Category Selection */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel>Category</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  {categories.map(category => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Amazon Disclaimer Section */}
            <Grid item xs={12}>
              <Card variant="outlined" sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Amazon Disclaimer Settings
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeDisclaimer}
                        onChange={(e) => setIncludeDisclaimer(e.target.checked)}
                      />
                    }
                    label="Include Amazon Affiliate Disclaimer"
                  />
                  {includeDisclaimer && (
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        label="Disclaimer Text"
                        value={disclaimerText}
                        onChange={(e) => setDisclaimerText(e.target.value)}
                        sx={{ mb: 2 }}
                      />
                      <FormControl fullWidth>
                        <InputLabel>Disclaimer Position</InputLabel>
                        <Select
                          value={disclaimerPosition}
                          onChange={(e) => setDisclaimerPosition(e.target.value)}
                        >
                          <MenuItem value="top">Top of Article</MenuItem>
                          <MenuItem value="bottom">Bottom of Article</MenuItem>
                          <MenuItem value="after-intro">After Introduction</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid container spacing={2}>
  <Grid item xs={12}>
    <FormControlLabel
      control={
        <Switch
          checked={includeFeaturedImage}
          onChange={(e) => setIncludeFeaturedImage(e.target.checked)}
          disabled={isLoading}
        />
      }
      label="Include Featured Image"
    />
  </Grid>

  {includeFeaturedImage && (
    <Grid item xs={12}>
      <FormControl fullWidth disabled={isLoading}>
        <InputLabel>Featured Image Model</InputLabel>
        <Select
          value={featuredImageModel}
          onChange={(e) => setFeaturedImageModel(e.target.value)}
          label="Featured Image Model"
        >
          {availableImageModels.map((model) => (
            <MenuItem key={model.value} value={model.value}>
              {model.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )}

  <Grid item xs={12}>
    <FormControl fullWidth disabled={isLoading}>
      <InputLabel>Product Image Type</InputLabel>
      <Select
        value={productImageType}
        onChange={(e) => setProductImageType(e.target.value)}
        label="Product Image Type"
      >
        <MenuItem value="none">No Product Images</MenuItem>
        <MenuItem value="amazon">Amazon Product Images</MenuItem>
        <MenuItem value="generated">AI Generated Images</MenuItem>
      </Select>
    </FormControl>
  </Grid>
</Grid>
            {/* Product ASINs Input */}
<Grid item xs={12}>
  <Card variant="outlined" sx={{ mb: 3 }}>
    <CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">
          Product Selection
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={searchMode === 'search'}
              onChange={() => setSearchMode(searchMode === 'search' ? 'asin' : 'search')}
            />
          }
          label={searchMode === 'search' ? 'Search Mode' : 'Manual ASIN Mode'}
        />
      </Box>

      {searchMode === 'search' ? (
        <>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Search Query"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="e.g., best gaming chairs under $200"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                type="number"
                label="Number of Products"
                value={productCount}
                onChange={(e) => setProductCount(Math.min(10, Math.max(1, parseInt(e.target.value) || 1)))}
                inputProps={{ min: 1, max: 10 }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                onClick={handleSearch}
                disabled={isLoading || !searchQuery}
                sx={{ height: '100%' }}
                fullWidth
              >
                {isLoading ? <CircularProgress size={24} /> : 'Search'}
              </Button>
            </Grid>
          </Grid>

          {/* Update the table structure in ProductReviewsPage.js */}
{searchResults.length > 0 && (
  <TableContainer component={Paper} sx={{ mt: 2 }}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Product Title</TableCell>
          <TableCell>ASIN</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {searchResults.map((product) => (
          <TableRow key={product.asin}>
            <TableCell>
              <a 
                href={`https://amazon.com/dp/${product.asin}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ 
                  color: '#0066c0',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                {product.title}
              </a>
            </TableCell>
            <TableCell>{product.asin}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)}
        </>
      ) : (
        <TextField
          fullWidth
          multiline
          rows={4}
          label="Enter Amazon ASINs (one per line, max 10)"
          value={productAsins}
          onChange={(e) => setProductAsins(e.target.value)}
          helperText="Enter each Amazon ASIN on a new line"
          required
        />
      )}
    </CardContent>
  </Card>
</Grid>

            {/* Product Display Settings */}
            <Grid item xs={12}>
              <Card variant="outlined" sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Product Display Settings
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={linkHeadings}
                            onChange={(e) => setLinkHeadings(e.target.checked)}
                          />
                        }
                        label="Link Product Headings to Amazon"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={includeCheckButton}
                            onChange={(e) => setIncludeCheckButton(e.target.checked)}
                          />
                        }
                        label="Include 'Check on Amazon' Button"
                      />
                    </Grid>
                    {includeCheckButton && (
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Button
                            onClick={() => setShowColorPicker(!showColorPicker)}
                            variant="contained"
                            style={{ backgroundColor: buttonColor }}
                          >
                            Choose Button Color
                          </Button>
                          {showColorPicker && (
                            <Box sx={{ position: 'absolute', zIndex: 2 }}>
                              <HexColorPicker
                                color={buttonColor}
                                onChange={setButtonColor}
                              />
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Affi.ai Integration */}
            <Grid item xs={12}>
              <Card variant="outlined" sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Affi.ai Integration
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={includeCarousel}
                            onChange={(e) => setIncludeCarousel(e.target.checked)}
                          />
                        }
                        label="Include Product Carousel"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={includeProductBoxes}
                            onChange={(e) => setIncludeProductBoxes(e.target.checked)}
                          />
                        }
                        label="Include Individual Product Boxes"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            {/* Pros and Cons Section */}
            <Grid item xs={12}>
              <Card variant="outlined" sx={{ mb: 3 }}>
                <CardContent>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={includeProsConsSection}
                        onChange={(e) => setIncludeProsConsSection(e.target.checked)}
                      />
                    }
                    label="Include Pros and Cons Sections"
                  />
                </CardContent>
              </Card>
            </Grid>
            {/* AI Model Selection */}
            <Grid item xs={12}>
              <Card variant="outlined" sx={{ mb: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    AI Model Selection
                  </Typography>
                  <FormControl fullWidth required>
                    <InputLabel>Select AI Model</InputLabel>
                    <Select
                      value={selectedModel}
                      onChange={(e) => setSelectedModel(e.target.value)}
                    >
                      {availableModels.map(model => (
                        <MenuItem key={model.value} value={model.value}>
                          {model.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={isLoading}
                fullWidth
              >
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Generate Product Review"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

