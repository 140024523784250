import OpenAI from 'openai';
import { Anthropic } from '@anthropic-ai/sdk';

class AiClient {
  constructor() {
    this.openaiClient = null;
    this.anthropicClient = null;
    this.openrouterClient = null;
    this.modelConfigs = new Map();
  }

  setApiKeys(keys) {
    console.log("Setting API keys:", {
      hasOpenAI: !!keys.openai,
      hasAnthropic: !!keys.anthropic,
      hasOpenRouter: !!keys.openrouter
    });

    if (keys.openai) {
      try {
        this.openaiClient = new OpenAI({
          apiKey: keys.openai,
          dangerouslyAllowBrowser: true
        });
        console.log("OpenAI client initialized");
      } catch (error) {
        console.error("OpenAI client initialization failed:", error);
      }
    }
    
    if (keys.anthropic) {
      try {
        this.anthropicClient = new Anthropic({
          apiKey: keys.anthropic
        });
        console.log("Anthropic client initialized");
      } catch (error) {
        console.error("Anthropic client initialization failed:", error);
      }
    }

    if (keys.openrouter) {
      try {
        this.openrouterClient = new OpenAI({
          apiKey: keys.openrouter,
          baseURL: "https://openrouter.ai/api/v1",
          defaultHeaders: {
            "HTTP-Referer": window.location.origin,
            "X-Title": "Content Generation App",
          },
          dangerouslyAllowBrowser: true
        });
        console.log("OpenRouter client initialized");
      } catch (error) {
        console.error("OpenRouter client initialization failed:", error);
      }
    }
  }

  
  async enhanceImagePrompt(basicPrompt, style = 'photorealistic') {
    const stylePrompts = {
      photorealistic: "Create a photorealistic image generation prompt based on the given text.\n" +
        "Focus on:\n" +
        "- Hyper-realistic details of the main subject\n" +
        "- Natural lighting and real-world environment\n" +
        "- Precise physical characteristics and textures\n" +
        "- Authentic environmental context\n" +
        "- True-to-life colors and materials\n\n" +
        "Guidelines:\n" +
        "- Describe as if capturing a high-end professional photograph\n" +
        "- Include natural lighting conditions (golden hour, soft daylight, etc.)\n" +
        "- Specify real camera angles (eye-level, aerial view, etc.)\n" +
        "- Add depth of field and focal point details\n" +
        "- Emphasize photographic composition (rule of thirds, leading lines)\n" +
        "- Include real-world scale and perspective\n" +
        "- Specify actual weather conditions and time of day\n" +
        "- Focus on documentary/wildlife photography style\n" +
        "- Avoid artistic or illustrated effects\n" +
        "- Keep it under 100 words\n" +
        "- Specify not to include any text in the final image\n" +
        "- Use single, detailed sentence\n\n" +
        "Style keywords to include: photorealistic, 8k uhd, high detail, natural lighting, professional photography, documentary style",
  
      illustration: "Create an artistic illustration prompt based on the given text.\n" +
        "Focus on:\n" +
        "- Stylized artistic interpretation\n" +
        "- Creative color palettes and harmonies\n" +
        "- Artistic composition and layout\n" +
        "- Imaginative scene setting\n" +
        "- Unique artistic perspective\n\n" +
        "Guidelines:\n" +
        "- Describe as if commissioning a professional illustration\n" +
        "- Specify artistic style (digital, watercolor, etc.)\n" +
        "- Include lighting and atmosphere details\n" +
        "- Define mood and emotional tone\n" +
        "- Describe artistic techniques to use\n" +
        "- Specify composition and focal points\n" +
        "- Include artistic references if relevant\n" +
        "- Keep it under 100 words\n" +
        "- Specify not to include text\n" +
        "- Use single, detailed sentence\n\n" +
        "Style keywords: digital art, illustration, artistic composition, creative interpretation",
  
      "3d_render": "Create a 3D rendering prompt based on the given text.\n" +
        "Focus on:\n" +
        "- 3D modeling details and geometry\n" +
        "- Material properties and textures\n" +
        "- Lighting and rendering quality\n" +
        "- Camera perspective and composition\n" +
        "- Environmental details and atmosphere\n\n" +
        "Guidelines:\n" +
        "- Describe as if creating a professional 3D render\n" +
        "- Specify materials and their properties (metallic, glossy, matte)\n" +
        "- Include lighting setup details (HDRI, studio, natural)\n" +
        "- Define camera angle and position\n" +
        "- Describe post-processing effects\n" +
        "- Consider depth and atmospheric effects\n" +
        "- Specify render engine style\n" +
        "- Keep it under 100 words\n" +
        "- Specify not to include text\n" +
        "- Use single, detailed sentence\n\n" +
        "Style keywords: 3D render, octane render, physically based materials, global illumination"
    };
  
    try {
      const modelConfig = this.modelConfigs.get(this.getAuxiliaryModel());
      if (!modelConfig) {
        throw new Error('No auxiliary model configured');
      }
  
      const systemPrompt = stylePrompts[style] || stylePrompts.photorealistic;
      
      let response;
      switch (modelConfig.provider.toLowerCase()) {
        case 'openai':
          if (!this.openaiClient) throw new Error('OpenAI API key not configured');
          response = await this.openaiClient.chat.completions.create({
            model: modelConfig.model_name,
            messages: [
              {
                role: "system",
                content: systemPrompt
              },
              {
                role: "user",
                content: `Create a ${style} image generation prompt for: ${basicPrompt}`
              }
            ],
            temperature: 0.7,
            max_tokens: 150
          });
          return response.choices[0].message.content.trim();
  
        case 'anthropic':
          if (!this.anthropicClient) throw new Error('Anthropic API key not configured');
          response = await this.anthropicClient.messages.create({
            model: modelConfig.model_name,
            temperature: 0.7,
            max_tokens: 150,
            system: systemPrompt,
            messages: [{
              role: "user",
              content: `Create a ${style} image generation prompt for: ${basicPrompt}`
            }]
          });
          return response.content[0].text.trim();
  
        default:
          throw new Error(`Unsupported model provider: ${modelConfig.provider}`);
      }
    } catch (error) {
      console.error('Error enhancing image prompt:', error);
      return basicPrompt;
    }
  }

  updateModelConfigs(models) {
    this.modelConfigs.clear();
    if (Array.isArray(models)) {
      models.forEach(model => {
        const config = {
          id: model.value,
          provider: model.provider,
          model_name: model.value,
          maxTokens: model.max_tokens || 4000,
          temperature: model.temperature || 0.7,
          system_prompt: model.system_prompt
        };
        this.modelConfigs.set(model.value, config);
      });
      console.log('Model configurations updated:', [...this.modelConfigs.entries()]);
    } else {
      console.error('Invalid models data:', models);
      throw new Error('Models must be provided as an array');
    }
  }

  async generateArticleContent(params) {
    const { title, modelId, customPrompt } = params;
    
    try {
      const modelConfig = this.modelConfigs.get(modelId);
      if (!modelConfig) {
        throw new Error(`Model configuration not found for ID: ${modelId}`);
      }

      const finalPrompt = customPrompt.replace('[title]', title);
      let content;

      switch (modelConfig.provider.toLowerCase()) {
        case 'openai':
          if (!this.openaiClient) throw new Error('OpenAI API key not configured');
          
          const openaiResponse = await this.openaiClient.chat.completions.create({
            model: modelConfig.model_name,
            messages: [
              {
                role: "system",
                content: modelConfig.system_prompt || "You are a professional content writer."
              },
              {
                role: "user", 
                content: finalPrompt
              }
            ],
            temperature: modelConfig.temperature,
            max_tokens: modelConfig.maxTokens
          });
          content = openaiResponse.choices[0].message.content;
          break;

        case 'anthropic':
          if (!this.anthropicClient) throw new Error('Anthropic API key not configured');
          
          const anthropicResponse = await this.anthropicClient.messages.create({
            model: modelConfig.model_name,
            temperature: modelConfig.temperature,
            system: modelConfig.system_prompt || "You are a professional content writer.",
            messages: [{
              role: "user",
              content: finalPrompt
            }],
            max_tokens: modelConfig.maxTokens
          });
          content = anthropicResponse.content[0].text;
          break;

        case 'openrouter':
          if (!this.openrouterClient) throw new Error('OpenRouter API key not configured');
          
          const openrouterResponse = await this.openrouterClient.chat.completions.create({
            model: modelConfig.model_name,
            messages: [
              {
                role: "system",
                content: modelConfig.system_prompt || "You are a professional content writer."
              },
              {
                role: "user",
                content: finalPrompt
              }
            ],
            temperature: modelConfig.temperature,
            max_tokens: modelConfig.maxTokens
          });
          content = openrouterResponse.choices[0].message.content;
          break;

        default:
          throw new Error(`Unsupported model provider: ${modelConfig.provider}`);
      }

      return content;
      
    } catch (error) {
      console.error('AI generation error:', error);
      throw new Error(error.message || 'Failed to generate content');
    }
  }
}

export const aiClient = new AiClient();
export default aiClient;