import OpenAI from 'openai';
import { fal } from '@fal-ai/client';

export const IMAGE_MODELS = {
  FLUX_SCHNELL: { id: 'fal-ai/flux/schnell', name: 'FLUX Schnell', provider: 'fal' },
  RECRAFT_V3: { id: 'fal-ai/recraft-v3', name: 'Recraft V3', provider: 'fal' },
  FLUX_DEV: { id: 'fal-ai/flux/dev', name: 'FLUX Dev', provider: 'fal' },
  DALL_E_3: { id: 'dall-e-3', name: 'DALL-E 3', provider: 'openai' }
};

class ImageClient {
  constructor() {
    this.falClient = null;
    this.openaiClient = null;
    this.aiClient = null;
    this._availableModels = []; // Initialize as empty array
  }

  setApiKeys(keys) {
    if (keys.fal) {
      fal.config({ credentials: keys.fal });
      this.falClient = fal;
      console.log('Fal.ai client initialized');
    }
    if (keys.openai) {
      this.openaiClient = new OpenAI({ apiKey: keys.openai, dangerouslyAllowBrowser: true });
      console.log('OpenAI client initialized');
    }
  }

  setAiClient(client) {
    this.aiClient = client;
    console.log('AI client set for ImageClient');
  }

  setAvailableModels(models) {
    this._availableModels = models;
    console.log('Available models set:', models);
  }

  getAuxiliaryModel() {
    const stored = localStorage.getItem('globalAiModel');
    if (stored && this._availableModels.some(m => m.value === stored)) {
      return stored;
    }
    return this._availableModels[0]?.value;
  }

  async enhancePrompt(basicPrompt, style = 'photorealistic', context = {}) {
    if (!this.aiClient) {
      console.warn('AI client not initialized, using original prompt');
      return basicPrompt;
    }
  
    try {
      const stylePrompts = {
        photorealistic: `Create a photorealistic image generation prompt for an article titled "${context.articleTitle || ''}".
          Current section: "${basicPrompt}"
          
          Focus on:
          - Creating an image that directly relates to both the article topic and current section
          - Hyper-realistic details of the main subject
          - Natural lighting and professional photography aesthetics
          - Clear connection to the article's subject matter
          
          Guidelines:
          - Start with the main subject matter from the section
          - Include relevant setting and context from the article topic
          - Specify camera angle and composition that best illustrates the content
          - Maintain professional and appropriate imagery
          - Keep it under 100 words
          
          Style keywords: photorealistic, 4k uhd, high detail, natural lighting, professional photography`,
  
        illustration: `Create an artistic illustration prompt for an article titled "${context.articleTitle || ''}".
          Current section: "${basicPrompt}"
          
          Focus on:
          - Creating an illustration that clearly represents both the article topic and current section
          - Stylized artistic interpretation that maintains relevance
          - Creative visualization of the content
          - Clear connection to the article's subject matter
          
          Guidelines:
          - Start with the main concept from the section
          - Include symbolic or metaphorical elements that relate to the article topic
          - Specify artistic style and composition
          - Keep it visually engaging while remaining topical
          
          Style keywords: digital art, illustration, artistic composition, creative interpretation`,
  
        "3d_render": `Create a 3D rendering prompt for an article titled "${context.articleTitle || ''}".
          Current section: "${basicPrompt}"
          
          Focus on:
          - Creating a 3D scene that represents both the article topic and current section
          - Professional 3D visualization of the content
          - Clear connection to the article's subject matter
          
          Guidelines:
          - Start with the main elements from the section
          - Include environmental context from the article topic
          - Specify materials, lighting, and camera angle
          - Keep the scene focused and relevant
          
          Style keywords: 3D render, octane render, physically based materials, professional 3D visualization`
      };
  
      const systemPrompt = stylePrompts[style] || stylePrompts.photorealistic;
      
      const auxModel = this.getAuxiliaryModel();
      console.log('Using auxiliary model for prompt enhancement:', auxModel);
  
      if (!auxModel) {
        console.warn('No auxiliary model available, using original prompt');
        return basicPrompt;
      }
  
      const promptRequest = `Generate an image prompt that will create a visual representation of this article section.
        Article Title: "${context.articleTitle || ''}"
        Section Heading: "${basicPrompt}"
        
        ${systemPrompt}
        
        Return only the image generation prompt, no explanations or additional text.`;
  
      const response = await this.aiClient.generateArticleContent({
        title: 'Image Prompt Enhancement',
        modelId: auxModel,
        customPrompt: promptRequest
      });
  
      console.log('Enhanced prompt:', response);
      return response.trim();
    } catch (error) {
      console.error('Error enhancing prompt:', error);
      return basicPrompt;
    }
  }

  async generateImage({ 
    prompt, 
    model, 
    dimension = 'square', 
    style = 'default', 
    enhancePrompt = false, 
    context = {},
    enableSafetyFilter = true
  }) {
    try {
      if (!this.falClient) {
        throw new Error('Fal.ai client not initialized');
      }

      let finalPrompt = prompt;
      if (enhancePrompt) {
        console.log('🔄 Original prompt:', prompt);
        console.log('Enhancing prompt with style:', style);
        console.log('🎯 Context:', context);
        finalPrompt = await this.enhancePrompt(prompt, style, context);
        console.log('✨ Enhanced prompt:', finalPrompt);
      } else {
        console.log('📝 Using original prompt:', finalPrompt);
      }

      console.log('🎨 Starting image generation with parameters:', {
        prompt: finalPrompt,
        model,
        dimension,
        style,
        safety_filter: enableSafetyFilter
      });

      // Configure safety settings based on provider
      let safetyConfig = {};
      
      if (model.includes('fal-ai')) {
        safetyConfig = {
          safety_filter: enableSafetyFilter,
          unsafe_content_threshold: enableSafetyFilter ? 0.98 : 0.1 // Lower threshold when safety is disabled
        };
      }

      const result = await this.falClient.subscribe(model, {
        input: {
          prompt: finalPrompt,
          seed: Math.floor(Math.random() * 1000000),
          image_size: dimension,
          num_images: 1,
          ...safetyConfig
        },
        logs: true,
        onQueueUpdate: (update) => {
          if (update.status === "IN_PROGRESS") {
            update.logs?.map(log => log.message).forEach(msg => 
              console.log('🔄 Fal.ai progress:', msg)
            );
          }
          console.log('📊 Queue status:', update.status);
        }
      });

      if (!result?.data) {
        throw new Error('No data in response');
      }

      const imageUrl = result.data.images?.[0]?.url || result.data.image;
      
      if (!imageUrl) {
        console.error('Full result:', result);
        if (result.error?.message) {
          throw new Error(result.error.message);
        }
        throw new Error('No image URL in response - the content may have been filtered');
      }

      return {
        status: 'success',
        image_url: imageUrl
      };

    } catch (error) {
      console.error('Image generation failed:', error);
      // Enhance error messages for better user feedback
      if (error.message?.includes('safety')) {
        throw new Error('Content was filtered due to safety restrictions. Please modify your prompt and try again.');
      }
      if (error.message?.includes('rate limit')) {
        throw new Error('Rate limit exceeded. Please wait a moment and try again.');
      }
      throw error;
    }
  }
}

export const imageClient = new ImageClient();
export default imageClient;