export const formatContent = (content, title = '', options = {}) => {
  const { isListicle = false } = options;

  // Remove title if it appears at the start
  if (title) {
    const escapedTitle = title.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const titleRegex = new RegExp(`^\\s*(#\\s*)?${escapedTitle}\\s*\n`, 'i');
    content = content.replace(titleRegex, '');
  }

  let formattedContent = content;

  if (isListicle) {
    // First handle bold text that should be headings
    formattedContent = formattedContent
      // Convert "**1. Title**" format to h2
      .replace(/^\*\*(\d+)\.\s+(.+?)\*\*$/gm, '<h2>$1. $2</h2>')
      // Convert "1. Title" format to h2
      .replace(/^(\d+)\.\s+(.+?)$/gm, '<h2>$1. $2</h2>')
      // Convert remaining "**Title**" at line start to h2 if it looks like a heading
      .replace(/^\*\*([^*\n]+?)\*\*$/gm, (match, p1) => {
        if (p1.length < 100 && !/[.!?]/.test(p1)) {
          return `<h2>${p1}</h2>`;
        }
        return match;
      });
  }

  // Handle FAQ sections
  formattedContent = formattedContent
    .replace(/^(FAQ|Frequently Asked Questions|Common Questions)$/gim, '<h2>$1</h2>')
    .replace(/^Q:\s*(.+)$/gm, '<h3>$1</h3>');

  // Convert markdown headings to HTML
  formattedContent = formattedContent
    .replace(/^## (.*$)/gm, '<h2>$1</h2>')
    .replace(/^### (.*$)/gm, '<h3>$1</h3>')
    .replace(/^#### (.*$)/gm, '<h4>$1</h4>');

  // Convert to DOM for processing
  const parser = new DOMParser();
  const doc = parser.parseFromString(formattedContent, 'text/html');

  // Style headings
  doc.querySelectorAll('h2').forEach((h2, index) => {
    // For listicles, ensure proper numbering
    if (isListicle && !h2.textContent.match(/^\d+\./)) {
      h2.textContent = `${index + 1}. ${h2.textContent}`;
    }
    h2.style.marginTop = '2em';
    h2.style.marginBottom = '1em';
    h2.style.fontWeight = '600';
    h2.style.fontSize = '1.5em';
    h2.id = `section-${index + 1}`;
  });

  // Process sections
  const sections = Array.from(doc.body.children);
  let currentSection = [];
  
  sections.forEach(element => {
    if (element.matches('h2, h3, h4')) {
      if (currentSection.length > 0) {
        processSection(currentSection, doc);
        currentSection = [];
      }
      currentSection.push(element);
    } else {
      currentSection.push(element);
    }
  });
  
  if (currentSection.length > 0) {
    processSection(currentSection, doc);
  }

  // Format lists
  doc.querySelectorAll('ul, ol').forEach(list => {
    list.style.marginBottom = '1em';
    list.style.marginLeft = '2em';
  });

  // Get the processed content
  formattedContent = doc.body.innerHTML;

  // Format remaining markdown
  formattedContent = formattedContent
    .replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>')
    .replace(/\*(.+?)\*/g, '<em>$1</em>')
    .replace(/_(.+?)_/g, '<em>$1</em>')
    .replace(/```([^`]+)```/g, '<pre><code>$1</code></pre>')
    .replace(/`([^`]+)`/g, '<code>$1</code>')
    .replace(/^> (.+)$/gm, '<blockquote>$1</blockquote>');

  return formattedContent.trim();
};

function processSection(section, doc) {
  // Process paragraphs within the section
  const textNodes = section.filter(el => !el.matches('h2, h3, h4'));
  const sentences = textNodes
    .map(el => el.textContent)
    .join(' ')
    .split(/(?<=[.!?])\s+/);

  let paragraphs = [];
  let currentParagraph = [];

  sentences.forEach(sentence => {
    sentence = sentence.trim();
    if (!sentence) return;

    if (sentence.length > 150) {
      if (currentParagraph.length > 0) {
        paragraphs.push(currentParagraph.join(' '));
        currentParagraph = [];
      }
      paragraphs.push(sentence);
    } else if (currentParagraph.join(' ').length + sentence.length > 200) {
      paragraphs.push(currentParagraph.join(' '));
      currentParagraph = [sentence];
    } else {
      currentParagraph.push(sentence);
      if (currentParagraph.length >= 2) {
        paragraphs.push(currentParagraph.join(' '));
        currentParagraph = [];
      }
    }
  });

  if (currentParagraph.length > 0) {
    paragraphs.push(currentParagraph.join(' '));
  }

  // Create paragraph elements
  paragraphs.forEach(p => {
    if (p.trim()) {
      const para = doc.createElement('p');
      para.textContent = p.trim();
      para.style.marginBottom = '1em';
      para.style.lineHeight = '1.7';
      section[0].parentNode.insertBefore(para, section[0].nextSibling);
    }
  });
}