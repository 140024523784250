import React from 'react';
import { Box, Container, Typography, Breadcrumbs, Link } from '@mui/material';
import { Sparkles } from 'lucide-react';

const PrivacyPolicy = () => {
  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', pt: 8, pb: 6 }}>
      {/* Navigation */}
      <Box 
        sx={{ 
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bgcolor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'divider',
          zIndex: 1100,
          px: 4,
          py: 2
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <Sparkles size={24} color="#E04C16" />
            <Typography 
              variant="h5" 
              component="h1" 
              sx={{ 
                fontWeight: 800,
                letterSpacing: '-0.05em'
              }}
            >
              Scripti
            </Typography>
          </Box>
          <Breadcrumbs>
            <Link href="/" color="inherit">Home</Link>
            <Typography color="text.primary">Privacy Policy</Typography>
          </Breadcrumbs>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ mt: 8 }}>
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
          Privacy Policy
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" paragraph>
          Last updated: January 17, 2025
        </Typography>

        <Box sx={{ mt: 6 }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
            1. Information We Collect
          </Typography>
          <Typography paragraph>
            We collect:
            • Account information (email, name)
            • Usage data and analytics
            • WordPress site connection details
            • Generated content data
            • Payment information via our payment processor
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            2. API Keys and Third-Party Services
          </Typography>
          <Typography paragraph>
            • We securely store but cannot access your API keys
            • API keys are encrypted and used only for service requests
            • We do not store or access content generated via third-party APIs
            • Usage data from third-party services is subject to their privacy policies
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            3. How We Use Your Information
          </Typography>
          <Typography paragraph>
            We use collected information to:
            • Provide and maintain our service
            • Process payments and subscriptions
            • Send service updates and notifications
            • Improve our service
            • Respond to support requests
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            4. Data Storage and Security
          </Typography>
          <Typography paragraph>
            • All data is encrypted in transit and at rest
            • We implement industry-standard security measures
            • Regular security audits and updates
            • Immediate notification of security breaches
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            5. Data Sharing and Third Parties
          </Typography>
          <Typography paragraph>
            We share data with:
            • Third-party AI service providers (as necessary for service operation)
            • Payment processors
            • Analytics providers
            • Hosting and infrastructure providers
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            6. User Rights
          </Typography>
          <Typography paragraph>
            Users have the right to:
            • Access their personal data
            • Request data deletion
            • Export their data
            • Opt out of marketing communications
            • Update or correct their information
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            7. Cookies and Tracking
          </Typography>
          <Typography paragraph>
            We use cookies and similar technologies for:
            • Authentication
            • Service functionality
            • Analytics and performance monitoring
            • User preferences
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            8. Changes to Privacy Policy
          </Typography>
          <Typography paragraph>
            We may update this policy periodically. Users will be notified of significant changes. Continued use of the service constitutes acceptance of the updated policy.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            9. Contact Information
          </Typography>
          <Typography paragraph>
            For privacy-related questions or concerns, contact us at privacy@scripti.ai
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;