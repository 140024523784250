import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Lock,
  Bot,
  Image,
  Key,
  Zap,
  Globe,
  FileText,
  Files,
  ShoppingBag,
  ListOrdered,
  Sparkles,
  Infinity,
} from 'lucide-react';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [expandedFaq, setExpandedFaq] = useState(null);
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    localStorage.clear();
    sessionStorage.clear();
    loginWithRedirect({
      prompt: 'login',
      appState: { returnTo: '/articles/standard' }
    });
  };

  const toggleFaq = (index) => {
    setExpandedFaq(expandedFaq === index ? null : index);
  };

  const features = [
    {
      icon: <Bot className="w-8 h-8 text-orange-600" />,
      title: "Multiple AI Models*",
      desc: "Access leading AI models including Claude 3.5 Sonnet, GPT-4o-Mini, and many more models through OpenRouter"
    },
    {
      icon: <Image className="w-8 h-8 text-orange-600" />,
      title: "AI Image Generation*",
      desc: "Create visuals using Fal.ai's image generation models including FLUX Schnell and Recraft V3"
    },
    {
      icon: <Globe className="w-8 h-8 text-orange-600" />,
      title: "WordPress Integration",
      desc: "Publish generated content as drafts to WordPress sites"
    }
  ];

  const contentTypes = [
    {
      icon: <FileText className="w-8 h-8 text-orange-600" />,
      title: "Single Article Creator*",
      desc: "Create in-depth, SEO-optimized articles with custom sections and AI-generated images"
    },
    {
      icon: <Files className="w-8 h-8 text-orange-600" />,
      title: "Bulk Article Generator*",
      desc: "Generate multiple articles simultaneously, perfect for content agencies and high-volume needs"
    },
    {
      icon: <ShoppingBag className="w-8 h-8 text-orange-600" />,
      title: "Product Reviews & Roundups*",
      desc: "Create product comparisons and review articles optimized for affiliate marketing"
    },
    {
      icon: <ListOrdered className="w-8 h-8 text-orange-600" />,
      title: "Listicle Articles*",
      desc: "Generate engaging list-based articles with customizable sections"
    }
  ];

  const faqs = [
    {
      q: "What do I need to get started?",
      a: "You'll need our subscription ($12.99/month) and API keys for the AI services you want to use (OpenAI, Anthropic, or Fal.ai). Some providers like Grok are currently free through OpenRouter. API availability and pricing subject to change."
    },
    {
      q: "What are the typical API costs for content generation?",
      a: "Example costs as of January 2024 for a 1000-word article: GPT-4o mini ($0.001), Claude 3.5 Haiku ($0.0064), Claude 3.5 Sonnet ($0.024), Grok 2 Vision ($0.016). Perplexity Sonar Small ($0.0005) and Large ($0.0027) have an additional $5 per 1000 requests. Costs subject to change."
    },
    {
      q: "How much do AI-generated images cost?",
      a: "Current Fal.ai costs range from $0.02 per image (FLUX Schnell) to $0.10 per image (Recraft V3). Prices subject to change."
    },
    {
      q: "Do you offer refunds?",
      a: "No, we don't offer refunds. However, you can cancel your subscription at any time."
    },
    {
      q: "How much content can I create per month?",
      a: "Example with $10 API budget: GPT-4o mini (~10,000 articles), Perplexity Sonar Small (~20,000 articles + request fees), or Claude 3.5 Sonnet (~400 articles). Image costs additional. Prices subject to change."
    },
    {
      q: "Which AI models are supported?",
      a: "We support various AI models through direct integration and OpenRouter, including Claude 3 (Anthropic), GPT-4 (OpenAI), and others. For images, we use Fal.ai models. Model availability subject to change."
    },
    {
      q: "Do you provide the API keys?",
      a: "No, you'll need to bring your own API keys. This gives you direct control over your API usage and costs."
    },
    {
      q: "What is OpenRouter?",
      a: "OpenRouter is a unified API gateway providing access to various AI models through a single API key. Current offerings include models from Anthropic, Meta, Mistral, Perplexity, and others. Model availability and pricing subject to change."
    },
    {
      q: "Can I control my API costs?",
      a: "Yes! You have complete control over which models you use and how much you spend. You can monitor usage through provider dashboards and switch between models to optimize costs."
    }
  ];

  return (
    <div className="min-h-screen bg-white font-['Inter']">
      {/* Navigation */}
      <nav className="fixed w-full bg-white shadow-sm z-50">
        <div className="max-w-7xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center gap-2">
              <Sparkles size={24} color="#E04C16" />
              <span className="font-['Inter'] text-2xl font-extrabold text-black tracking-tight">
                Scripti
              </span>
            </div>
            
            <div className="hidden md:flex items-center space-x-8">
              <a href="#features" className="text-gray-700 hover:text-orange-600 font-medium">Features</a>
              <a href="#pricing" className="text-gray-700 hover:text-orange-600 font-medium">Pricing</a>
              <a href="#faq" className="text-gray-700 hover:text-orange-600 font-medium">FAQ</a>
              <button
                onClick={handleLogin}
                className="px-4 py-2 bg-orange-600 text-white rounded-full hover:bg-orange-700 font-semibold"
              >
                Log In
              </button>
            </div>

            <button 
              className="md:hidden"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>

          {isMobileMenuOpen && (
            <div className="md:hidden py-2">
              <a href="#features" className="block py-2 text-gray-700 font-medium">Features</a>
              <a href="#pricing" className="block py-2 text-gray-700 font-medium">Pricing</a>
              <a href="#faq" className="block py-2 text-gray-700 font-medium">FAQ</a>
              <button
                onClick={handleLogin}
                className="w-full mt-2 px-4 py-2 bg-orange-600 text-white rounded-full font-semibold"
              >
                Log In
              </button>
            </div>
          )}
        </div>
      </nav>

      {/* Hero Section */}
      <section className="pt-32 pb-16 px-4 bg-gradient-to-b from-orange-50 to-white">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
            Create SEO-Optimized Content with
            <br />
            <span className="text-orange-600">Advanced AI Technology</span>
          </h1>
          <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
          Use leading AI model APIs like Anthropic's Claude 3.5 Sonnet and OpenAI's GPT-4o to generate content for a fraction of the cost of other AI writers*
          </p>
          <div className="flex justify-center gap-4">
            <button
              onClick={handleLogin}
              className="px-8 py-3 bg-orange-600 text-white rounded-full hover:bg-orange-700 font-semibold flex items-center gap-2"
            >
              <Zap className="w-5 h-5" />
              Start Creating Now
            </button>
            <a 
              href="#pricing"
              className="px-8 py-3 border border-gray-300 rounded-full hover:border-orange-600 font-semibold"
            >
              View Pricing
            </a>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-16 bg-white px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">
            Powerful Features for Content Creation
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="p-6 bg-white rounded-lg shadow-lg border border-gray-100 hover:border-orange-200 transition-all">
                <div className="mb-4">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Content Types Section */}
      <section className="py-16 bg-gray-50 px-4">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">
            Create Different Types of Content
          </h2>
          <div className="grid md:grid-cols-2 gap-8">
            {contentTypes.map((type, index) => (
              <div key={index} className="p-6 bg-white rounded-lg shadow-lg border border-gray-100 hover:border-orange-200 transition-all">
                <div className="mb-4">
                  {type.icon}
                </div>
                <h3 className="text-xl font-semibold mb-2">{type.title}</h3>
                <p className="text-gray-600">{type.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="py-16 bg-orange-50 px-4">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Simple, Transparent Pricing*</h2>
          <div className="bg-white rounded-2xl shadow-xl overflow-hidden">
            <div className="p-8 border-b border-gray-100">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-2xl font-bold">Unlimited Plan</h3>
                <div className="bg-orange-100 text-orange-800 px-3 py-1 rounded-full text-sm font-medium">
                  Most Popular
                </div>
              </div>
              <div className="flex items-baseline mb-4">
                <span className="text-xl text-gray-400 line-through mr-3">$25.00</span>
                <span className="text-4xl font-bold">$12.50</span>
                <span className="text-gray-500 ml-2">/month</span>
              </div>
              <div className="bg-orange-100 text-orange-800 px-3 py-1 rounded-full text-sm font-medium inline-block mb-4">
                Limited Time Offer - 50% Off
              </div>
              <p className="text-gray-600 mb-6">
                Full access to all features with your own API keys
              </p>
            </div>
            
            <div className="p-8">
              <ul className="space-y-4">
                <li className="flex items-center gap-3">
                  <Infinity className="w-5 h-5 text-orange-600" />
                  <span>Unlimited article generation</span>
                </li>
                <li className="flex items-center gap-3">
                  <Image className="w-5 h-5 text-orange-600" />
                  <span>Unlimited AI image generation</span>
                </li>
                <li className="flex items-center gap-3">
                  <Bot className="w-5 h-5 text-orange-600" />
                  <span>Access to all AI models</span>
                </li>
                <li className="flex items-center gap-3">
                  <Key className="w-5 h-5 text-orange-600" />
                  <span>Bring your own API keys for direct cost control</span>
                </li>
                <li className="flex items-center gap-3">
                  <Lock className="w-5 h-5 text-orange-600" />
                  <span>Cancel anytime</span>
                </li>
              </ul>
              
              <button
                onClick={handleLogin}
                className="w-full mt-8 px-8 py-3 bg-orange-600 text-white rounded-full hover:bg-orange-700 font-semibold"
              >
                Get Started Now
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="py-16 px-4">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">
            Frequently Asked Questions
          </h2>
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div 
                key={index} 
                className="border border-gray-200 rounded-lg overflow-hidden"
              >
                <button
                 
                 className="w-full px-6 py-4 text-left bg-white hover:bg-gray-50 flex justify-between items-center"
                 onClick={() => toggleFaq(index)}
               >
                 <h3 className="text-lg font-semibold">{faq.q}</h3>
                 <svg
                   className={`w-6 h-6 transform transition-transform ${
                     expandedFaq === index ? 'rotate-180' : ''
                   }`}
                   fill="none"
                   viewBox="0 0 24 24"
                   stroke="currentColor"
                 >
                   <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth={2}
                     d="M19 9l-7 7-7-7"
                   />
                 </svg>
               </button>
               <div
                 className={`px-6 py-4 bg-gray-50 transition-all duration-200 ease-in-out ${
                   expandedFaq === index ? 'block' : 'hidden'
                 }`}
               >
                 <p className="text-gray-600">{faq.a}</p>
               </div>
             </div>
           ))}
         </div>
         
         {/* Disclaimers Section */}
         <div className="mt-12 pt-8 border-t border-gray-200">
           <div className="text-gray-500 text-sm space-y-2">
             <p>* API costs are additional and vary by provider. Pricing information shown is as of January 2024 and subject to change.</p>
             <p>* Content quality, image generation quality, and processing speed depend on the AI models selected.</p>
             <p>* Model availability and capabilities are subject to change by providers.</p>
             <p>* Generated content may require human review and verification before use.</p>
           </div>
         </div>
       </div>
     </section>

     {/* Footer */}
     <footer className="bg-gray-50 py-12 px-4">
       <div className="max-w-7xl mx-auto">
         <div className="flex items-center justify-between">
           <div className="flex items-center gap-2">
             <Sparkles size={24} color="#E04C16" />
             <span className="font-['Inter'] text-2xl font-extrabold text-black tracking-tight">
               Scripti
             </span>
           </div>
           <div className="flex gap-6">
  <Link 
    to="/privacy" 
    className="text-gray-600 hover:text-orange-600 no-underline"
  >
    Privacy Policy
  </Link>
  <Link 
    to="/terms" 
    className="text-gray-600 hover:text-orange-600 no-underline"
  >
    Terms of Service
  </Link>
</div>

         </div>
         <div className="mt-8 pt-8 border-t border-gray-200 text-center">
           <p className="text-gray-600">© 2024 Scripti All rights reserved.</p>
         </div>
       </div>
     </footer>
   </div>
 );
};

export default LandingPage;