import React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { 
  Grid, 
  TextField, 
  Button, 
  Typography, 
  Box, 
  Paper, 
  IconButton, 
  CircularProgress, 
  Alert, 
  Snackbar, 
  Tooltip, 
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Link } from 'react-router-dom';
import { 
  HelpCircle, 
  PlusCircle, 
  XCircle, 
  ChevronDown, 
  ChevronRight, 
  PenTool, 
  BookOpen, 
  LayoutGrid, 
  ListOrdered, 
  CheckSquare,
  TestTubeDiagonal,
  ListFilter,
  X,
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import apiClient from '../utils/apiUtils';
import LoadingOverlay from './LoadingOverlay';
import PageHeader from './PageHeader';

const isValidInput = (input) => {
  const dangerousPatterns = [
    /<script/i,
    /javascript:/i,
    /data:/i,
    /vbscript:/i,
    /on\w+=/i,  // onerror=, onload=, etc.
    /\{\{.*\}\}/,  // template injection
    /\$\{.*\}/,   // template literal injection
    /<iframe/i,
    /<img/i,
    /eval\(/i,
    /setTimeout/i,
    /setInterval/i,
    /new\s+Function/i
  ];

  return !dangerousPatterns.some(pattern => pattern.test(input));
};

const sanitizeInput = (input) => {
  return input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;')
    .replace(/\//g, '&#x2F;')
    .replace(/\0/g, '')
    .replace(/[\x00-\x1F\x7F-\x9F]/g, '');
};


const PROMPT_BLOCKS = {
  writingStyle: {
    title: "Writing Style",
    icon: PenTool,
    blocks: [
      { 
        id: 'tone-friendly', 
        category: 'tone',
        label: 'Friendly Tone',
        template: 'Use a friendly and approachable tone throughout the content.'
      },
      { 
        id: 'tone-professional', 
        category: 'tone',
        label: 'Professional Tone',
        template: 'Maintain a professional and authoritative tone.'
      },
      { 
        id: 'tone-academic', 
        category: 'tone',
        label: 'Academic Tone',
        template: 'Employ an academic writing style with formal language.'
      },
      { 
        id: 'language-simple', 
        category: 'language',
        label: 'Simple Language',
        template: 'Use simple, everyday language accessible to general readers.'
      },
      { 
        id: 'language-technical', 
        category: 'language',
        label: 'Technical Language',
        template: 'Include technical terminology appropriate for industry experts.'
      }
    ]
  },
  introduction: {
    title: "Introduction Styles",
    icon: BookOpen,
    blocks: [
      {
        id: 'intro-balanced',
        category: 'introduction',
        label: 'Balanced Introduction',
        template: 'Start with a balanced introduction combining context and engaging hooks.'
      },
      {
        id: 'intro-direct',
        category: 'introduction',
        label: 'Direct Introduction',
        template: 'Begin with a direct, to-the-point introduction.'
      },
      {
        id: 'intro-story',
        category: 'introduction',
        label: 'Story-based Introduction',
        template: 'Open with a relevant anecdote or story.'
      },
      {
        id: 'intro-problem',
        category: 'introduction',
        label: 'Problem-focused Introduction',
        template: 'Start by presenting the main problem or challenge.'
      }
    ]
  },
  contentStructure: {
    title: "Content Structure",
    icon: LayoutGrid,
    blocks: [
      {
        id: 'structure-seo',
        category: 'headings',
        label: 'SEO Question Headings',
        template: 'Format main headings as SEO-friendly questions.'
      },
      {
        id: 'structure-howto',
        category: 'headings',
        label: 'How-To Format',
        template: 'Structure content as a step-by-step guide.'
      },
      {
        id: 'paragraphs-short',
        category: 'paragraphs',
        label: 'Short Paragraphs',
        template: 'Keep paragraphs concise (2-3 sentences).'
      },
      {
        id: 'paragraphs-detailed',
        category: 'paragraphs',
        label: 'Detailed Paragraphs',
        template: 'Use longer, more detailed paragraphs (4-6 sentences).'
      }
    ]
  },
  seoFeatures: {
    title: "SEO Features",
    icon: ListFilter,
    blocks: [
      {
        id: 'seo-featured',
        category: 'seo-featured',
        label: 'Featured Snippets',
        template: 'Include a featured snippet near the start of the article formatted in bold text.'
      },
      {
        id: 'seo-keywords',
        category: 'seo-keywords',
        label: 'SEO Keywords',
        template: 'Naturally incorporate target keywords throughout the content.'
      },
      {
        id: 'seo-H2-headings',
        category: 'seo-H2-headings',
        label: 'Include H2 Heading Structure',
        template: 'Use H2 headings for main sections'
      },
      {
        id: 'seo-H3-headings',
        category: 'seo-H3-headings',
        label: 'Include H3 Heading Structure',
        template: 'Use H3 headings for subsections.'
      }
    ]
  },
  additionalElements: {
    title: "Additional Elements",
    icon: ListOrdered,
    blocks: [
      {
        id: 'element-lists',
        category: 'formatting',
        label: 'Bullet Points & Lists',
        template: 'Include bullet points and numbered lists where appropriate.'
      },
      {
        id: 'element-examples',
        category: 'content',
        label: 'Examples',
        template: 'Include practical examples to illustrate key points.'
      },
      {
        id: 'element-faq',
        category: 'sections',
        label: 'FAQ Section',
        template: 'Add a FAQ section with 3-5 relevant questions and answers.'
      },
      {
        id: 'element-callouts',
        category: 'formatting',
        label: 'Key Callouts',
        template: 'Use callout boxes to highlight important information.'
      }
    ]
  },
  conclusion: {
    title: "Conclusion Styles",
    icon: CheckSquare,
    blocks: [
      {
        id: 'conclusion-summary',
        category: 'conclusion',
        label: 'Summary Conclusion',
        template: 'End with a comprehensive summary of key points.'
      },
      {
        id: 'conclusion-action',
        category: 'conclusion',
        label: 'Call-to-Action',
        template: 'Conclude with a clear call-to-action.'
      },
      {
        id: 'conclusion-questions',
        category: 'conclusion',
        label: 'Open-ended Questions',
        template: 'End with thought-provoking questions for further consideration.'
      }
    ]
  }
};

function PromptBuilder({ config }) {
  const { authenticatedRequest, error: authError, isAuthenticated, loading: authLoading } = useAuth();
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [availableModels, setAvailableModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  
  const getAuxiliaryModel = () => {
    const stored = localStorage.getItem('globalAiModel');
    if (stored && availableModels.some(m => m.value === stored)) {
      return stored;
    }
    return availableModels[0]?.value;
  };
  const [promptName, setPromptName] = useState('');
  const [customRequirements, setCustomRequirements] = useState('');
  const [generatedPrompt, setGeneratedPrompt] = useState('');
  const [expandedSections, setExpandedSections] = useState({});
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [notification, setNotification] = useState({
    open: false,
    severity: 'success',
    message: ''
  });

  useEffect(() => {
    // Initialize all sections as collapsed
    const sections = Object.keys(PROMPT_BLOCKS).reduce((acc, key) => {
      acc[key] = key === 'writingStyle';
      return acc;
    }, {});
    setExpandedSections(sections);
    
    // Fetch available models
    const fetchModels = async () => {
      try {
        const models = await authenticatedRequest(() => 
          apiClient.getAvailableModels()
        );
        setAvailableModels(models);
        if (models.length > 0) {
          setSelectedModel(models[0].value);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to load available models');
      }
    };

    fetchModels();
  }, [authenticatedRequest]);

  const toggleSection = (section) => {
    setExpandedSections(prev => {
      // Create an object with all sections set to false
      const allClosed = Object.keys(prev).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {});
      
      // Toggle only the clicked section
      return {
        ...allClosed,
        [section]: !prev[section]
      };
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (destination.droppableId === 'selected-blocks') {
      if (source.droppableId === 'selected-blocks') {
        // Reordering within selected blocks
        const newBlocks = Array.from(selectedBlocks);
        const [movedBlock] = newBlocks.splice(source.index, 1);
        newBlocks.splice(destination.index, 0, movedBlock);
        setSelectedBlocks(newBlocks);
      } else {
        // Adding from available blocks
        const sourceCategory = source.droppableId;
        const blockToAdd = PROMPT_BLOCKS[sourceCategory].blocks[source.index];
        
        // Check if block already exists
        const blockExists = selectedBlocks.some(block => block.id === blockToAdd.id);
        
        if (!blockExists) {
          const newBlock = {
            ...blockToAdd,
            uniqueId: `${blockToAdd.id}-${Date.now()}`
          };
          
          const newBlocks = Array.from(selectedBlocks);
          newBlocks.splice(destination.index, 0, newBlock);
          setSelectedBlocks(newBlocks);
        }
      }
    }
  };

  const handleAddBlock = useCallback((blockToAdd) => {
    const filteredBlocks = selectedBlocks.filter(
      block => block.category !== blockToAdd.category
    );
    
    const newBlock = {
      ...blockToAdd,
      uniqueId: `${blockToAdd.id}-${Date.now()}`
    };
    
    setSelectedBlocks([...filteredBlocks, newBlock]);
  }, [selectedBlocks]);

  const [promptDialogOpen, setPromptDialogOpen] = useState(false);

  const generatePromptTemplate = (selectedBlocks, customRequirements) => {
    const organizedBlocks = selectedBlocks.reduce((acc, block) => {
      acc[block.category] = block;
      return acc;
    }, {});
  
    let promptText = `Please write an article on [title] using the following guidelines:\n\n`;
    let instructionNumber = 1;
  
    const categories = [
      { key: 'tone', prefix: '' },
      { key: 'structure', prefix: '' },
      { key: 'content', prefix: '' },
      { key: 'language', prefix: '' },
      { key: 'introduction', prefix: '' },
      { key: 'audience', prefix: 'Target audience: ' }
    ];
  
    categories.forEach(({ key, prefix }) => {
      if (organizedBlocks[key]) {
        promptText += `${instructionNumber}. ${prefix}${organizedBlocks[key].template}\n`;
        instructionNumber++;
      }
    });
  
    if (customRequirements.trim()) {
      promptText += `\nAdditional Instructions (exactly as specified):\n${customRequirements.trim()}\n`;
    }
  
    promptText += `\nPlease ensure the article flows naturally and reads as if written by a knowledgeable human rather than an AI.`;
  
    return promptText;
  };
  
  const handleGeneratePrompt = async () => {
    if (selectedBlocks.length === 0) {
      setError('Please add some blocks to generate a prompt');
      return;
    }
  
    setLoading(true);
    setError(null);
  
    try {
      const auxModel = getAuxiliaryModel();
      if (!auxModel) {
        throw new Error('No AI models available');
      }
      
      console.log('Using auxiliary model:', auxModel);
      const generatedTemplate = generatePromptTemplate(selectedBlocks, customRequirements);
  
      const apiRequest = {
        model: auxModel,
        settings: {
          blocks: selectedBlocks,
          customRequirements: customRequirements.trim()
        }
      };
  
      const response = await authenticatedRequest(() => 
        apiClient.generatePrompt(apiRequest)
      );
  
      setGeneratedPrompt(response.prompt || generatedTemplate);
      
    } catch (err) {
      console.error('Prompt generation error:', err);
      setError(err.message || 'Failed to generate prompt');
      setGeneratedPrompt(generatePromptTemplate(selectedBlocks, customRequirements));
    } finally {
      setLoading(false);
    }
  };
  
  const handleSavePrompt = async () => {
    if (!promptName.trim()) {
      setError('Please enter a name for your prompt');
      return;
    }
  
    try {
      await authenticatedRequest(() => 
        apiClient.createPrompt({
          name: promptName,
          prompt_text: generatedPrompt,
          settings: {
            blocks: selectedBlocks,
            customRequirements,
            template: generatePromptTemplate(selectedBlocks, customRequirements)
          }
        })
      );
  
      setPromptName('');
      setNotification({
        open: true,
        severity: 'success',
        message: 'Prompt template saved successfully!'
      });
    } catch (error) {
      setNotification({
        open: true,
        severity: 'error',
        message: `Save failed: ${error.message}`
      });
    }
  };
  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  if (authLoading) {
    return (
      <Box sx={{ p: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!isAuthenticated) {
    return (
      <Box sx={{ p: 4 }}>
        <Alert severity="warning">
          Please log in to use this feature
        </Alert>
      </Box>
    );
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
       {loading && <LoadingOverlay />}
      <Box sx={{ p: 4 }}>
        {authError && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {authError}
          </Alert>
        )}

        {/* Page Header */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          mb: 4 
        }}>
          <PageHeader 
    title="Create A New Prompt"
    description="Start creating your own custom prompt by choosing prompt blocks from the expandable sections below. You can also add any instructions that you want in the 'Custom Requirements' box. Remember to keep it concise and clear so the AI model will follow them precisely."
    icon={<TestTubeDiagonal size={24} color="#E04C16" />} 
  />
          <Button
            onClick={() => setHelpDialogOpen(true)}
            startIcon={<HelpCircle size={18} />}
            sx={{
              bgcolor: '#E04C16',
              color: 'white',
              borderRadius: '20px',
              px: 3,
              '&:hover': {
                bgcolor: '#c43d0f'
              }
            }}
          >
            How to Use
          </Button>
        </Box>
        
        {/* Help Dialog */}
        <Dialog 
          open={helpDialogOpen}
          onClose={() => setHelpDialogOpen(false)}
          PaperProps={{
            sx: {
              position: 'fixed',
              right: 32,
              top: '50%',
              transform: 'translateY(-50%)',
              m: 0,
              width: 400,
              maxWidth: '90vw',
              maxHeight: '80vh',
              borderRadius: 2
            }
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            p: 2,
            borderBottom: '1px solid',
            borderColor: 'divider'
          }}>
            <DialogTitle sx={{ p: 0, fontSize: '1.25rem' }}>
              How to Use PromptLab
            </DialogTitle>
            <IconButton onClick={() => setHelpDialogOpen(false)} size="small">
              <X size={18} />
            </IconButton>
          </Box>
          <DialogContent>
 
 <Typography variant="body2" sx={{ mb: 4 }}>
   Build your own custom AI prompts that allow you to generate content for any niche. 
 </Typography>
 <Typography variant="h6" sx={{ mb: 2 }}>
 1. Building Your Template
 </Typography>
 <Typography variant="body2" sx={{ mb: 2 }}>
  Browse through the five categories (Writing Style, Introduction, etc.). Each category offers different options for customizing your prompt to allow you to create content in the style and format that you want. To add one to your prompt, click the + button or drag the block to the prompt structure box
</Typography>
 
 <Typography variant="body2" sx={{ mb: 2 }}>
   
   You can arrange your selected blocks in the order you want them to appear in the prompt.
   The 'Custom Requirements' field can be used to add specific instructions to the prompt
  such as information about your target audience or the goal of your content.
 </Typography>
 <Typography variant="h6" sx={{ mb: 2 }}>
 2. Generating & Saving
 </Typography>
 <Typography variant="body2" sx={{ mb: 4 }}>
  Click "Generate Prompt" to create your custom template. Once generated, click 'View & Save' to review the prompt to ensure it meets your needs
    If you are happy with it, give your template a descriptive name for easy reference and save it to your library for so it can be used with the article creator.
 </Typography>
 <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
   Note - Prompts are created with the assistance of the 'Auxiliary AI model' that you have selected in the side menu bar. The quality, accuracy and style of the prompt will vary depending on the model chosen.
 </Typography>

 <Typography variant="h6" sx={{ mb: 2 }}>
   3. Making the Most of Your Templates
 </Typography>
 <Typography variant="body2" sx={{ mb: 2 }}>
   
   You can find all of your saved templates in the 'Saved Prompts' section.
   Saved prompts can be edited to refine your content generation. You can also create multiple versions of the same prompt easily by using the 'duplicate prompt' button.
 </Typography>


</DialogContent>
        </Dialog>

        <Grid container spacing={3}>
          {/* Left Column - Available Blocks */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3 }}>
              
              
             
            {Object.entries(PROMPT_BLOCKS).map(([category, { title, icon: Icon, blocks }]) => (
  <Box 
    key={category} 
    sx={{ 
      mb: 4,
      border: '1px solid',
      borderColor: 'divider',
      borderRadius: 1,
      overflow: 'hidden'
    }}
  >
<Button
      onClick={() => toggleSection(category)}
      sx={{ 
        width: '100%', 
        justifyContent: 'flex-start',
        p: 2,
        textTransform: 'none',
        color: expandedSections[category] ? 'inherit' : '#E04C16', // Orange text when closed
        bgcolor: expandedSections[category] ? 'transparent' : '#e9e9e9', // Light grey background when closed
        border: expandedSections[category] ? 'none' : '1px solid #E04C16', // Orange border when closed
        borderBottom: expandedSections[category] ? '1px solid' : '1px solid #E04C16',
        borderColor: expandedSections[category] ? 'divider' : '#E04C16',
        '&:hover': {
          bgcolor: expandedSections[category] ? 'action.hover' : '#fafafa',
          color: expandedSections[category] ? 'inherit' : '#E04C16',
        },
        '& svg': {
          color: expandedSections[category] ? 'inherit' : '#E04C16'  // Orange icon when closed
        },
        '& .MuiTypography-root': {
          color: expandedSections[category] ? 'inherit' : '#E04C16'  // Orange text when closed
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        {Icon && (
          <Box component="span" sx={{ mr: 1, display: 'flex', alignItems: 'center' }}>
            <Icon size={20} />
          </Box>
        )}
        <Typography 
          variant="subtitle1"
          sx={{ 
            flexGrow: 1,
            ml: 1,
            fontWeight: expandedSections[category] ? 600 : 400
          }}
        >
          {title}
        </Typography>
        {expandedSections[category] ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
      </Box>
    </Button> 
                  <Collapse in={expandedSections[category]} timeout="auto">
                    <Droppable droppableId={category} isDropDisabled={true}>
                      {(provided) => (
                        <Box
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          sx={{ 
                            minHeight: 50,
                            p: 2
                          }}
                        >
                          {blocks.map((block, index) => (
                            <Draggable
                              key={block.id}
                              draggableId={block.id}
                              index={index}
                            >
                              {(provided) => (
                                <Paper
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  sx={{ 
                                    p: 2,
                                    mb: 1,
                                    opacity: selectedBlocks.some(
                                      selected => selected.category === block.category
                                    ) ? 0.5 : 1,
                                    position: 'relative',
                                    '&:hover': {
                                      bgcolor: 'action.hover'
                                    }
                                  }}
                                >
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                    <Tooltip 
                                      title={selectedBlocks.some(
                                        selected => selected.category === block.category
                                      ) ? "Only one option can be selected from each category" : "Click + or drag to add"}
                                      placement="top"
                                    >
                                      <div>
                                        <Typography variant="subtitle2">
                                          {block.label}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                          {block.template}
                                        </Typography>
                                      </div>
                                    </Tooltip>
                                    <IconButton 
                                      size="small"
                                      onClick={() => {
                                        if (!selectedBlocks.some(
                                          selected => selected.category === block.category
                                        )) {
                                          handleAddBlock(block);
                                        }
                                      }}
                                      disabled={selectedBlocks.some(
                                        selected => selected.category === block.category
                                      )}
                                      sx={{ 
                                        ml: 1,
                                        opacity: selectedBlocks.some(
                                          selected => selected.category === block.category
                                        ) ? 0.5 : 1
                                      }}
                                    >
                                      <PlusCircle size={20} />
                                    </IconButton>
                                  </Box>
                                </Paper>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </Collapse>
                </Box>
              ))}
            </Paper>
          </Grid>

          {/* Right Column - Selected Blocks and Actions */}
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Your Prompt Structure
              </Typography>
              
              <Droppable droppableId="selected-blocks">
                {(provided) => (
                  <Box
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    sx={{ 
                      minHeight: 200,
                      border: '1px dashed',
                      borderColor: 'divider',
                      borderRadius: 1,
                      p: 2,
                      mb: 3
                    }}
                  >
                    {selectedBlocks.map((block, index) => (
                      <Draggable
                        key={block.uniqueId || block.id}
                        draggableId={block.uniqueId || block.id}
                        index={index}
                      >
                        {(provided) => (
                          <Paper
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{ 
                              p: 2,
                              mb: 1,
                              cursor: 'grab',
                              '&:hover': {
                                bgcolor: 'action.hover'
                              }
                            }}
                          >
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                              <div>
                                <Typography variant="subtitle2">
                                  {block.label}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  {block.template}
                                </Typography>
                              </div>
                              <IconButton 
                                size="small"
                                onClick={() => {
                                  const newBlocks = selectedBlocks.filter(
                                    b => b.uniqueId !== block.uniqueId
                                  );
                                  setSelectedBlocks(newBlocks);
                                }}
                                sx={{ ml: 1 }}
                              >
                                <XCircle size={20} />
                              </IconButton>
                            </Box>
                          </Paper>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>

              <Box sx={{ mt: 3, mb: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Custom Requirements
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  placeholder="Add any specific requirements or preferences not covered by the blocks above..."
                  value={customRequirements}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (input.length > 500) {
                      setError('Input exceeds maximum length of 500 characters');
                      return;
                    }
                    if (!isValidInput(input)) {
                      setError('Invalid input detected. Please remove any special characters or code.');
                      return;
                    }
                    setError(null);
                    setCustomRequirements(sanitizeInput(input));
                  }}
                  helperText={`${customRequirements.length}/500 characters`}
                  sx={{ mb: 1 }}
                />
              </Box>

              <Button
                variant="contained"
                color="primary"
                onClick={handleGeneratePrompt}
                disabled={loading || selectedBlocks.length === 0}
                fullWidth
                sx={{ mb: 3 }}
              >
                {loading ? <CircularProgress size={24} /> : 'Generate Prompt'}
              </Button>

              {generatedPrompt && (
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Alert severity="success" sx={{ flexGrow: 1 }}>
                    Prompt template generated successfully!
                  </Alert>
                  <Button
                    variant="contained"
                    onClick={() => setPromptDialogOpen(true)}
                  >
                    View & Save
                  </Button>
                </Box>
              )}

              <Dialog 
                open={promptDialogOpen} 
                onClose={() => setPromptDialogOpen(false)}
                maxWidth="md"
                fullWidth
              >
                <DialogTitle>Review & Save Prompt Template</DialogTitle>
                <DialogContent>
                  <TextField
                    fullWidth
                    multiline
                    rows={12}
                    value={generatedPrompt}
                    onChange={(e) => setGeneratedPrompt(e.target.value)}
                    sx={{ mt: 2, mb: 3 }}
                  />
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                      label="Template Name"
                      value={promptName}
                      onChange={(e) => setPromptName(e.target.value)}
                      sx={{ flexGrow: 1 }}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setPromptDialogOpen(false)}>Cancel</Button>
                  <Button 
                    variant="contained"
                    onClick={() => {
                      handleSavePrompt();
                      setPromptDialogOpen(false);
                    }}
                    disabled={!promptName.trim()}
                  >
                    Save Template
                  </Button>
                </DialogActions>
              </Dialog>

              {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {error}
                </Alert>
              )}
            </Paper>
          </Grid>
        </Grid>

        <Snackbar
          open={notification.open}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert 
            onClose={handleCloseNotification} 
            severity={notification.severity}
            sx={{ width: '100%' }}
            action={
              notification.severity === 'success' && (
                <Button 
                  color="inherit" 
                  size="small" 
                  component={Link} 
                  to="/prompts/saved"
                >
                  View Saved
                </Button>
              )
            }
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </Box>
    </DragDropContext>
  );
}

export default React.memo(PromptBuilder);