import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  Tooltip,
} from '@mui/material';
import { Star, Copy, Pencil, Trash2, X } from 'lucide-react';

const PromptCard = ({ prompt, onToggleFavorite, onDuplicate, onEdit, onDelete, onClick }) => {
  return (
    <div 
      onClick={onClick}
      className="bg-white rounded-lg border border-gray-200 p-4 cursor-pointer transition-all
                hover:border-orange-500 hover:shadow-lg h-[280px] flex flex-col"
    >
      <div className="flex justify-between items-start mb-3">
        <div className="flex-1 min-w-0">
          <h3 className="text-lg font-semibold text-gray-900 truncate mb-1">
            {prompt.name}
          </h3>
          <p className="text-sm text-gray-500 truncate">
            Used {prompt.usage_count || 0} times
          </p>
        </div>
        <Tooltip title={prompt.is_favorite ? "Remove from favorites" : "Add to favorites"}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onToggleFavorite(prompt.id);
            }}
            className="text-gray-400 hover:text-orange-500"
            size="small"
          >
            <Star 
              className={`h-5 w-5 ${prompt.is_favorite ? 'fill-orange-500 text-orange-500' : ''}`}
            />
          </IconButton>
        </Tooltip>
      </div>
      
      <div className="text-sm text-gray-600 flex-grow overflow-hidden relative h-[160px] mb-4">
        <div className="h-full">
          {prompt.prompt_text}
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-8 bg-gradient-to-t from-white to-transparent"></div>
      </div>

      <div className="flex gap-2 mt-auto">
        <Tooltip title="Duplicate prompt">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onDuplicate(prompt.id);
            }}
            className="text-gray-400 hover:text-gray-600"
            size="small"
          >
            <Copy className="h-4 w-4" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit prompt">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onEdit(prompt.id);
            }}
            className="text-gray-400 hover:text-orange-500"
            size="small"
          >
            <Pencil className="h-4 w-4" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete prompt">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              onDelete(prompt.id);
            }}
            className="text-gray-400 hover:text-red-500"
            size="small"
          >
            <Trash2 className="h-4 w-4" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

const PromptGrid = ({ 
  prompts, 
  onToggleFavorite, 
  onDuplicate, 
  onEdit, 
  onDelete 
}) => {
  const [selectedPrompt, setSelectedPrompt] = useState(null);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {prompts.map((prompt) => (
          <PromptCard
            key={prompt.id}
            prompt={prompt}
            onToggleFavorite={onToggleFavorite}
            onDuplicate={onDuplicate}
            onEdit={onEdit}
            onDelete={onDelete}
            onClick={() => setSelectedPrompt(prompt)}
          />
        ))}
      </div>

      <Dialog 
        open={!!selectedPrompt} 
        onClose={() => setSelectedPrompt(null)}
        maxWidth="md"
        fullWidth
      >
        {selectedPrompt && (
          <>
            <DialogTitle>
              <div className="flex justify-between items-center">
                <div className="flex items-center gap-2">
                  <span className="text-xl font-semibold">{selectedPrompt.name}</span>
                  {selectedPrompt.is_favorite && (
                    <Star className="h-5 w-5 fill-orange-500 text-orange-500" />
                  )}
                </div>
                <IconButton
                  onClick={() => setSelectedPrompt(null)}
                  size="small"
                  className="text-gray-500"
                >
                  <X className="h-5 w-5" />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent>
              <div className="space-y-4">
                <div className="flex gap-2 text-sm text-gray-500">
                  <span>Created {new Date(selectedPrompt.created_at).toLocaleDateString()}</span>
                  <span>•</span>
                  <span>Used {selectedPrompt.usage_count || 0} times</span>
                  {selectedPrompt.last_used && (
                    <>
                      <span>•</span>
                      <span>Last used {new Date(selectedPrompt.last_used).toLocaleDateString()}</span>
                    </>
                  )}
                </div>
                
                <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                  <pre className="whitespace-pre-wrap font-mono text-sm text-gray-800">
                    {selectedPrompt.prompt_text}
                  </pre>
                </div>

                <div className="flex justify-end gap-2 mt-4">
                  <Button
                    onClick={() => onDuplicate(selectedPrompt.id)}
                    startIcon={<Copy className="h-4 w-4" />}
                    variant="outlined"
                    color="inherit"
                  >
                    Duplicate
                  </Button>
                  <Button
                    onClick={() => {
                      onEdit(selectedPrompt.id);
                      setSelectedPrompt(null);
                    }}
                    startIcon={<Pencil className="h-4 w-4" />}
                    variant="contained"
                    sx={{ bgcolor: '#E04C16', '&:hover': { bgcolor: '#c43d0f' } }}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </DialogContent>
          </>
        )}
      </Dialog>
    </>
  );
};

export default PromptGrid;