import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

const LoadingOverlay = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 9999,
    }}
  >
    <CircularProgress size={48} sx={{ color: 'white', mb: 2 }} />
    <Typography 
      sx={{ 
        color: 'white',
        fontWeight: 500,
        textAlign: 'center',
        px: 2
      }}
    >
      Generating Prompt Template...
    </Typography>
  </Box>
);

export default LoadingOverlay;