import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
  Alert,
  Snackbar
} from '@mui/material';
import { TestTubeDiagonal } from 'lucide-react';
import PageHeader from './PageHeader';
import PromptGrid from './PromptCard';
import { useAuth } from '../context/AuthContext';
import apiClient from '../utils/apiUtils';

export default function SavedPrompts() {
  const [prompts, setPrompts] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [promptToDelete, setPromptToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const { authenticatedRequest, error: authError } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPrompts();
  }, []);

  const fetchPrompts = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const data = await authenticatedRequest(() => apiClient.getSavedPrompts());
      
      const userPrompts = data.user_prompts || [];
      
     
      setPrompts(userPrompts);
      
    } catch (err) {
      setError(err.message || 'Failed to fetch prompts');
      setNotification({
        open: true,
        message: 'Failed to load prompts. Please try refreshing the page.',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleFavorite = async (promptId) => {
    try {
      const result = await authenticatedRequest(() => 
        apiClient.togglePromptFavorite(promptId)
      );
      setPrompts(prompts.map(p => 
        p.id === promptId ? { ...p, is_favorite: result.is_favorite } : p
      ));
    } catch (err) {
      setNotification({
        open: true,
        message: 'Failed to update favorite status',
        severity: 'error'
      });
    }
  };

  const handleDuplicate = async (promptId) => {
    try {
      const newPrompt = await authenticatedRequest(() => 
        apiClient.duplicatePrompt(promptId)
      );
      setPrompts([...prompts, newPrompt]);
      setNotification({
        open: true,
        message: 'Prompt duplicated successfully',
        severity: 'success'
      });
    } catch (err) {
      setNotification({
        open: true,
        message: 'Failed to duplicate prompt',
        severity: 'error'
      });
    }
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await authenticatedRequest(() => 
        apiClient.deletePrompt(promptToDelete)
      );
      
      setPrompts(prompts.filter(prompt => prompt.id !== promptToDelete));
      setNotification({
        open: true,
        message: 'Prompt deleted successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Delete error:', err);
      setNotification({
        open: true,
        message: err.message || 'Failed to delete prompt',
        severity: 'error'
      });
    } finally {
      setDeleteLoading(false);
      setDeleteDialogOpen(false);
      setPromptToDelete(null);
    }
  };

  const handleEdit = (promptId) => {
    navigate(`/prompts/edit/${promptId}`);
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4, maxWidth: '1200px', mx: 'auto' }}>
      <PageHeader 
        title="Saved Prompts" 
        icon={<TestTubeDiagonal size={24} color="#E04C16" />} 
      />
      
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {prompts.length === 0 ? (
        <Box sx={{ textAlign: 'center', p: 4, color: 'text.secondary' }}>
          <Typography>
            No saved prompts found. Create a new prompt to get started.
          </Typography>
        </Box>
      ) : (
        <PromptGrid
          prompts={prompts}
          onToggleFavorite={handleToggleFavorite}
          onDuplicate={handleDuplicate}
          onEdit={handleEdit}
          onDelete={(id) => {
            setPromptToDelete(id);
            setDeleteDialogOpen(true);
          }}
        />
      )}

      <Dialog
        open={deleteDialogOpen}
        onClose={() => !deleteLoading && setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Prompt</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this prompt? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteDialogOpen(false)}
            disabled={deleteLoading}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDelete} 
            color="error" 
            variant="contained"
            disabled={deleteLoading}
          >
            {deleteLoading ? <CircularProgress size={24} /> : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}