// index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from './context/AuthContext';  // Add this import
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain="dev-37mvwswd3e73pa07.eu.auth0.com"
        clientId="i4jaANoox38PiYlyOTC5e2XY8NlctjnC"
        authorizationParams={{
          redirect_uri: `${window.location.origin}/callback`,
          audience: "https://TrevoWriter",
          scope: "openid profile email offline_access",
        }}
        onRedirectCallback={(appState) => {
          const params = new URLSearchParams(window.location.search);
          const error = params.get('error');
          
          if (error) {
            window.location.replace('/unauthorized');
          } else {
            window.location.href = appState?.returnTo || '/articles/standard';
          }
        }}
        cacheLocation="localstorage"
        useRefreshTokens={true}
        useRefreshTokensFallback={true}
      >
        <AuthProvider>   {/* Add AuthProvider here */}
          <App />
        </AuthProvider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);