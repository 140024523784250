// ImageControls.js
import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Switch
} from '@mui/material';

// Image model configurations
const imageModels = [
  { 
    value: 'fal-ai/flux/schnell', 
    label: 'FLUX.1 Schnell',
    price: 0.003,
    description: 'Fast generation,low cost, good quality'
  },
  {
    value: 'fal-ai/flux/dev',
    label: 'FLUX.1 Dev',
    price: 0.025,
    description: 'Low cost high quality images'
  },
  {
    value: 'fal-ai/recraft-v3',
    label: 'Recraft V3',
    price: 0.04,
    description: 'High-quality creative images'
  },
  {
    value: 'fal-ai/recraft-20b',
    label: 'Recraft 20b',
    price: 0.022,
    description: 'High quality images, ability to add text and logos'
  },
  { 
    value: 'fal-ai/flux-pro/v1.1-ultra', 
    label: 'FLUX 1.1 Pro Ultra',
    price: 0.06,
    description: 'Professional quality, up to 2k resolution'
  },
  {
    value: 'fal-ai/ideogram/v2',
    label: 'Ideogram V2.0',
    price: 0.08,
    description: 'High quality visuals, posters, and logos with accurate text'
  },
  {
    value: 'fal-ai/ideogram/v2/turbo',
    label: 'Ideogram V2.0 Turbo',
    price: 0.05,
    description: 'Accelerated Ideogram V2.0'
  }
];

const imageStyles = [
  { value: 'realistic_image', label: 'Realistic' },
  { value: 'digital_art', label: 'Digital Art' },
  { value: 'artistic', label: 'Artistic' },
  { value: 'cinematic', label: 'Cinematic' }
];

const dimensions = [
  { value: 'square_hd', label: 'Square HD (1024×1024)', description: 'High definition square format' },
  { value: 'square', label: 'Square (512×512)', description: 'Standard square format' },
  { value: 'portrait_4_3', label: 'Portrait 4:3 (768×1024)', description: 'Standard portrait format' },
  { value: 'portrait_16_9', label: 'Portrait 16:9 (576×1024)', description: 'Widescreen portrait format' },
  { value: 'landscape_4_3', label: 'Landscape 4:3 (1024×768)', description: 'Standard landscape format' },
  { value: 'landscape_16_9', label: 'Landscape 16:9 (1024×576)', description: 'Widescreen landscape format' }
];

const ImageControls = ({
  model,
  setModel,
  style,
  setStyle,
  dimension,
  setDimension,
  prompt,
  setPrompt,
  useAIEnhancement,
  setUseAIEnhancement,
  loading
}) => {
  // Only show style selector if style and setStyle props are provided
  const showStyle = style !== undefined && setStyle !== undefined;
  // Only show prompt and enhancement controls if they are provided
  const showPromptControls = prompt !== undefined && setPrompt !== undefined;

  return (
    <Grid container spacing={3}>
      {/* Model Selection */}
      <Grid item xs={12} md={showStyle ? 4 : 6}>
        <FormControl fullWidth>
          <InputLabel>Image Model</InputLabel>
          <Select
            value={model}
            label="Image Model"
            onChange={(e) => setModel(e.target.value)}
            disabled={loading}
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.02)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            {imageModels.map((modelOption) => (
              <MenuItem 
                key={modelOption.value} 
                value={modelOption.value}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  py: 1
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Typography variant="body1">
                    {modelOption.label}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ ml: 2 }}
                  >
                    ${modelOption.price.toFixed(3)}/image
                  </Typography>
                </Box>
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ mt: 0.5 }}
                >
                  {modelOption.description}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Style Selection - only rendered if style props are provided */}
      {showStyle && (
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel>Image Style</InputLabel>
            <Select
              value={style}
              label="Image Style"
              onChange={(e) => setStyle(e.target.value)}
              disabled={loading}
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
            >
              {imageStyles.map((styleOption) => (
                <MenuItem key={styleOption.value} value={styleOption.value}>
                  {styleOption.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {/* Dimension Selection */}
      <Grid item xs={12} md={showStyle ? 4 : 6}>
        <FormControl fullWidth>
          <InputLabel>Image Dimensions</InputLabel>
          <Select
            value={dimension}
            label="Image Dimensions"
            onChange={(e) => setDimension(e.target.value)}
            disabled={loading}
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.02)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)'
              }
            }}
          >
            {dimensions.map((dim) => (
              <MenuItem 
                key={dim.value} 
                value={dim.value}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  py: 1
                }}
              >
                <Typography variant="body1">
                  {dim.label}
                </Typography>
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ mt: 0.5 }}
                >
                  {dim.description}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default ImageControls;