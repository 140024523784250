import React from 'react';
import { Box, Container, Typography, Breadcrumbs, Link } from '@mui/material';
import { Sparkles } from 'lucide-react';

const TermsAndConditions = () => {
  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', pt: 8, pb: 6 }}>
      {/* Navigation */}
      <Box 
        sx={{ 
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bgcolor: 'background.paper',
          borderBottom: '1px solid',
          borderColor: 'divider',
          zIndex: 1100,
          px: 4,
          py: 2
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
            <Sparkles size={24} color="#E04C16" />
            <Typography 
              variant="h5" 
              component="h1" 
              sx={{ 
                fontWeight: 800,
                letterSpacing: '-0.05em'
              }}
            >
              Scripti
            </Typography>
          </Box>
          <Breadcrumbs>
            <Link href="/" color="inherit">Home</Link>
            <Typography color="text.primary">Terms & Conditions</Typography>
          </Breadcrumbs>
        </Container>
      </Box>

      <Container maxWidth="lg" sx={{ mt: 8 }}>
        <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
          Terms and Conditions
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" paragraph>
          Last updated: January 17, 2025
        </Typography>

        <Box sx={{ mt: 6 }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
            1. Agreement to Terms
          </Typography>
          <Typography paragraph>
            By accessing or using Scripti's services, you agree to be bound by these Terms and Conditions. If you disagree with any part of the terms, you may not access the service.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            2. Service Description
          </Typography>
          <Typography paragraph>
            Scripti provides AI-powered content generation services. Users must provide their own API keys for various AI services (including but not limited to OpenAI, Anthropic, and Fal.ai). The service acts as an interface to these third-party AI models.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            3. User Responsibilities
          </Typography>
          <Typography paragraph>
            Users are responsible for:
            • Managing and securing their own API keys
            • All costs associated with API usage
            • Reviewing and verifying all generated content
            • Ensuring compliance with third-party AI service terms
            • Maintaining account security
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            4. Content Usage and Rights
          </Typography>
          <Typography paragraph>
            Users retain all rights to content generated through our service. However, users must ensure generated content complies with applicable laws and regulations. Scripti makes no claims of ownership over generated content but retains rights to the service interface and technology.
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            5. Subscription and Billing
          </Typography>
          <Typography paragraph>
            • Subscription fees are billed monthly
            • No refunds for partial month usage
            • Users may cancel at any time
            • API usage costs are separate and billed by respective providers
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            6. Limitation of Liability
          </Typography>
          <Typography paragraph>
            Scripti is not responsible for:
            • Content generated by third-party AI models
            • API costs or service interruptions
            • Accuracy or appropriateness of generated content
            • Third-party API service availability or changes
          </Typography>

          <Typography variant="h5" gutterBottom sx={{ fontWeight: 600, mt: 4 }}>
            7. Changes to Terms
          </Typography>
          <Typography paragraph>
            We reserve the right to modify these terms at any time. Users will be notified of significant changes. Continued use of the service constitutes acceptance of modified terms.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default TermsAndConditions;