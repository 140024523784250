// useAIModels.js
import { useState, useEffect } from 'react';
import apiClient from '../utils/apiUtils';

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours
const CACHE_KEY = 'availableModels';

export const useAIModels = (authenticatedRequest) => {
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const isCacheValid = () => {
    const cached = localStorage.getItem(CACHE_KEY);
    if (!cached) return false;

    const { timestamp } = JSON.parse(cached);
    return Date.now() - timestamp < CACHE_DURATION;
  };

  const getCachedModels = () => {
    const cached = localStorage.getItem(CACHE_KEY);
    if (!cached) return null;

    const { data } = JSON.parse(cached);
    return data;
  };

  // useAIModels.js
const fetchAndStoreModels = async (force = false) => {
    try {
      if (!authenticatedRequest) {
        throw new Error('Authentication not available');
      }
  
      // Check cache first unless forced refresh
      if (!force && isCacheValid()) {
        const cachedModels = getCachedModels();
        if (cachedModels) {
          setModels(cachedModels);
          setLoading(false);
          return;
        }
      }
  
      setLoading(true);
  
      // Changed from getAllModels to getAvailableModels
      const allModels = await authenticatedRequest(() => apiClient.getAvailableModels());
  
      if (!allModels || allModels.length === 0) {
        throw new Error('No AI models available. Please add at least one API key in settings.');
      }
  
      setModels(allModels);
      
      localStorage.setItem(CACHE_KEY, JSON.stringify({
        data: allModels,
        timestamp: Date.now()
      }));
  
    } catch (err) {
      console.error('Error fetching AI models:', err);
      setError(err.message);
      
      // Fall back to cached models if available
      const cachedModels = getCachedModels();
      if (cachedModels) {
        setModels(cachedModels);
      }
    } finally {
      setLoading(false);
    }
  };

  // Get the current auxiliary model with validation
  const getAuxiliaryModel = () => {
    const stored = localStorage.getItem('globalAiModel');
    if (stored && models.some(m => m.value === stored)) {
      return stored;
    }
    
    // Default to first available model
    const defaultModel = models[0]?.value;
    if (defaultModel) {
      localStorage.setItem('globalAiModel', defaultModel);
    }
    return defaultModel;
  };

  useEffect(() => {
    if (authenticatedRequest) {
      fetchAndStoreModels();
    }
  }, [authenticatedRequest]);

  return { 
    models, 
    loading, 
    error, 
    refreshModels: () => fetchAndStoreModels(true),
    getAuxiliaryModel 
  };
};