import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Snackbar,
  Container,
  Divider
} from '@mui/material';
import { useAuth } from '../context/AuthContext';
import apiClient from '../utils/apiUtils';

export default function EditPrompt() {
  const { promptId } = useParams();
  const navigate = useNavigate();
  const { authenticatedRequest, error: authError } = useAuth();

  const [prompt, setPrompt] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [formData, setFormData] = useState({
    name: '',
    prompt_text: '',
    settings: {}
  });

  useEffect(() => {
    fetchPrompt();
  }, [promptId]);

  const fetchPrompt = async () => {
    try {
      setLoading(true);
      await authenticatedRequest(async (token) => {
        apiClient.setAuthToken(token);
        const data = await apiClient.getPrompt(promptId);
        setPrompt(data);
        setFormData({
          name: data.name,
          prompt_text: data.prompt_text,
          settings: data.settings || {}
        });
      });
    } catch (err) {
      setError(err.message || 'Failed to fetch prompt');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaving(true);
      await authenticatedRequest(async (token) => {
        apiClient.setAuthToken(token);
        
        await apiClient.updatePrompt(promptId, {
          name: formData.name,
          prompt_text: formData.prompt_text,
          settings: formData.settings || {}
        });

        setNotification({
          open: true,
          message: 'Prompt updated successfully',
          severity: 'success'
        });

        // Navigate after a short delay
        setTimeout(() => {
          navigate('/prompts/saved');
        }, 1500);
      });
    } catch (err) {
      console.error('Error updating prompt:', err);
      setError(err.message || 'Failed to update prompt');
      setNotification({
        open: true,
        message: err.message,
        severity: 'error'
      });
    } finally {
      setSaving(false);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: 'calc(100vh - 100px)'
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth={false} sx={{ p: { xs: 2, md: 4 } }}>
      <Paper 
        sx={{ 
          p: { xs: 2, md: 4 },
          minHeight: 'calc(100vh - 140px)',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}>
          <Typography variant="h4">
            Edit Prompt
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => navigate('/prompts/saved')}
              disabled={saving}
              size="large"
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              disabled={saving}
              size="large"
            >
              {saving ? 'Saving...' : 'Save Changes'}
            </Button>
          </Box>
        </Box>

        <Divider sx={{ mb: 4 }} />
        
        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <form onSubmit={handleSubmit} style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <TextField
            label="Prompt Name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            margin="normal"
            required
            variant="outlined"
            sx={{ mb: 3 }}
            InputProps={{
              sx: { fontSize: '1.1rem', py: 1 }
            }}
          />
          
          <TextField
            label="Prompt Text"
            value={formData.prompt_text}
            onChange={(e) => setFormData({ ...formData, prompt_text: e.target.value })}
            required
            multiline
            rows={20}
            variant="outlined"
            sx={{ 
              flex: 1,
              mb: 3,
              '& .MuiOutlinedInput-root': {
                height: '100%',
                '& textarea': {
                  height: '100% !important'
                }
              }
            }}
            InputProps={{
              sx: { 
                fontSize: '1rem',
                lineHeight: '1.5',
                fontFamily: 'monospace'
              }
            }}
          />
        </form>
      </Paper>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}