export class WordPressClient {
  constructor(site) {
    if (!site) {
      throw new Error('WordPress site configuration is required');
    }
    this.site = site;
    this.credentials = null;
  }

  setCredentials(username, password) {
    this.credentials = { username, password };
  }

  getAuthHeader() {
    if (!this.credentials?.username || !this.credentials?.password) {
      throw new Error('WordPress credentials not configured');
    }
    const token = btoa(`${this.credentials.username}:${this.credentials.password}`);
    return `Basic ${token}`;
  }

  async uploadMedia(blob, filename) {
    try {
      const response = await fetch(`${this.site.api_url}/media`, {
        method: 'POST',
        headers: {
          'Authorization': this.getAuthHeader(),
          'Content-Disposition': `attachment; filename="${filename}"`,
          'Content-Type': blob.type,
        },
        body: blob
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Media upload failed: ${errorData.message || response.statusText}`);
      }

      const data = await response.json();
      return {
        id: data.id,
        url: data.source_url
      };
    } catch (error) {
      console.error('Media upload error:', error);
      throw error;
    }
  }

  async getMediaUrl(mediaId) {
    try {
      const response = await fetch(`${this.site.api_url}/media/${mediaId}`, {
        headers: {
          'Authorization': this.getAuthHeader()
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch media');
      }

      const data = await response.json();
      return data.source_url;
    } catch (error) {
      console.error('Error fetching media URL:', error);
      return null;
    }
  }


  async uploadFeaturedImage(imageUrl, title) {
      try {
        console.log('Attempting to download image from:', imageUrl);
        
        if (!imageUrl) {
          throw new Error('No image URL provided');
        }
        
        const response = await fetch(imageUrl);
        if (!response.ok) {
          throw new Error(`Failed to download image: ${response.statusText}`);
        }
    
        const blob = await response.blob();
        if (!blob || blob.size === 0) {
          throw new Error('Downloaded image is empty');
        }
    
        const filename = `${title}-${Date.now()}.${blob.type.split('/')[1]}`;
        console.log('Uploading image with filename:', filename);
        
        const uploadResult = await this.uploadMedia(blob, filename);
        console.log('Upload result:', uploadResult);
        
        if (!uploadResult?.id) {
          throw new Error('Media upload succeeded but no ID was returned');
        }
    
        return uploadResult.id;
      } catch (error) {
        console.error('Featured image upload error:', error);
        throw new Error(`Failed to upload featured image: ${error.message}`);
      }
  }

  async getUsers() {
    try {
      const response = await fetch(`${this.site.api_url}/users`, {
        headers: {
          'Authorization': this.getAuthHeader()
        }
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Failed to fetch users: ${errorData.message || response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching users:', error);
      throw error;
    }
  }

  

  async createPost({ title, content, categoryId, featuredMediaId, authorId, status = 'draft' }) {
    try {
      const postData = {
        title: { raw: title },
        content: { raw: content },
        status,
        categories: categoryId ? [parseInt(categoryId)] : undefined,
        featured_media: featuredMediaId || 0,
        author: authorId ? parseInt(authorId) : undefined
      };

      const response = await fetch(`${this.site.api_url}/posts`, {
        method: 'POST',
        headers: {
          'Authorization': this.getAuthHeader(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(`Post creation failed: ${errorData.message || response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Post creation error:', error);
      throw error;
    }
  }
}

export default WordPressClient;