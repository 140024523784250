import { config } from '../config';
import axios from 'axios';



export class ApiClient {
  

  constructor() {
    this.instance = axios.create({
      baseURL: config.api.baseUrl,
      timeout: 30000,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });

    this.cache = new Map(); 
    this.CACHE_DURATION = 12 * 60 * 60 * 1000;
  }
  
  configureRequest(config) {
    return {
      ...config,
      withCredentials: true,
      headers: {
        ...config.headers,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    };
  }

  async request(config) {
    try {
        const response = await this.instance(this.configureRequest(config));
        return response.data;
    } catch (error) {
        if (error.response) {
            const errorDetail = error.response.data?.detail || error.response.data?.error || error.message;
            throw new Error(errorDetail);
        }
        throw error;
    }
}


  setAuthToken(token) {
    if (token) {
      this.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete this.instance.defaults.headers.common['Authorization'];
      // Clear cache when token is removed
      this.clearCache();
    }
  }

  // Cache management methods
  setCached(key, data) {
    this.cache.set(key, {
      data,
      timestamp: Date.now()
    });
  }

  getCached(key) {
    const cached = this.cache.get(key);
    if (!cached) return null;

    // Check if cache is expired
    if (Date.now() - cached.timestamp > this.CACHE_DURATION) {
      this.cache.delete(key);
      return null;
    }

    return cached.data;
  }

  clearCache() {
    this.cache.clear();
  }

  async scrapeUrl(url) {
    try {
        const response = await this.request({
            method: 'POST',
            url: '/api/user/scrape-url',
            data: {url},
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });

       
        if (!response.success) {
            throw new Error(response.error || 'Failed to scrape URL');
        }

        return response;

    } catch (error) {
        
        const errorMessage = error.response?.data?.detail || error.message || 'Failed to scrape URL';
        throw new Error(errorMessage);
    }
}




  async getApiKeys() {
    const cachedKeys = this.getCached('apiKeys');
    if (cachedKeys) {
      return cachedKeys;
    }

    const keys = await this.request({
      method: 'GET',
      url: '/api/user/api-keys'
    });

    this.setCached('apiKeys', keys);
    return keys;
  }

  async getSavedPrompts() {
    const cachedPrompts = this.getCached('savedPrompts');
    if (cachedPrompts) {
      return cachedPrompts;
    }

    const prompts = await this.request({
      method: 'GET',
      url: '/api/user/prompts'
    });

    this.setCached('savedPrompts', prompts);
    return prompts;
  }

  async getWordPressSites() {
    const cachedSites = this.getCached('wpSites');
    if (cachedSites) {
      return cachedSites;
    }

    const sites = await this.request({
      method: 'GET',
      url: '/api/user/wordpress-sites'
    });

    this.setCached('wpSites', sites);
    return sites;
  }

  async getAvailableModels() {
    const cachedModels = this.getCached('availableModels');
    if (cachedModels) {
      return cachedModels;
    }

    const models = await this.request({
      method: 'GET',
      url: '/api/user/available-models'
    });

    this.setCached('availableModels', models);
    return models;
  }

  async createPrompt(data) {
    const result = await this.request({
      method: 'POST',
      url: '/api/user/prompts',
      data
    });
    this.cache.delete('savedPrompts');
    return result;
  }

  async updatePrompt(promptId, data) {
    const result = await this.request({
      method: 'PUT',
      url: `/api/user/prompts/${promptId}`,
      data
    });
    this.cache.delete('savedPrompts');
    return result;
  }

  async deletePrompt(promptId) {
    const result = await this.request({
      method: 'DELETE',
      url: `/api/user/prompts/${promptId}`
    });
    this.cache.delete('savedPrompts');
    return result;
  }

  
  async togglePromptFavorite(promptId) {
    return this.request({
      method: 'PUT',
      url: `/api/user/prompts/${promptId}/favorite`
    });
  }
  
  async duplicatePrompt(promptId) {
    return this.request({
      method: 'POST',
      url: `/api/user/prompts/${promptId}/duplicate`
    });
  }

  async getPrompt(promptId) {
    const cachedPrompt = this.getCached(`prompt-${promptId}`);
    if (cachedPrompt) {
      return cachedPrompt;
    }

    const prompt = await this.request({
      method: 'GET',
      url: `/api/user/prompts/${promptId}`
    });

    this.setCached(`prompt-${promptId}`, prompt);
    return prompt;
  }
  
  async generatePrompt(data) {
    return this.request({
      method: 'POST',
      url: '/api/user/generate-prompt',
      data
    });
  }

  async getWordPressSites() {
    return this.request({
      method: 'GET',
      url: '/api/user/wordpress-sites'
    });
  }

  async getWordPressCredentials(siteId) {
    return this.request({
      method: 'GET',
      url: `/api/user/wordpress-sites/${siteId}/credentials`
    });
  }

  async getCategories(siteId) {
    return this.request({
      method: 'GET',
      url: `/api/user/wordpress-sites/${siteId}/categories`
    });
  }

  async generateArticle(data) {
    return this.request({
      method: 'POST',
      url: '/api/user/generate-article',
      data
    });
  }

  async generateImage(data) {
    return this.request({
      method: 'POST',
      url: '/api/user/generate-image',
      data
    });
  }

  async uploadMedia(formData) {
    return this.request({
      method: 'POST',
      url: '/api/user/upload-media',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async getAvailableModels() {
    console.log('Calling getAvailableModels endpoint');
    return this.request({
      method: 'GET',
      url: '/api/user/available-models'
    });
  }

  

  async getAvailableImageModels() {
    return this.request({
      method: 'GET', 
      url: '/api/user/available-image-models'
    });
  }

  async searchProducts(params) {
    return this.request({
      method: 'POST',
      url: '/api/user/search-products',
      data: {
        query: params.query,
        maxResults: params.maxResults,
        region: params.region
      }
    });
  }

  async validateAndGatherProducts(data) {
    return this.request({
      method: 'POST',
      url: '/api/user/validate-and-gather',
      data
    });
  }

  async generateProductReview(data) {
    return this.request({
      method: 'POST',
      url: '/api/user/generate-product-review', 
      data
    });
  }

  

  // Create WordPress post through the generate-article endpoint
  async createWordPressPost(data) {
    return this.request({
      method: 'POST',
      url: '/api/user/generate-article',
      data: {
        title: data.title,
        content: data.content,
        siteId: data.siteId,
        category: data.category,
        featured_media: data.featured_media,
        model: 'claude', 
        selectedPrompt: data.promptId 
      }
    });
  }
}

// Create a singleton instance
const apiClient = new ApiClient(process.env.REACT_APP_API_BASE_URL || 'http://localhost:8000');

export default apiClient;