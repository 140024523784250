import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

const Callback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if there's an error in the URL
    const params = new URLSearchParams(location.search);
    const error = params.get('error');
    
    if (error) {
      // Clear any auth state
      localStorage.clear();
      sessionStorage.clear();
      // Redirect to root with clean slate
      window.location.replace('/');
    }
  }, [location]);

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh' 
    }}>
      <CircularProgress />
    </Box>
  );
};

export default Callback;