import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { BrainCircuit } from 'lucide-react';
import ModelSelector from './ModelSelector';
import { useAIModels } from '../hooks/useAIModels';
import { useAuth } from '../context/AuthContext';

const GlobalModelSelector = () => {
  const { authenticatedRequest } = useAuth();
  const { getAuxiliaryModel } = useAIModels(authenticatedRequest);
  const [selectedModel, setSelectedModel] = useState('');

  useEffect(() => {
    const validModel = getAuxiliaryModel();
    if (validModel && validModel !== selectedModel) {
      setSelectedModel(validModel);
    }
  }, [getAuxiliaryModel]);

  const handleModelChange = (event) => {
    const newModel = event.target.value;
    setSelectedModel(newModel);
    localStorage.setItem('globalAiModel', newModel);
  };

  if (!selectedModel) return null;

  return (
    <ModelSelector
      icon={<BrainCircuit />}
      label="AUXILIARY AI MODEL"
      tooltip="This model will be used for secondary tasks like generating image prompts and enhancing user inputs but not for main content creation"
      value={selectedModel}
      onChange={handleModelChange}
      variant="sidebar"
      showDescription={true}
    />
  );
};

export default GlobalModelSelector;