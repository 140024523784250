import React, { useState, useEffect } from 'react';
import ImageControls from './ImageControls';
import {
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  ListSubheader, 
  FormHelperText, 
  Switch,
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Pending as PendingIcon,
  Refresh as RefreshIcon,
} from '@mui/icons-material';
import { Eye, FileText, HelpCircle, X } from 'lucide-react';
import { useAIModels } from '../hooks/useAIModels';
import ModelSelector from './ModelSelector';
import PageHeader from './PageHeader';
import { useAuth } from '../context/AuthContext';
import apiClient from '../utils/apiUtils';
import aiClient from '../api/clients/AiClient';
import imageClient from '../api/clients/ImageClient';
import WordPressClient from '../utils/WordPressClient';

const MAX_RETRIES = 3;
const RETRY_DELAY = 3000;

const UrlRewriter = () => {
  // State Management
  const [urls, setUrls] = useState('');
  const [featuredImage, setFeaturedImage] = useState(false);
  const [featuredImageModel, setFeaturedImageModel] = useState('fal-ai/flux/schnell');
  const [subheadingImages, setSubheadingImages] = useState(false);
  const [subheadingImageModel, setSubheadingImageModel] = useState('fal-ai/flux/schnell');
  const [maxSubheadings, setMaxSubheadings] = useState(5);
  const [dimension, setDimension] = useState('square');

  // Auth Context
  const { authenticatedRequest, error: authError, loading: authLoading } = useAuth();
  
  // Sites and Categories State
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');

  // Form State
  const [model, setModel] = useState('claude');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  
  // Queue State
  const [queuedUrls, setQueuedUrls] = useState([]);
  const [retryCount, setRetryCount] = useState({});

  const { models: availableModels, loading: modelsLoading, error: modelsError } = useAIModels(authenticatedRequest);
  const [useAIEnhancement, setUseAIEnhancement] = useState(false);
  
  // Image Settings State
  const [includeImages, setIncludeImages] = useState(false);
  const [imageFrequency, setImageFrequency] = useState('every');
  const [featuredImageStyle, setFeaturedImageStyle] = useState('realistic_image');
  const [subheadingImageStyle, setSubheadingImageStyle] = useState('realistic_image');
  const [featuredDimension, setFeaturedDimension] = useState('square');
  const [subheadingDimension, setSubheadingDimension] = useState('square');
  const [maxSubheadingImages, setMaxSubheadingImages] = useState(5);
  const [excludeIntro, setExcludeIntro] = useState(false);
  const [excludeConclusion, setExcludeConclusion] = useState(false);
  const [excludeFAQ, setExcludeFAQ] = useState(false);
  const [featuredImagePrompt, setFeaturedImagePrompt] = useState('');

  // Prompt State
  const [prompts, setPrompts] = useState({
    systemTemplates: [],
    userPrompts: []
  });
  const [selectedPrompt, setSelectedPrompt] = useState('');


  // Validation State
  const [urlsTouched, setUrlsTouched] = useState(false);
  const hasUrls = urls.trim().length > 0;
  const showUrlError = urlsTouched && !hasUrls;

  // Preview State
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewPrompt, setPreviewPrompt] = useState(null);

  

  const [helpDialogOpen, setHelpDialogOpen] = useState(false);


  useEffect(() => {
    fetchInitialData();
   
  }, []);

  useEffect(() => {
    if (selectedSite) {
      fetchCategories();
    }
  }, [selectedSite]);

  useEffect(() => {
    const initializeServices = async () => {
      try {
        const keys = await authenticatedRequest(() => apiClient.getApiKeys());
        
        if (!keys.openai && !keys.anthropic && !keys.fal) {
          throw new Error("No API keys configured. Please add required API keys in settings.");
        }
        
        aiClient.setApiKeys(keys);
        aiClient.updateModelConfigs(availableModels);
  
        imageClient.setAvailableModels(availableModels);
        imageClient.setAiClient(aiClient);
        imageClient.setApiKeys(keys);
  
        if (availableModels.length > 0 && !model) {
          const defaultModel = availableModels[0].value;
          setModel(defaultModel);
        }
        
      } catch (error) {
        console.error("Failed to initialize services:", error);
        setError(error.message || 'Failed to initialize services');
      }
    };
  
    if (!authLoading && !modelsLoading && availableModels.length > 0) {
      initializeServices();
    }
  }, [authLoading, modelsLoading, availableModels]);

  const getModelId = (selectedModel) => {
    // Map the UI model selection to the actual model ID
    switch (selectedModel) {
      case 'claude':
        return 'claude-3-sonnet-20240222';
      case 'gpt-4':
        return 'gpt-4o-mini';
      default:
        return selectedModel;
    }
  };

  const shouldExcludeHeading = (headingText) => {
    const text = headingText.toLowerCase();
    if (excludeIntro && (text.includes('introduction') || text.includes('overview'))) return true;
    if (excludeConclusion && (text.includes('conclusion') || text.includes('summary'))) return true;
    if (excludeFAQ && (text.includes('faq') || text.includes('frequently asked'))) return true;
    return false;
  };

 
  const fetchInitialData = async () => {
    try {
      const [sitesData, promptsData] = await Promise.all([
        authenticatedRequest(() => apiClient.getWordPressSites()),
        authenticatedRequest(() => apiClient.getSavedPrompts())
      ]);
  
      setSites(sitesData || []);
      if (sitesData?.length > 0) {
        setSelectedSite(sitesData[0].id);
      }
  
      if (promptsData) {
        setPrompts({
          systemTemplates: promptsData.system_templates || [],
          userPrompts: promptsData.user_prompts || []
        });
      }
    } catch (err) {
      console.error('Failed to load initial data:', err);
      setError('Failed to load initial data');
      setSites([]);
      setPrompts({
        systemTemplates: [],
        userPrompts: []
      });
    }
  };

  const fetchCategories = async () => {
    if (!selectedSite) return;
    try {
      const categoriesData = await authenticatedRequest(() => 
        apiClient.getCategories(selectedSite)
      );
      setCategories(categoriesData);
    } catch (err) {
      setError('Failed to load categories');
    }
  };

  
  const generateArticle = async (url, retryAttempt = 0) => {
    try {
      console.log('Starting article generation for URL:', url, 'Attempt:', retryAttempt + 1);
  
      // Scrape content
      updateUrlStatus(url, 'processing', 'Scraping content...');
      const scrapedResult = await apiClient.scrapeUrl(url);
      
      
      if (!scrapedResult?.success) {
        throw new Error(scrapedResult?.error || 'Failed to scrape content');
      }
  
      const site = sites.find(s => s.id === selectedSite);
      if (!site) throw new Error('Selected site not found');
  
      // Initialize WordPress client
      const wpClient = new WordPressClient(site);
      const credentials = await authenticatedRequest(() =>
        apiClient.getWordPressCredentials(selectedSite)
      );
      wpClient.setCredentials(credentials.username, credentials.password);
     
      const customPrompt = scrapedResult.data?.content || 'No content available.';
      console.log('Custom Prompt for AI Writer (Content Only):', customPrompt);

      if (typeof customPrompt !== 'string') {
          throw new Error('Custom prompt must be a valid string.');
      }

   

      updateUrlStatus(url, 'processing', 'Generating content...');
      const content = await aiClient.generateArticleContent({
        title: '',
        modelId: getModelId(model),
        customPrompt: customPrompt
      });
  
      console.log('Generated content:', {
        preview: content.slice(0, 200) + '...',
        length: content.length,
        modelUsed: getModelId(model)
      });
  
      let featuredMediaId = null;
      let processedContent = content;
  
      // Handle featured image
      if (featuredImage) {
        try {
          updateUrlStatus(url, 'processing', 'Generating featured image...');
          console.log('Generating featured image with settings:', {
            prompt: featuredImagePrompt || scrapedResult.data.title,
            model: featuredImageModel,
            dimension: featuredDimension,
            style: featuredImageStyle,
            aiEnhancement: useAIEnhancement
          });
  
          const imageData = await imageClient.generateImage({
            prompt: featuredImagePrompt || scrapedResult.data.title,
            model: featuredImageModel,
            dimension: featuredDimension,
            style: featuredImageStyle,
            enhancePrompt: useAIEnhancement,
            context: {
              articleTitle: scrapedResult.data.title
            }
          });
  
          console.log('Featured image generated:', {
            success: !!imageData?.image_url,
            url: imageData?.image_url
          });
  
          if (imageData?.image_url) {
            const formData = new FormData();
            formData.append('file', await (await fetch(imageData.image_url)).blob());
            formData.append('siteId', selectedSite);
  
            const uploadResult = await authenticatedRequest(() => 
              apiClient.uploadMedia(formData)
            );
            featuredMediaId = uploadResult.media_id;
            console.log('Featured image uploaded:', { mediaId: featuredMediaId });
          }
        } catch (imageError) {
          console.error('Featured image error:', imageError);
          updateUrlStatus(url, 'processing', 'Featured image failed, continuing...');
        }
      }
  
      // Handle subheading images
      if (subheadingImages) {
        try {
          const parser = new DOMParser();
          const doc = parser.parseFromString(processedContent, 'text/html');
          const headings = Array.from(doc.querySelectorAll('h2'));
          let imagesGenerated = 0;
  
          console.log('Processing subheading images:', {
            totalHeadings: headings.length,
            maxImages: maxSubheadingImages
          });
  
          for (const heading of headings) {
            if (imagesGenerated >= maxSubheadingImages) break;
            if (shouldExcludeHeading(heading.textContent)) {
              console.log('Skipping excluded heading:', heading.textContent);
              continue;
            }
  
            try {
              updateUrlStatus(url, 'processing', `Generating image for section: ${heading.textContent}`);
              console.log('Generating subheading image:', {
                heading: heading.textContent,
                model: subheadingImageModel,
                style: subheadingImageStyle
              });
  
              const imageData = await imageClient.generateImage({
                prompt: heading.textContent,
                model: subheadingImageModel,
                dimension: subheadingDimension,
                style: subheadingImageStyle,
                enhancePrompt: useAIEnhancement,
                context: {
                  articleTitle: scrapedResult.data.title
                }
              });
  
              if (imageData?.image_url) {
                const response = await fetch(imageData.image_url);
                const blob = await response.blob();
                const uploadResult = await wpClient.uploadMedia(
                  blob,
                  `subheading-${heading.textContent.replace(/[^a-zA-Z0-9]/g, '-')}.png`
                );
  
                console.log('Subheading image uploaded:', {
                  heading: heading.textContent,
                  imageId: uploadResult?.id
                });
  
                if (uploadResult) {
                  const img = doc.createElement('img');
                  img.src = uploadResult.url;
                  img.alt = heading.textContent;
                  img.className = `wp-image-${uploadResult.id}`;
                  img.style.cssText = 'max-width: 100%; height: auto; margin: 20px 0;';
                  heading.parentNode.insertBefore(img, heading.nextSibling);
                  imagesGenerated++;
                }
              }
            } catch (error) {
              console.error(`Failed to process image for heading "${heading.textContent}":`, error);
              updateUrlStatus(
                url, 
                'processing',
                `Failed to generate image for "${heading.textContent}", continuing...`
              );
            }
          }
  
          processedContent = doc.body.innerHTML;
          console.log('Completed subheading images:', {
            imagesGenerated,
            contentLength: processedContent.length
          });
        } catch (error) {
          console.error('Subheading images error:', error);
          updateUrlStatus(url, 'processing', 'Subheading images failed, continuing...');
        }
      }
  
      // Create WordPress post
      updateUrlStatus(url, 'processing', 'Creating WordPress post...');
      console.log('Creating WordPress post:', {
        title: scrapedResult.data.title,
        contentLength: processedContent.length,
        featuredMediaId,
        category
      });
  
      const post = await wpClient.createPost({
        title: scrapedResult.data.title,
        content: processedContent,
        categoryId: category,
        featuredMediaId,
        status: 'draft'
      });
  
      console.log('Post created successfully:', {
        postId: post.id,
        status: post.status
      });
  
      updateUrlStatus(url, 'completed', `Created successfully (Post ID: ${post.id})`);
      setRetryCount(prev => ({ ...prev, [url]: 0 }));
  
    } catch (err) {
      console.error('Error in generateArticle:', {
        url,
        retryAttempt,
        error: err.message,
        stack: err.stack
      });
  
      if (!err.message.includes('Content access failed')) {
        await handleGenerationError(err, url, retryAttempt);
      } else {
        updateUrlStatus(url, 'error', err.message);
      }
    }
  };


  const handleGenerationError = async (err, url, retryAttempt) => {
    if (retryAttempt < MAX_RETRIES && isRetryableError(err)) {
      updateUrlStatus(url, 'retrying', `Attempt ${retryAttempt + 1}/${MAX_RETRIES}...`);
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * Math.pow(2, retryAttempt)));
      return generateArticle(url, retryAttempt + 1);
    }

    setRetryCount(prev => ({ ...prev, [url]: (prev[url] || 0) + 1 }));
    updateUrlStatus(url, 'error', err.message || 'Failed to process URL');
  };

  const isRetryableError = (error) => {
    const message = error.message.toLowerCase();
    return (
      message.includes('rate limit') ||
      message.includes('timeout') ||
      message.includes('too many requests') ||
      message.includes('service unavailable')
    );
  };

  const initializeQueue = (urlList) => {
    const newQueue = urlList.map(url => ({
      url,
      status: 'queued',
      message: 'Waiting to start...'
    }));
    setQueuedUrls(newQueue);
  };

  const updateUrlStatus = (url, status, message) => {
    setQueuedUrls(prev =>
      prev.map(item =>
        item.url === url
          ? { ...item, status, message }
          : item
      )
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setRetryCount({});
    setIsGenerating(true);

    const urlList = urls.split('\n')
      .map(url => url.trim())
      .filter(url => url && url.startsWith('http'));
    
    if (urlList.length === 0) {
      setError('Please enter at least one valid URL');
      setLoading(false);
      setIsGenerating(false);
      return;
    }

    if (urlList.length > 25) {
      setError('Maximum 25 URLs allowed');
      setLoading(false);
      setIsGenerating(false);
      return;
    }

    initializeQueue(urlList);

    try {
      for (const url of urlList) {
        await generateArticle(url);
      }
    } catch (error) {
      console.error('Error processing URLs:', error);
    } finally {
      setLoading(false);
      setIsGenerating(false);
    }
  };

  const handlePreviewPrompt = (promptId) => {
    const prompt = [
      ...prompts.systemTemplates,
      ...prompts.userPrompts
    ].find(p => p.id === promptId);
    
    if (prompt) {
      setPreviewPrompt(prompt);
      setPreviewOpen(true);
    }
  };

  const getStatusIcon = (item) => {
    switch (item.status) {
      case 'completed':
        return <CheckCircleIcon color="success" />;
      case 'error':
        return <ErrorIcon color="error" />;
      case 'processing':
      case 'retrying':
        return <CircularProgress size={24} />;
      default:
        return <PendingIcon color="disabled" />;
    }
  };

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
     
    {/* Page Header with Help Button */}
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      mb: 4 
    }}>
      <PageHeader 
        title="URL Rewriter" 
        icon={<FileText size={24} color="#E04C16" />} 
      />
      <Button
        onClick={() => setHelpDialogOpen(true)}
        startIcon={<HelpCircle size={18} />}
        sx={{
          bgcolor: '#E04C16',
          color: 'white',
          borderRadius: '20px',
          px: 3,
          '&:hover': {
            bgcolor: '#c43d0f'
          }
        }}
      >
        How to Use
      </Button>
    </Box>

    {/* Help Dialog */}
    <Dialog 
      open={helpDialogOpen}
      onClose={() => setHelpDialogOpen(false)}
      PaperProps={{
        sx: {
          position: 'fixed',
          right: 32,
          top: '50%',
          transform: 'translateY(-50%)',
          m: 0,
          width: 400,
          maxWidth: '90vw',
          maxHeight: '80vh',
          borderRadius: 2
        }
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 2,
        borderBottom: '1px solid',
        borderColor: 'divider'
      }}>
        <DialogTitle sx={{ p: 0, fontSize: '1.25rem' }}>
          How to Use URL Rewriter
        </DialogTitle>
        <IconButton onClick={() => setHelpDialogOpen(false)} size="small">
          <X size={18} />
        </IconButton>
      </Box>
      <DialogContent>
      <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
          Welcome to URL Rewriter
        </Typography>
        <Typography variant="body2" sx={{ mb: 4 }}>
          URL Rewriter helps you generate fresh, unique content inspired by existing articles while maintaining your preferred writing style and format. Before proceeding, please ensure you have appropriate rights or permissions to reference and rewrite content from your selected sources.
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
          Getting Started
        </Typography>
        <Typography variant="body2" sx={{ mb: 4 }}>
          Begin by selecting your target WordPress site from the dropdown menu and choosing the appropriate category for your content. Next, select a writing style template that matches your desired tone and format. Finally, enter the URLs of the articles you'd like to rewrite, with each URL on a new line.
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
          Customizing Your Content
        </Typography>
        <Typography variant="body2" sx={{ mb: 4 }}>
          Choose between Claude 3.5 or GPT-4 as your AI model for content generation. If you want to enhance your articles with visuals, enable the image generation feature. This allows you to add both featured images and inline images throughout your content, with full control over style and placement.
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
          Important Guidelines
        </Typography>
        <Typography variant="body2" sx={{ mb: 4 }}>
          You can process up to 25 URLs in a single batch. Each URL will be processed sequentially, and you can monitor the progress in real-time through the URL Queue panel. Please note that some websites may restrict access to their content, in which case the rewriting process for that particular URL will be skipped.
        </Typography>

        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
          Best Practices
        </Typography>
        <Typography variant="body2">
          For optimal results, ensure your source URLs are publicly accessible and lead to complete articles. Keep an eye on the URL Queue to track the status of each article being processed. Remember to respect copyright laws and content ownership rights when selecting source material for inspiration.
        </Typography>
      </DialogContent>
    </Dialog>
      
      {/* Main content */}
      <Grid container spacing={3}>
        {/* Form controls */}
        <Grid item xs={12} md={7}>
          <Paper sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* Site selection */}
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>WordPress Site</InputLabel>
                    <Select
                      value={selectedSite}
                      label="WordPress Site"
                      onChange={(e) => setSelectedSite(e.target.value)}
                      disabled={loading}
                    >
                      {sites.map((site) => (
                        <MenuItem key={site.id} value={site.id}>
                          {site.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Category selection */}
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={category}
                      label="Category"
                      onChange={(e) => setCategory(e.target.value)}
                      disabled={loading || !selectedSite}
                    >
                      {categories.map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>
                          {cat.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Prompt template selection */}
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <FormControl fullWidth required>
  <InputLabel>Writing Style Template</InputLabel>
  <Select
    value={selectedPrompt}
    label="Writing Style Template"
    onChange={(e) => setSelectedPrompt(e.target.value)}
    disabled={loading}
  >
    {/* System Templates Section */}
    {prompts.systemTemplates.length > 0 && (
      <ListSubheader>Default Prompts</ListSubheader>
    )}
    {prompts.systemTemplates.map((prompt) => (
      <MenuItem key={prompt.id} value={prompt.id}>
        <Box>
          <Typography variant="subtitle2">{prompt.name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {prompt.settings?.description || 'System template'}
          </Typography>
        </Box>
      </MenuItem>
    ))}

    {/* User Templates Section */}
    {prompts.userPrompts.length > 0 && (
      <ListSubheader>My Saved Prompts</ListSubheader>
    )}
    {prompts.userPrompts.map((prompt) => (
      <MenuItem key={prompt.id} value={prompt.id}>
        {prompt.name}
      </MenuItem>
    ))}

    {/* Show message if no prompts available */}
    {!prompts.systemTemplates.length && !prompts.userPrompts.length && (
      <MenuItem disabled>
        No templates available
      </MenuItem>
    )}
  </Select>
</FormControl>
                    {selectedPrompt && (
                      <IconButton 
                        onClick={() => handlePreviewPrompt(selectedPrompt)}
                        sx={{ ml: 1 }}
                      >
                        <Eye />
                      </IconButton>
                    )}
                  </Box>
                </Grid>

                {/* URLs input */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="URLs (one per line, max 25)"
                    multiline
                    rows={5}
                    value={urls}
                    onChange={(e) => setUrls(e.target.value)}
                    onBlur={() => setUrlsTouched(true)}
                    required
                    disabled={loading}
                    placeholder="Enter URLs to rewrite, one per line"
                    error={showUrlError}
                    helperText={showUrlError ? "At least one valid URL is required" : ""}
                  />
                </Grid>

                {/* AI Model selection */}
                {/* Replace the existing AI Model FormControl with */}
<Grid item xs={12}>
  <Box sx={{ display: 'flex', alignItems: 'start', gap: 1, width: '100%' }}>
    <Box sx={{ flexGrow: 1 }}>
      <ModelSelector
        value={model}
        onChange={(e) => setModel(e.target.value)}
        required
        tooltip="Choose the AI model that will generate your content. Different models have different strengths and capabilities."
        label="AI Model"
        disabled={loading || modelsLoading}
        showDescription={true}
      />
      {modelsError && (
        <FormHelperText error>
          {modelsError}
        </FormHelperText>
      )}
    </Box>
  </Box>
</Grid>

{/* Add AI Enhancement Toggle */}
<Grid item xs={12}>
  <Box sx={{ bgcolor: 'background.default', borderRadius: 1, mb: 3 }}>
    <FormControlLabel
      control={
        <Switch
          checked={useAIEnhancement}
          onChange={(e) => setUseAIEnhancement(e.target.checked)}
          disabled={loading}
        />
      }
      label="Enhance Image Prompts with AI"
    />
    <FormHelperText>
      AI will automatically enhance image prompts for better results
    </FormHelperText>
  </Box>
</Grid>

               {/* Replace the existing ImageControls section with */}
<Grid item xs={12}>
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
    {/* Featured Image Section */}
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={featuredImage}
            onChange={(e) => setFeaturedImage(e.target.checked)}
            disabled={loading}
          />
        }
        label="Generate Featured Image"
      />
      
      {featuredImage && (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={2}
                label="Featured Image Prompt (optional)"
                placeholder="Leave blank to let AI generate based on article title and content"
                value={featuredImagePrompt}
                onChange={(e) => setFeaturedImagePrompt(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <ImageControls
                model={featuredImageModel}
                setModel={setFeaturedImageModel}
                style={featuredImageStyle}
                setStyle={setFeaturedImageStyle}
                dimension={featuredDimension}
                setDimension={setFeaturedDimension}
                loading={loading}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>

    {/* Subheading Images Section */}
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={subheadingImages}
            onChange={(e) => setSubheadingImages(e.target.checked)}
            disabled={loading}
          />
        }
        label="Generate Subheading Images"
      />

      {subheadingImages && (
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Configure settings for subheading images. A new image will be generated for each subheading.
              </Typography>
            </Grid>
            
            {/* Image Generation Controls */}
            <Grid item xs={12}>
              <ImageControls
                model={subheadingImageModel}
                setModel={setSubheadingImageModel}
                style={subheadingImageStyle}
                setStyle={setSubheadingImageStyle}
                dimension={subheadingDimension}
                setDimension={setSubheadingDimension}
                loading={loading}
              />
            </Grid>

            {/* Maximum Images Control */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="number"
                label="Maximum Subheading Images"
                value={maxSubheadingImages}
                onChange={(e) => {
                  const inputValue = e.target.value === '' ? '' : parseInt(e.target.value);
                  if (inputValue === '') {
                    setMaxSubheadingImages('');
                  } else {
                    const limitedValue = Math.min(Math.max(0, inputValue), 10);
                    setMaxSubheadingImages(limitedValue);
                  }
                }}
                InputProps={{ 
                  inputProps: { 
                    min: 0, 
                    max: 10 
                  } 
                }}
                helperText={`You can generate 0 to 10 images`}
              />
            </Grid>

            {/* Section Exclusion Controls */}
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
                Exclude Images from Sections
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={excludeIntro}
                      onChange={(e) => setExcludeIntro(e.target.checked)}
                      disabled={loading}
                    />
                  }
                  label="Skip Introduction Section"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={excludeConclusion}
                      onChange={(e) => setExcludeConclusion(e.target.checked)}
                      disabled={loading}
                    />
                  }
                  label="Skip Conclusion Section"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={excludeFAQ}
                      onChange={(e) => setExcludeFAQ(e.target.checked)}
                      disabled={loading}
                    />
                  }
                  label="Skip FAQ Section"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  </Box>
</Grid>
               

                {/* Error display */}
                <Grid item xs={12}>
                  {error && (
                    <Alert severity="error">
                      {typeof error === 'string' ? error : 'An error occurred'}
                    </Alert>
                  )}
                </Grid>

                {/* Submit button */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={!selectedSite || !category || !selectedPrompt || !hasUrls || loading}
                    fullWidth
                  >
                    {loading ? 'Processing URLs...' : 'Process URLs'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

        {/* URL Queue */}
        <Grid item xs={12} md={5}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              URL Queue
            </Typography>
            {queuedUrls.length === 0 ? (
              <Box sx={{ textAlign: 'center', py: 8 }}>
                <Typography color="textSecondary">
                  No URLs in queue. Add URLs and click "Process URLs" to begin.
                </Typography>
              </Box>
            ) : (
              <List>
                {queuedUrls.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      borderBottom: '1px solid',
                      borderColor: 'divider',
                      '&:last-child': {
                        borderBottom: 'none'
                      },
                      py: 2
                    }}
                  >
                    <ListItemIcon>
                      {getStatusIcon(item)}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.url}
                      secondary={item.message}
                      primaryTypographyProps={{
                        fontWeight: 500,
                        sx: { wordBreak: 'break-all' }
                      }}
                      secondaryTypographyProps={{
                        fontSize: '0.875rem'
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* Preview Dialog */}
      <Dialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          {previewPrompt?.name}
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ whiteSpace: 'pre-wrap' }}>
            {previewPrompt?.prompt_text}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UrlRewriter;