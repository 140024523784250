import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Alert,
    Snackbar,
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
} from '@mui/material';
import { 
    Sparkles,
    Bot,
    Image,
    FileText,
    Zap
} from 'lucide-react';
import axios from 'axios';
import { config } from '../config';

const SubscriptionPlans = () => {
    const { getAccessTokenSilently } = useAuth0();
    const location = useLocation();
    const navigate = useNavigate();
    const [prices, setPrices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [subscription, setSubscription] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showCanceled, setShowCanceled] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    useEffect(() => {
        // Check URL parameters
        const searchParams = new URLSearchParams(location.search);
        const success = searchParams.get('success');
        const canceled = searchParams.get('canceled');

        if (success) {
            setSuccessMessage('Subscription successful! Welcome aboard.');
            setShowSuccess(true);
            // Remove query parameters from URL
            navigate('/account/subscription', { replace: true });
        }
        if (canceled) {
            setShowCanceled(true);
            navigate('/account/subscription', { replace: true });
        }
    }, [location, navigate]);

    useEffect(() => {
        const initializeData = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                await fetchData(accessToken);
            } catch (err) {
                console.error('Authentication error:', err);
                setError('Authentication failed. Please try again.');
                setLoading(false);
            }
        };

        initializeData();
    }, [getAccessTokenSilently]);

    const fetchData = async (accessToken) => {
        try {
            const [pricesResponse, subscriptionResponse] = await Promise.all([
                axios.get(`${config.api.baseUrl}/api/stripe/prices`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }),
                axios.get(`${config.api.baseUrl}/api/stripe/subscription`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                })
            ]);

            setPrices(pricesResponse.data.prices);
            setSubscription(subscriptionResponse.data.subscription);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Failed to load subscription data');
        } finally {
            setLoading(false);
        }
    };

    const handleSubscribe = async (priceId) => {
        setError(null);
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await axios.post(
                `${config.api.baseUrl}/api/stripe/create-checkout-session`,
                { price_id: priceId },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            );

            if (response.data?.url) {
                window.location.href = response.data.url;
            } else {
                setError('Invalid response from server');
                console.error('Invalid checkout response:', response.data);
            }
        } catch (err) {
            const errorMessage = err.response?.data?.detail || 'Failed to initiate checkout. Please try again.';
            setError(errorMessage);
            console.error('Checkout error:', err);
        }
    };

    const handleReactivate = async (subscriptionId) => {
        try {
            setError(null);
            const accessToken = await getAccessTokenSilently();
            
            if (!window.confirm('Would you like to restart your membership?')) {
                return;
            }
    
            const response = await axios.post(
                `${config.api.baseUrl}/api/stripe/reactivate-subscription`,
                { subscription_id: subscriptionId },  // Make sure this matches your Pydantic model
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
    
            if (response.data?.subscription) {
                // Refresh subscription data
                await fetchData(accessToken);
                
                setSuccessMessage('Your subscription has been successfully reactivated!');
                setShowSuccess(true);
            }
    
        } catch (err) {
            console.error('Reactivation error:', err);
            const errorMessage = err.response?.data?.detail || 'Failed to reactivate subscription';
            setError(errorMessage);
        }
    };

    const handleCancel = async () => {
        try {
            setError(null);
            const accessToken = await getAccessTokenSilently();
            
            // Add confirmation dialog
            if (!window.confirm('Are you sure you want to cancel your subscription?')) {
                return;
            }

            await axios.post(
                `${config.api.baseUrl}/api/stripe/cancel-subscription`,
                { subscription_id: subscription.id },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Refresh subscription data
            await fetchData(accessToken);
            
            setSuccessMessage('Subscription cancelled successfully.');
            setShowSuccess(true);

        } catch (err) {
            console.error('Cancel error:', err);
            const errorMessage = err.response?.data?.detail || 'Failed to cancel subscription';
            setError(errorMessage);
        }
    };

    const handleCloseSnackbar = () => {
        setShowSuccess(false);
        setShowCanceled(false);
    };

    const getSubscriptionStatus = (status, cancelAtPeriodEnd) => {
        if (status === 'active' && cancelAtPeriodEnd) {
            return 'Canceling at end of period';
        }
        return status;
    };

    const features = [
        {
            icon: <Bot size={24} color="#E04C16" />,
            text: "Access to GPT-4 and Claude AI"
        },
        {
            icon: <Image size={24} color="#E04C16" />,
            text: "Unlimited AI Image Generation"
        },
        {
            icon: <FileText size={24} color="#E04C16" />,
            text: "Unlimited Article Generation"
        },
        {
            icon: <Sparkles size={24} color="#E04C16" />,
            text: "Custom Article Templates"
        },
        {
            icon: <Zap size={24} color="#E04C16" />,
            text: "Priority Support"
        }
    ];

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
                <CircularProgress sx={{ color: '#E04C16' }} />
            </Box>
        );
    }

    return (
        <Container maxWidth="lg">
            <Box sx={{ mt: 4, mb: 4 }}>
                {error && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                        {error}
                    </Alert>
                )}

                <Snackbar
                    open={showSuccess}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity="success">
                        {successMessage}
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={showCanceled}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity="info">
                        Checkout canceled. Please try again when you're ready.
                    </Alert>
                </Snackbar>

                {subscription ? (
                    <Card 
                    sx={{ 
                        maxWidth: 400,
                        mx: 'auto',
                        background: 'linear-gradient(145deg, #ffffff 0%, #f9f9f9 100%)',
                        boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                        borderRadius: 2
                    }}
                >
                    <CardContent sx={{ p: 4 }}>
                        <Typography variant="h5" gutterBottom sx={{ color: '#E04C16', fontWeight: 600 }}>
                            {subscription.cancel_at_period_end ? 'Subscription Ending' : 'Current Subscription'}
                        </Typography>
                        
                        <Typography variant="h4" sx={{ mt: 2, fontWeight: 700 }}>
                            ${subscription.plan.amount / 100}
                            <Typography component="span" variant="h6" color="text.secondary">
                                /month
                            </Typography>
                        </Typography>
            
                        <Divider sx={{ my: 2 }} />
                        
                        {subscription.cancel_at_period_end ? (
                            <Alert severity="warning" sx={{ mb: 2 }}>
                                Your premium features will end on {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
                            </Alert>
                        ) : (
                            <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                                Next billing date: {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
                            </Typography>
                        )}
            
                        <Typography variant="subtitle1" sx={{ mt: 3, mb: 2, fontWeight: 600 }}>
                            {subscription.cancel_at_period_end ? 'Features you\'ll lose access to:' : 'Your active features:'}
                        </Typography>
            
                        <List>
                            {features.map((feature, index) => (
                                <ListItem key={index} sx={{ px: 0 }}>
                                    <ListItemIcon sx={{ minWidth: 40 }}>
                                        {feature.icon}
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={feature.text}
                                        primaryTypographyProps={{
                                            sx: { 
                                                fontWeight: 500,
                                                color: subscription.cancel_at_period_end ? 'text.secondary' : 'text.primary'
                                            }
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
            
                        {subscription.cancel_at_period_end ? (
                            <Box sx={{ mt: 3 }}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    sx={{
                                        backgroundColor: '#E04C16',
                                        '&:hover': {
                                            backgroundColor: '#c43e12'
                                        },
                                        mb: 2,
                                        py: 1.5
                                    }}
                                    onClick={() => handleReactivate(subscription.id)}
                                >
                                    Restart Membership Now
                                </Button>
                                <Typography variant="body2" color="text.secondary" align="center">
                                    Restart now to keep your premium features without interruption
                                </Typography>
                            </Box>
                        ) : (
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                    color: '#E04C16',
                                    borderColor: '#E04C16',
                                    '&:hover': {
                                        borderColor: '#c43e12',
                                        color: '#c43e12'
                                    },
                                    mt: 2
                                }}
                                onClick={handleCancel}
                            >
                                Cancel Subscription
                            </Button>
                        )}
                    </CardContent>
                </Card>
                ) : (
                    <Box>
                        <Typography variant="h4" align="center" gutterBottom sx={{ color: '#E04C16', fontWeight: 600, mb: 4 }}>
                            Choose Your Plan
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            {prices.map((price) => (
                                <Card
                                    key={price.id}
                                    sx={{
                                        width: 380,
                                        height: 570,
                                        background: 'linear-gradient(145deg, #ffffff 0%, #f9f9f9 100%)',
                                        boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
                                        borderRadius: 2,
                                        transition: 'transform 0.2s',
                                        '&:hover': {
                                            transform: 'translateY(-5px)'
                                        }
                                    }}
                                >
                                    <CardContent sx={{ p: 4 }}>
                                        <Typography variant="h5" gutterBottom sx={{ color: '#E04C16', fontWeight: 600 }}>
                                            {price.product.name}
                                        </Typography>
                                        <Typography variant="h3" sx={{ mt: 2, mb: 3, fontWeight: 700 }}>
                                            ${price.unit_amount / 100}
                                            <Typography component="span" variant="h6" color="text.secondary">
                                                /month
                                            </Typography>
                                        </Typography>
                                        <Typography color="text.secondary" sx={{ mb: 4 }}>
                                            {price.product.description}
                                        </Typography>
                                        <Divider sx={{ my: 3 }} />
                                        <List>
                                            {features.map((feature, index) => (
                                                <ListItem key={index} sx={{ px: 0 }}>
                                                    <ListItemIcon sx={{ minWidth: 40 }}>
                                                        {feature.icon}
                                                    </ListItemIcon>
                                                    <ListItemText 
                                                        primary={feature.text}
                                                        primaryTypographyProps={{
                                                            sx: { fontWeight: 500 }
                                                        }}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            size="large"
                                            sx={{
                                                mt: 4,
                                                backgroundColor: '#E04C16',
                                                '&:hover': {
                                                    backgroundColor: '#c43e12'
                                                },
                                                py: 1.5
                                            }}
                                            onClick={() => handleSubscribe(price.id)}
                                        >
                                            Get Started
                                        </Button>
                                    </CardContent>
                                </Card>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default SubscriptionPlans;