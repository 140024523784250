import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { config } from '../config';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  IconButton,
  Tabs,
  Tab,
  CircularProgress
} from '@mui/material';
import { Edit, Trash, Plus } from 'lucide-react';
import axios from 'axios';
import AmazonSettings from './AmazonSettings';
import SecureCredentialsField from './SecureCredentialsField';

// Custom TabPanel component
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function UserDashboard() {
  const { getAccessTokenSilently, user, isLoading: authLoading } = useAuth0();
  const [activeTab, setActiveTab] = useState(2);
  const [apiKeys, setApiKeys] = useState({
    openai: '',    
    anthropic: '', 
    fal: '',
    ideogram: '',
    openrouter: '',
    scrapeowl: ''       
  });

  const [isLoadingApiKeys, setIsLoadingApiKeys] = useState(true);
  const [isLoadingWordPress, setIsLoadingWordPress] = useState(true);
  const [isLoadingAmazon, setIsLoadingAmazon] = useState(true);
  
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const [amazonSettings, setAmazonSettings] = useState({
    api_key: '',
    secret_key: '',
    tracking_id: '',
    marketplace: 'amazon.com'
  });

  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingError, setLoadingError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSite, setSelectedSite] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [siteToDelete, setSiteToDelete] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [siteForm, setSiteForm] = useState({
    name: '',
    url: '',
    username: '',
    password: ''
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);


  const validateForm = () => {
    const errors = {};
    
    // Name validation
    if (!siteForm.name.trim()) {
      errors.name = 'Site name is required';
    }
  
    // URL validation
    if (!siteForm.url.trim()) {
      errors.url = 'Site URL is required';
    } else {
      const urlPattern = /^(?:https?:\/\/)?(?:[\w-]+\.)+[\w-]+\/?$/;
      if (!urlPattern.test(siteForm.url.trim())) {
        errors.url = 'Please enter a valid domain (e.g., your-site.com)';
      }
    }
  
    // Username validation
    if (!siteForm.username.trim()) {
      errors.username = 'Username is required';
    }
  
    // Password validation
    if (!siteForm.password) {
      errors.password = 'Password is required';
    }
  
    return errors;
  };

  useEffect(() => {
    if (!authLoading) {
      fetchWordPressSites();
    }
  }, [authLoading]);

  useEffect(() => {
    if (!authLoading) {
      fetchApiKeys();
    }
  }, [authLoading]);

  useEffect(() => {
    if (!authLoading) {
      fetchAmazonSettings();
    }
  }, [authLoading]);


  const fetchWordPressSites = async () => {
    try {
      setIsLoadingWordPress(true);
      setLoadingError(null);
      
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${config.api.baseUrl}/api/user/wordpress-sites`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      setSites(response.data);
    } catch (error) {
      console.error('WordPress sites fetch error:', error);
      setLoadingError(error.response?.data?.detail || 'Error loading WordPress sites');
      setNotification({
        open: true,
        message: 'Error loading WordPress sites: ' + (error.response?.data?.detail || error.message),
        severity: 'error'
      });
    } finally {
      setIsLoadingWordPress(false);
    }
  };

  const fetchApiKeys = async () => {
    try {
      setIsLoadingApiKeys(true);
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${config.api.baseUrl}/api/user/api-keys`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setApiKeys(response.data);
    } catch (error) {
      console.error('API keys fetch error:', error);
      setNotification({
        open: true,
        message: 'Error loading API keys: ' + (error.response?.data?.detail || error.message),
        severity: 'error'
      });
    } finally {
      setIsLoadingApiKeys(false);
    }
  };

  const fetchAmazonSettings = async () => {
    try {
      setIsLoadingAmazon(true);
      const token = await getAccessTokenSilently();
      const response = await axios.get(`${config.api.baseUrl}/api/user/amazon-credentials`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      setAmazonSettings({
        api_key: response.data.api_key === true ? '••••••••' : response.data.api_key || '',
        secret_key: response.data.secret_key === true ? '••••••••' : response.data.secret_key || '',
        tracking_id: response.data.tracking_id || ''
      });
    } catch (error) {
      console.error('Amazon settings fetch error:', error);
      setNotification({
        open: true,
        message: 'Error loading Amazon settings: ' + (error.response?.data?.detail || error.message),
        severity: 'error'
      });
    } finally {
      setIsLoadingAmazon(false);
    }
  };
  

  const handleApiKeysSave = async (e) => {
    e.preventDefault();
    try {
      const token = await getAccessTokenSilently();
      await axios.post(`${config.api.baseUrl}/api/user/api-keys`, apiKeys, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      setNotification({
        open: true,
        message: 'API keys saved successfully',
        severity: 'success'
      });
    } catch (error) {
      setNotification({
        open: true,
        message: error.response?.data?.detail || 'Failed to save API keys',
        severity: 'error'
      });
    }
  };

  const handleSaveSite = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    // Basic form validation
    const errors = {};
    if (!siteForm.name.trim()) {
      errors.name = "Site name is required";
    }
    if (!siteForm.url.trim()) {
      errors.url = "Site URL is required";
    } else {
      // Clean up URL
      let cleanUrl = siteForm.url.trim().toLowerCase();
      // Remove trailing slashes
      cleanUrl = cleanUrl.replace(/\/+$/, '');
      // Add https if no protocol specified
      if (!cleanUrl.startsWith('http')) {
        cleanUrl = 'https://' + cleanUrl;
      }
      siteForm.url = cleanUrl;
    }
    if (!siteForm.username.trim()) {
      errors.username = "Username is required";
    }
    if (!siteForm.password.trim()) {
      errors.password = "Password is required";
    }
  
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsLoading(false);
      return;
    }
  
    try {
      setNotification({
        open: true,
        message: 'Verifying WordPress connection...',
        severity: 'info'
      });
  
      const token = await getAccessTokenSilently();
      const url = selectedSite
        ? `${config.api.baseUrl}/api/user/wordpress-sites/${selectedSite.id}`
        : `${config.api.baseUrl}/api/user/wordpress-sites`;
  
      const response = await axios.request({
        url,
        method: selectedSite ? 'PUT' : 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: siteForm
      });
  
      if (response.status >= 200 && response.status < 300) {
        await fetchWordPressSites();
        handleCloseSiteDialog();
        setNotification({
          open: true,
          message: selectedSite 
            ? 'WordPress site updated successfully' 
            : 'WordPress site connected successfully',
          severity: 'success'
        });
      }
    } catch (error) {
      let errorMessage = 'Failed to connect to WordPress site';
      
      if (error.response?.data?.detail) {
        errorMessage = error.response.data.detail;
      } else if (error.response?.status === 404) {
        errorMessage = "WordPress REST API not found. Please ensure your site has REST API enabled.";
      } else if (error.response?.status === 401) {
        errorMessage = "Invalid WordPress credentials. Please check your username and password.";
      } else if (error.response?.status === 403) {
        errorMessage = "Access denied. Please ensure your user has administrator privileges.";
      } else if (error.message.includes('Network Error')) {
        errorMessage = "Could not connect to the site. Please check the URL and try again.";
      }
  
      setNotification({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenDeleteDialog = (site) => {
    setSiteToDelete(site);
    setDeleteDialogOpen(true);
  };
  
  const handleDeleteSite = async () => {
    try {
      setDeleteLoading(true); 
      const token = await getAccessTokenSilently();
      await axios.delete(`${config.api.baseUrl}/api/user/wordpress-sites/${siteToDelete.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      setDeleteDialogOpen(false);
      await fetchWordPressSites();
      setNotification({
        open: true,
        message: 'Site deleted successfully',
        severity: 'success'
      });
    } catch (error) {
      setNotification({
        open: true,
        message: 'Error deleting WordPress site',
        severity: 'error'
      });
    } finally {
      setDeleteLoading(false); // Reset the separate loading state
      setSiteToDelete(null);
    }
  };

  const handleOpenSiteDialog = (site = null) => {
    if (site) {
      setSiteForm(site);
      setSelectedSite(site);
    } else {
      setSiteForm({
        name: '',
        url: '',
        api_url: '',
        username: '',
        password: ''
      });
      setSelectedSite(null);
    }
    setOpenDialog(true);
  };

  const handleCloseSiteDialog = () => {
    setOpenDialog(false);
    setSelectedSite(null);
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const handleSaveAmazonSettings = async (settings) => {
    try {
      // Don't proceed if no settings to update
      if (Object.keys(settings).length === 0) {
        return;
      }
  
      const token = await getAccessTokenSilently();
      await axios.post(`${config.api.baseUrl}/api/user/amazon-credentials`, settings, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      // Refresh the settings
      await fetchAmazonSettings();
      
      setNotification({
        open: true,
        message: 'Amazon settings saved successfully',
        severity: 'success'
      });
    } catch (error) {
      setNotification({
        open: true,
        message: error.response?.data?.detail || 'Failed to save Amazon settings',
        severity: 'error'
      });
    }
  };

  if (authLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ width: '100%', mb: 3 }}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab label="User Details" />
          <Tab label="API Keys" />
          <Tab label="WordPress Sites" />
          <Tab label="Amazon API" /> 
        </Tabs>

        {/* User Details Tab */}
        <TabPanel value={activeTab} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" color="text.secondary">
                Name
              </Typography>
              <Typography variant="body1">
                {user?.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" color="text.secondary">
                Email
              </Typography>
              <Typography variant="body1">
                {user?.email}
              </Typography>
            </Grid>
          </Grid>
        </TabPanel>

      
        {/* API Keys Tab */}
        <TabPanel value={activeTab} index={1}>
          {isLoadingApiKeys ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleApiKeysSave}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="OpenAI API Key"
                    type="password"
                    value={apiKeys.openai}
                    onChange={(e) => setApiKeys({ ...apiKeys, openai: e.target.value })}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Anthropic API Key"
                    type="password"
                    value={apiKeys.anthropic}
                    onChange={(e) => setApiKeys({ ...apiKeys, anthropic: e.target.value })}
                    margin="normal"
                  />
                </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="OpenRouter API Key"
                      type="password"
                      value={apiKeys.openrouter}
                      onChange={(e) => setApiKeys({ ...apiKeys, openrouter: e.target.value })}
                      margin="normal"
                    />
                  </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Fal.ai API Key"
                    type="password"
                    value={apiKeys.fal}
                    onChange={(e) => setApiKeys({ ...apiKeys, fal: e.target.value })}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="ScrapeOwl API Key"
                    type="password"
                    value={apiKeys.scrapeowl}
                    onChange={(e) => setApiKeys({ ...apiKeys, scrapeowl: e.target.value })}
                    margin="normal"
                    helperText="Required for URL Rewriter functionality"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary">
                    Save API Keys
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </TabPanel>
       
        <TabPanel value={activeTab} index={3}>
        {isLoadingAmazon ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <AmazonSettings
            onSave={handleSaveAmazonSettings}
            initialData={amazonSettings}
            isLoading={false}
          />
        )}
      </TabPanel>

        {/* WordPress Sites Tab */}
        <TabPanel value={activeTab} index={2}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">
              Connected WordPress Sites
            </Typography>
            <Button
              variant="contained"
              startIcon={<Plus />}
              onClick={() => handleOpenSiteDialog()}
            >
              Add Site
            </Button>
          </Box>

          {isLoadingWordPress ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : loadingError ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {loadingError}
            </Alert>
          ) : sites.length === 0 ? (
            <Alert severity="info" sx={{ mb: 2 }}>
              No WordPress sites added yet. Click "Add Site" to get started.
            </Alert>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              {sites.map((site) => (
                <Card key={site.id}>
                  <CardContent>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <img 
                        src={`${site.url}/favicon.ico`}
                        alt=""
                        onError={(e) => {
                          e.target.src = `https://www.google.com/s2/favicons?domain=${site.url}`;
                        }}
                        style={{
                          width: '16px',
                          height: '16px',
                          objectFit: 'contain'
                        }}
                      />
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" component="div">
                          {site.name}
                        </Typography>
                        <Typography color="text.secondary">
                          {site.url}
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton onClick={() => handleOpenSiteDialog(site)}>
                          <Edit />
                        </IconButton>
                        <IconButton onClick={() => handleOpenDeleteDialog(site)}>
                         <Trash />
                        </IconButton>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              ))}
            </Box>
          )}
        </TabPanel>
      </Paper>

      {/* WordPress Site Dialog */}
     
<Dialog open={openDialog} onClose={handleCloseSiteDialog} maxWidth="sm" fullWidth>
  <form onSubmit={handleSaveSite}>
    <DialogTitle>
      {selectedSite ? 'Edit WordPress Site' : 'Add WordPress Site'}
    </DialogTitle>
    <DialogContent>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Site Name"
            value={siteForm.name}
            onChange={(e) => setSiteForm({ ...siteForm, name: e.target.value })}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Site URL"
            value={siteForm.url}
            onChange={(e) => setSiteForm({ ...siteForm, url: e.target.value })}
            required
            helperText="Example: your-site.com"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Username"
            value={siteForm.username}
            onChange={(e) => setSiteForm({ ...siteForm, username: e.target.value })}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            type="password"
            label="Password"
            value={siteForm.password}
            onChange={(e) => setSiteForm({ ...siteForm, password: e.target.value })}
            required
          />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseSiteDialog}>Cancel</Button>
      <Button type="submit" variant="contained">
        {selectedSite ? 'Save Changes' : 'Add Site'}
      </Button>
    </DialogActions>
  </form>
</Dialog>
{/* Delete Confirmation Dialog */}
<Dialog
  open={deleteDialogOpen}
  onClose={() => setDeleteDialogOpen(false)}
  maxWidth="sm"
  fullWidth
>
  <DialogTitle sx={{ pb: 1 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Trash color="#ef4444" size={20} />
      <Typography variant="h6" component="span">
        Delete WordPress Site
      </Typography>
    </Box>
  </DialogTitle>
  <DialogContent>
    <Alert severity="warning" sx={{ mt: 2, mb: 2 }}>
      This action cannot be undone. The site will be disconnected from Scripti.sh.
    </Alert>
    <Typography>
      Are you sure you want to delete the connection to{' '}
      <strong>{siteToDelete?.name}</strong>?
    </Typography>
  </DialogContent>
  <DialogActions>
    <Button
      onClick={() => setDeleteDialogOpen(false)}
      disabled={deleteLoading}
    >
      Cancel
    </Button>
    <Button
      variant="contained"
      color="error"
      onClick={handleDeleteSite}
      disabled={deleteLoading}
      startIcon={deleteLoading ? <CircularProgress size={20} /> : <Trash size={20} />}
    >
      {deleteLoading ? 'Deleting...' : 'Delete Site'}
    </Button>
  </DialogActions>
</Dialog>
      {/* Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: '100%' }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
  
  export default UserDashboard;