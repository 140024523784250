import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const ArticleRewritesPage = () => (
  <Box sx={{ p: 3 }}>
    <Paper sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Article Rewrites
      </Typography>
      <Typography color="text.secondary">
        Rewrite and repurpose existing articles. Coming soon.
      </Typography>
    </Paper>
  </Box>
);

export default ArticleRewritesPage;