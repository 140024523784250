import React from 'react';
import { Box, Toolbar, Typography, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { 
  TestTubeDiagonal, 
  FileText, 
  Star, 
  ListOrdered, 
  FileEdit,
  ImageIcon, 
  Settings,
  CreditCard,
  Sparkles,
  PenLine, 
  Link2
} from 'lucide-react';
import GlobalModelSelector from './GlobalModelSelector';

const DrawerContent = ({ isGenerating = false }) => {
  const location = useLocation();
  
  const isCurrentPath = (path) => location.pathname === path;

  const MenuLink = ({ to, icon, text, indent = false }) => (
    <Tooltip 
      title={isGenerating ? "Please wait until article generation is complete" : ""}
      placement="right"
    >
      <ListItem
        component={Link}
        to={to}
        disabled={isGenerating}
        onClick={(e) => {
          if (isGenerating) {
            e.preventDefault();
          }
        }}
        sx={{
          pl: indent ? 4 : 3,
          py: 0.5,
          color: isGenerating ? 'text.disabled' : 'inherit',
          textDecoration: 'none',
          borderRadius: '0 24px 24px 0',
          mr: 2,
          backgroundColor: isCurrentPath(to) ? 'rgba(224, 76, 22, 0.08)' : 'transparent',
          '&:hover': {
            backgroundColor: isGenerating ? 'transparent' : (isCurrentPath(to) ? 'rgba(224, 76, 22, 0.12)' : 'rgba(0, 0, 0, 0.04)'),
          },
          pointerEvents: isGenerating ? 'none' : 'auto',
          opacity: isGenerating ? 0.5 : 1,
          transition: 'opacity 0.2s, color 0.2s',
        }}
      >
        <ListItemIcon sx={{ minWidth: 40 }}>
          {React.cloneElement(icon, { 
            size: 18,
            color: isGenerating ? '#999' : (isCurrentPath(to) ? '#E04C16' : '#666666')
          })}
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            variant: 'body1',
            sx: {
              fontSize: '1rem !important',
              fontWeight: `${isCurrentPath(to) ? 700 : 600} !important`,  
              color: isGenerating ? 'text.disabled' : (isCurrentPath(to) ? '#E04C16' : 'inherit'),
              letterSpacing: '0.01em'
            }
          }}
        />
      </ListItem>
    </Tooltip>
  );

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Toolbar sx={{ px: 2 }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          pl: -0.5
        }}>
          <Sparkles size={24} color="#E04C16" />
          <Box sx={{ position: 'relative' }}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: 'Inter',
                fontSize: '1.75rem',
                fontWeight: 800,
                color: '#000000',
                letterSpacing: '-0.02em'
              }}
            >
              Scripti
            </Typography>
            <Box
              sx={{
                position: 'absolute',
                top: '55%',
                right: -45,
                backgroundColor: '#E04C16',
                color: 'white',
                fontSize: '0.6rem',
                padding: '2px 6px',
                borderRadius: '10px',
                fontWeight: 600,
                letterSpacing: '0.02em',
                transform: 'translateY(-50%)',
                fontFamily: 'Inter'
              }}
            >
              BETA
            </Box>
          </Box>
        </Box>
      </Toolbar>

      <List sx={{ px: 1, py: 2 }}>
        {/* PromptLab Section */}
        <Typography
          variant="overline"
          sx={{
            pl: 3,
            mb: 1,
            display: 'block',
            color: isGenerating ? 'text.disabled' : '#666666',
            fontSize: '1rem',
            fontWeight: 400
          }}
        >
          PROMPTLAB
        </Typography>
        <MenuLink to="/prompts/new" icon={<PenLine />} text="New Prompt" />
        <MenuLink to="/prompts/saved" icon={<TestTubeDiagonal />} text="Saved Prompts" />

        {/* Articles Section */}
        <Typography
          variant="overline"
          sx={{
            pl: 3,
            mb: 1,
            display: 'block',
            color: isGenerating ? 'text.disabled' : '#666666',
            fontSize: '1rem',
            fontWeight: 400,
            letterSpacing: '-0.02em'
          }}
        >
          ARTICLES
        </Typography>
        <MenuLink to="/articles/single" icon={<FileText />} text="Single Article Creator" />
        <MenuLink to="/articles/standard" icon={<FileText />} text="Bulk Article Creator" />
        <MenuLink to="/articles/reviews" icon={<Star />} text="Product Reviews" />
        <MenuLink to="/articles/lists" icon={<ListOrdered />} text="Listicle Articles" />
        <MenuLink to="/url-rewriter" icon={<Link2 />} text="URL Rewriter" /> 
        <MenuLink to="/articles/rewrites" icon={<FileEdit />} text="Article Rewrites" />

        {/* Tools Section */}
        <Typography
          variant="overline"
          sx={{
            pl: 3,
            mt: 3,
            mb: 1,
            display: 'block',
            color: isGenerating ? 'text.disabled' : '#666666',
            fontSize: '1rem',
            fontWeight: 400
          }}
        >
          TOOLS
        </Typography>
        <MenuLink to="/images" icon={<ImageIcon />} text="Image Generator" />
      </List>

      <Box sx={{ 
        mt: 'auto',
        borderTop: '1px solid',
        borderColor: 'divider',
        pt: 2,
        px: 1,
        opacity: isGenerating ? 0.5 : 1,
        pointerEvents: isGenerating ? 'none' : 'auto'
      }}>
         <GlobalModelSelector />
        <MenuLink to="/subscription" icon={<CreditCard />} text="Subscription" />
        <MenuLink to="/dashboard" icon={<Settings />} text="Settings" />
      </Box>
    </Box>
  );
};

export default DrawerContent;