import React, { useState, useEffect } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Tooltip
} from '@mui/material';
import { Eye, EyeOff } from 'lucide-react';

const SecureCredentialsField = ({
  label,
  value,
  onChange,
  disabled = false,
  placeholder = '',
  error = false,
  helperText = ''
}) => {
  const [showValue, setShowValue] = useState(false);
  const [editableValue, setEditableValue] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    // Only update if value is a string and not the masked value
    if (typeof value === 'string' && value !== '••••••••') {
      setEditableValue(value);
    }
  }, [value]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setEditableValue(newValue);
    setIsEditing(true);
    // Ensure we're passing a string value
    if (onChange) {
      onChange({
        target: {
          value: newValue,
          name: label.toLowerCase().replace(/\s+/g, '_')
        }
      });
    }
  };

  const toggleVisibility = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowValue(!showValue);
    
    if (!showValue && !isEditing && typeof value === 'string') {
      setEditableValue(value);
    }
  };

  // Determine what value to display
  const displayValue = () => {
    if (isEditing) {
      return editableValue;
    }
    if (showValue && typeof value === 'string') {
      return value;
    }
    return value ? '••••••••' : '';
  };

  return (
    <TextField
      fullWidth
      label={label}
      type={showValue ? 'text' : 'password'}
      value={displayValue()}
      onChange={handleChange}
      disabled={disabled}
      placeholder={placeholder}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title={showValue ? "Hide value" : "Show value"}>
              <IconButton
                onClick={toggleVisibility}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
                size="small"
                className="hover:bg-gray-100"
                disabled={disabled}
              >
                {showValue ? 
                  <EyeOff className="w-4 h-4" /> : 
                  <Eye className="w-4 h-4" />
                }
              </IconButton>
            </Tooltip>
          </InputAdornment>
        )
      }}
    />
  );
};

export default SecureCredentialsField;