import React, { useState } from 'react';
import {
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  LogOut,
  Star,
} from 'lucide-react';
import md5 from 'crypto-js/md5';

const UserMenu = ({ user, onLogout }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getInitials = (name) => {
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase();
  };

  const getAvatarSrc = (email) => {
    const hash = md5(email.trim().toLowerCase());
    return `https://www.gravatar.com/avatar/${hash}?d=mp&s=200`;
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className="ml-2"
      >
        <Avatar
          sx={{ 
            width: 32, 
            height: 32,
            bgcolor: 'primary.main',
            fontSize: '0.875rem'
          }}
          src={getAvatarSrc(user.email)}
          alt={user.name}
        >
          {getInitials(user.name)}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="user-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        PaperProps={{
          sx: {
            mt: 1,
            width: 220, 
          }
        }}
      >
        <MenuItem disabled>
          <ListItemText 
            primary={user.name}
            secondary={user.email}
            primaryTypographyProps={{ 
              variant: 'subtitle1', 
              noWrap: true,
              sx: { fontSize: '1rem' } 
            }}
            secondaryTypographyProps={{
              variant: 'body2', 
              noWrap: true,
              sx: { fontSize: '0.875rem' } 
            }}
          />
        </MenuItem>
        <Divider />
      
        <MenuItem onClick={onLogout}>
          <ListItemIcon>
            <LogOut size={20} /> 
          </ListItemIcon>
          <ListItemText 
            primary="Logout"
            primaryTypographyProps={{
              sx: { fontSize: '1.5rem' } 
            }}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserMenu;