const getApiBaseUrl = () => {
  // In production, always use the Render URL
  if (process.env.NODE_ENV === 'production') {
    return 'https://treevowriter-api.onrender.com';
  }
  // For local development
  return 'http://localhost:8000';
};

const getClientBaseUrl = () => {
  if (process.env.NODE_ENV === 'production') {
    return 'https://scripti.sh';
  }
  return 'http://localhost:3000';
};


export const config = {
  auth0: {
    domain: process.env.AUTH0_DOMAIN || "dev-37mvwswd3e73pa07.eu.auth0.com",
    clientId: process.env.AUTH0_CLIENT_ID || "i4jaANoox38PiYlyOTC5e2XY8NlctjnC",
    audience: process.env.AUTH0_AUDIENCE || "https://TrevoWriter",
    authorizationParams: {
      redirect_uri: window.location.origin + '/callback'
    },
    onRedirectCallback: (appState) => {
      window.location.replace(appState?.returnTo || window.location.origin);
    },
    onError: (error) => {
      if (error.error === 'unauthorized' || error.error === 'access_denied') {
        window.location.replace('/unauthorized');
      }
    }
  },
  clientUrl: getClientBaseUrl(),
  api: {
    baseUrl: getApiBaseUrl()
  }
};

console.log('Loading config:', config);  


// Add logging for debugging
if (process.env.NODE_ENV === 'development') {
  console.log('API URL:', config.api.baseUrl);
}

export default config;