import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import apiClient from '../utils/apiUtils';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const { 
    getAccessTokenSilently, 
    isAuthenticated: auth0IsAuthenticated,
    user,
    isLoading: auth0Loading
  } = useAuth0();
  
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const cleanup = useCallback(() => {
    localStorage.removeItem('auth_token');
    setToken(null);
    apiClient.setAuthToken(null);
  }, []);

  const getToken = useCallback(async () => {
    try {
      const newToken = await getAccessTokenSilently();
      setToken(newToken);
      apiClient.setAuthToken(newToken);
      return newToken;
    } catch (err) {
      console.error('Error fetching token:', err);
      setError('Failed to get authentication token');
      cleanup();
      throw err;
    }
  }, [getAccessTokenSilently, cleanup]);

  useEffect(() => {
    const initAuth = async () => {
      if (!auth0Loading && auth0IsAuthenticated) {
        try {
          await getToken();
        } catch (err) {
          console.error('Error initializing auth:', err);
        }
      } else if (!auth0Loading) {
        cleanup();
      }
      setLoading(false);
    };

    initAuth();
  }, [auth0IsAuthenticated, auth0Loading, getToken, cleanup]);

  const authenticatedRequest = useCallback(async (requestFn) => {
    if (!auth0IsAuthenticated) {
      throw new Error('User not authenticated');
    }

    try {
      const currentToken = token || await getToken();
      
      try {
        return await requestFn(currentToken);
      } catch (err) {
        if (err.message?.includes('401') || err.message?.includes('expired')) {
          const newToken = await getToken();
          return await requestFn(newToken);
        }
        throw err;
      }
    } catch (err) {
      console.error('Authentication error:', err);
      throw new Error(err.message || 'Authentication failed');
    }
  }, [auth0IsAuthenticated, getToken, token]);

  return (
    <AuthContext.Provider value={{
      token,
      loading: loading || auth0Loading,
      error,
      user,
      isAuthenticated: auth0IsAuthenticated,
      authenticatedRequest,
      clearError: () => setError(null),
      cleanup
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};