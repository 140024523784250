import React from 'react';
import { Box, Typography } from '@mui/material';

const PageHeader = ({ title, icon, description }) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 2,
        mb: description ? 2 : 0
      }}>
        {icon && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center'
          }}>
            {icon}
          </Box>
        )}
        <Typography 
          variant="h5" 
          sx={{ 
            fontWeight: 800,
            fontFamily: 'Inter',
            color: '#0f172a'
          }}
        >
          {title}
        </Typography>
      </Box>
      {description && (
        <Typography 
          variant="body1" 
          sx={{ 
            mt: 1,
            color: 'text.secondary',
            maxWidth: '800px',
            lineHeight: 1.5
          }}
        >
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default PageHeader;