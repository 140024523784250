import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Alert,
  CircularProgress,
  Paper,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Collapse,
  FormControlLabel,
  Checkbox,
  Switch
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import {
  FileText,
  HelpCircle,
  X,
  GripVertical,
  Plus,
  Trash2,
  Wand2,
  ChevronDown,
  ChevronRight,
  Image,
  CheckCircle,
  AlertCircle,
  Link
} from 'lucide-react';
import PageHeader from './PageHeader';
import ImageControls from './ImageControls';
import { useAuth } from '../context/AuthContext';
import apiClient from '../utils/apiUtils';
import WordPressClient from '../utils/WordPressClient';
import aiClient from '../api/clients/AiClient';
import imageClient from '../api/clients/ImageClient';
import { formatContent } from '../utils/contentFormatting';
import { useAIModels } from '../hooks/useAIModels';
import ModelSelector from './ModelSelector';

// Define the default listicle prompt template
const DEFAULT_LISTICLE_PROMPT = `Create a comprehensive listicle article that explores each item in detail. For each list item:

1. Provide a clear explanation of what it is
2. Explain why it's important or relevant
3. Include specific examples or real-world applications
4. Add practical tips or recommendations when applicable

Writing Style:
- Use a conversational yet informative tone
- Break down complex concepts into simple terms
- Include relevant facts and data to support points
- Make the content engaging and actionable
- Maintain consistent length for each list item

Structure Requirements:
- Start with a compelling introduction that sets up the topic
- Each list item should have a clear, descriptive heading
- Include a brief conclusion that summarizes key takeaways
- Format text with appropriate subheadings and spacing`;

const ListicleGenerator = () => {
  const { authenticatedRequest, error: authError, loading: authLoading } = useAuth();
  const { models: availableModels, loading: modelsLoading, error: modelsError } = useAIModels(authenticatedRequest);

  // AI settings
  const [model, setModel] = useState('');

  useEffect(() => {
    const initializeAI = async () => {
      if (availableModels.length > 0) {
        try {
          const keys = await authenticatedRequest(() => apiClient.getApiKeys());
          aiClient.setApiKeys(keys);
          
          // Update AI client with model configurations
          aiClient.updateModelConfigs(availableModels);
          
          // Set initial model if none selected
          if (!model && availableModels.length > 0) {
            const defaultModel = availableModels[0].value;
            console.log('Setting default model:', defaultModel);
            setModel(defaultModel);
          }
    
        } catch (error) {
          console.error('Error initializing AI client:', error);
          setError('Failed to initialize AI services');
        }
      }
    };
  
    initializeAI();
  }, [availableModels, authenticatedRequest, model]);

  // Basic State
  const [title, setTitle] = useState('');
  const [listItems, setListItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [expandedItem, setExpandedItem] = useState(null);
  const [isGeneratingItems, setIsGeneratingItems] = useState(false);

  // WordPress Settings
  const [sites, setSites] = useState([]);
  const [selectedSite, setSelectedSite] = useState('');
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');

  // AI Settings
 
  const [optimizeForSearch, setOptimizeForSearch] = useState(false);

  // Image Settings
  const [includeImages, setIncludeImages] = useState(false);
  const [featuredImage, setFeaturedImage] = useState({
    enabled: false,
    imageModel: 'fal-ai/flux/schnell',
    imageStyle: 'realistic_image',
    imageDimension: 'square'
  });
  
  const [itemImages, setItemImages] = useState(false);
  const [itemImageModel, setItemImageModel] = useState('fal-ai/flux/schnell');
  const [dimension, setDimension] = useState('square');
  const [itemImageStyle, setItemImageStyle] = useState('realistic_image');
  const [featuredImagePrompt, setFeaturedImagePrompt] = useState('');
  const [useAIEnhancement, setUseAIEnhancement] = useState(false);
  const [clearDialogOpen, setClearDialogOpen] = useState(false);


  // Generation Status
  const [generationSteps, setGenerationSteps] = useState([
    { id: 'init', text: 'Initializing content generation...', status: 'pending', started: false },
    { id: 'content', text: 'Generating article content...', status: 'pending', started: false },
    { id: 'images', text: 'Processing images...', status: 'pending', started: false },
    { id: 'upload', text: 'Publishing to WordPress...', status: 'pending', started: false }
  ]);
  const [generationStatus, setGenerationStatus] = useState('');

  // Response Dialog State
  const [responseDialogOpen, setResponseDialogOpen] = useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [generatedArticle, setGeneratedArticle] = useState(null);

  useEffect(() => {
    if (!authLoading) {
      fetchInitialData();
      initializeServices();
    }
  }, [authLoading]);

  useEffect(() => {
    if (selectedSite) {
      fetchCategories();
    }
  }, [selectedSite]);

  const initializeServices = async () => {
    try {
      const keys = await authenticatedRequest(() => apiClient.getApiKeys());
      
      if (!keys.openai && !keys.anthropic && !keys.fal) {
        throw new Error("No API keys configured. Please add required API keys in settings.");
      }

      if (keys.fal) {
        imageClient.setApiKeys(keys);
        console.log('Image generation services initialized');
      } else {
        console.warn('Fal.ai API key not configured - image generation will be disabled');
        setFeaturedImage(false);
      }
    } catch (error) {
      console.error("Failed to initialize services:", error);
      setError('Failed to initialize services. Please check your API keys in settings.');
    }
  };

  const fetchInitialData = async () => {
    try {
      const sitesData = await authenticatedRequest(() => apiClient.getWordPressSites());
      setSites(sitesData);
      if (sitesData.length > 0) {
        setSelectedSite(sitesData[0].id);
      }
    } catch (err) {
      setError('Failed to load initial data');
    }
  };

  const fetchCategories = async () => {
    if (!selectedSite) return;
    try {
      const categoriesData = await authenticatedRequest(() => 
        apiClient.getCategories(selectedSite)
      );
      setCategories(categoriesData);
    } catch (err) {
      setError('Failed to load categories');
    }
  };

  const handleUpdateFeaturedImage = (field, value) => {
    setFeaturedImage(prev => ({
      ...prev,
      [field]: value
    }));
  };
 

    const getAuxiliaryModel = () => {
    const stored = localStorage.getItem('globalAiModel');
    if (stored && availableModels.some(m => m.value === stored)) {
      return stored;
    }
    return availableModels[0]?.value;
  };

  const handleGenerateItems = async () => {
    if (!title.trim()) {
      setError('Please enter an article title first');
      return;
    }
  
    setIsGeneratingItems(true);
    setError(null);
  
    try {
      if (!availableModels.length) {
        throw new Error('No AI models available');
      }
  
      const auxModel = getAuxiliaryModel();
      if (!auxModel) {
        throw new Error('No auxiliary model available');
      }
  
      // Extract number from title or default to 7
      const titleNumber = title.match(/\d+/);
      const numberOfItems = titleNumber ? parseInt(titleNumber[0]) : 7;
  
      const prompt = `Generate a list of exactly ${numberOfItems} items for an article titled "${title}".
  
  REQUIREMENTS:
  1. Generate EXACTLY ${numberOfItems} items - no more, no less
  2. Each item must be directly related to the title topic
  3. Items should follow a logical progression
  4. Each item should be 3-6 words long
  5. Present items as plain text, one per line
  6. Do not include numbers, bullets, or any other prefixes
  7. Each item must be a complete, standalone topic
  8. Avoid phrases like "Introduction to" or "Overview of"
  
  FORMAT RULES:
  - Return EXACTLY ${numberOfItems} items
  - Return ONLY the list items, nothing else
  - One item per line
  - No blank lines between items
  - No numbering or bullet points
  - No explanatory text
  
  EXAMPLE INPUT:
  "5 Essential Camping Gear Items for Beginners"
  
  EXAMPLE OUTPUT:
  Lightweight All-Season Camping Tent
  Insulated Sleeping Bag and Pad
  Portable Water Filtration System
  Weather-Resistant Camping Backpack
  Multi-Tool and Basic Repair Kit
  
  ACTUAL OUTPUT MUST FOLLOW THIS EXACT FORMAT WITH EXACTLY ${numberOfItems} ITEMS.
  
  NOW GENERATE LIST ITEMS FOR: "${title}"`;
  
      console.log('Using model for item generation:', auxModel);
      console.log('Requesting', numberOfItems, 'items');
      
      const response = await aiClient.generateArticleContent({
        title,
        modelId: getModelId(auxModel),
        customPrompt: prompt
      });
  
      const newItems = response
        .split('\n')
        .filter(line => line.trim())
        .map((text, index) => ({
          id: `item-${Date.now()}-${index}`,
          title: text.replace(/^[-•*\d.]+\s*/, '').trim(), // Remove any prefixes
          notes: '',
          wordCount: 200,
          generateImage: false,
          imagePrompt: '',
          useAIEnhancement: false 
        }));
  
      if (newItems.length !== numberOfItems) {
        throw new Error(`Expected ${numberOfItems} items but received ${newItems.length}. Please try again.`);
      }
  
      setListItems(currentItems => [...currentItems, ...newItems]);
    } catch (err) {
      console.error('List item generation error:', err);
      setError('Failed to generate list items: ' + err.message);
    } finally {
      setIsGeneratingItems(false);
    }
  };

  const handleAddItem = () => {
    const newItem = {
      id: `item-${Date.now()}`,
      title: '',
      notes: '',
      wordCount: 200,
      generateImage: false,
      imagePrompt: '',
      imageModel: itemImageModel,
      imageStyle: itemImageStyle,
      imageDimension: dimension,
      useAIEnhancement: false  
    };
    setListItems([...listItems, newItem]);
    setExpandedItem(newItem.id);
  };

  const handleDeleteItem = (id) => {
    setListItems(listItems.filter(item => item.id !== id));
  };

  const handleUpdateItem = (id, field, value) => {
    setListItems(listItems.map(item => 
      item.id === id ? { ...item, [field]: value } : item
    ));
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(listItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setListItems(items);
  };

  const getModelId = (selectedModel) => {
    // Map the UI model selection to the actual model ID
    switch (selectedModel) {
      case 'claude':
        return 'claude-3-sonnet-20240222';
      case 'gpt-4':
        return 'gpt-4-turbo-preview';
      default:
        return selectedModel; // For OpenRouter models, use the value directly
    }
  };

  const formatListicleContent = (rawContent, title) => {
    // Parse into DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(rawContent, 'text/html');
  
    // Remove title/h1 elements at the start of content
    const headings = doc.querySelectorAll('h1');
    headings.forEach(h1 => {
      // Remove if it matches the article title or appears at the start
      if (h1.textContent.toLowerCase().includes(title.toLowerCase()) || 
          !h1.previousElementSibling) {
        h1.remove();
      }
    });
  
    // Convert all main headings to h2 and number them
    const mainHeadings = doc.querySelectorAll('h1, h2, h3');
    mainHeadings.forEach((heading, index) => {
      // Create new h2 element
      const h2 = doc.createElement('h2');
      
      // Remove any existing numbers or prefixes
      let headingText = heading.textContent
        .replace(/^[\d\.\s]+/, '') // Remove leading numbers and dots
        .replace(/^(step|part|section)\s*[\d\.\s]+/i, '') // Remove "Step 1" etc.
        .trim();
      
      // Add the properly formatted number prefix
      h2.textContent = `${index + 1}. ${headingText}`;
      
      // Replace original heading with new h2
      heading.parentNode.replaceChild(h2, heading);
    });
  
    // Fix paragraph spacing and formatting
    const paragraphs = doc.querySelectorAll('p');
    paragraphs.forEach(p => {
      p.style.marginBottom = '1em';
      p.style.lineHeight = '1.7';
    });
  
    // Remove any empty paragraphs
    doc.querySelectorAll('p:empty').forEach(p => p.remove());
  
    // Fix spacing around headings
    doc.querySelectorAll('h2').forEach(h2 => {
      h2.style.marginTop = '2em';
      h2.style.marginBottom = '1em';
      h2.style.fontWeight = '600';
    });
  
    return doc.body.innerHTML;
  };

  
const DEFAULT_LISTICLE_PROMPT = `Create a comprehensive listicle article following this EXACT format and style:

INTRODUCTION (3 paragraphs):
1. Begin with a broad statement about the topic, containing 2-3 **keywords in bold**
2. Provide a specific example or illustration
3. End with significance or impact statement

LIST ITEMS:
For each numbered item, follow this exact structure:

**[NUMBER]. [ITEM NAME]**
(Start each item exactly like this, e.g. "**1. Eastern Gray Squirrel**")

First Paragraph:
- Start with a definition or overview
- Must contain 2-3 **important terms in bold**
- Focus on key characteristics

Second Paragraph:
- Describe behaviors, adaptations, or features
- Include specific details and examples
- Use 2-3 **bold keywords** for emphasis

Final Paragraph:
- Connect to broader themes
- Include practical significance
- End with memorable insight

WRITING REQUIREMENTS:
- Number all items consistently (1., 2., 3., etc.)
- Use double asterisks for bold text (**like this**)
-  Maintain a friendly, conversational tone throughout the piece.
- Include specific details, facts, and figures where relevant.
- Avoid slang and overly casual language, but keep the writing accessible and engaging.
- Present information objectively, minimizing editorializing.
- Incorporate a balanced mix of technical details and easy-to-understand explanations.
- Use short paragraphs and varied sentence structures to maintain reader interest.


FORMATTING:
- Add one blank line after each section
- Format numbers exactly as shown
- Include exact number of paragraphs specified
- Maintain consistent spacing throughout`;



const handleSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  setError(null);

  setGenerationSteps([
    { id: 'init', text: 'Initializing content generation...', status: 'pending' },
    { id: 'content', text: 'Generating article content...', status: 'pending' },
    { id: 'images', text: 'Processing images...', status: 'pending' },
    { id: 'upload', text: 'Publishing to WordPress...', status: 'pending' }
  ]);

  const updateStepStatus = (stepId, status, error = null) => {
    setGenerationSteps(prev => prev.map(step => {
      if (step.id === stepId) {
        return { ...step, status, error, started: true };
      } else if (!step.started && status === 'completed') {
        // Start the next step when current one completes
        const currentIndex = prev.findIndex(s => s.id === stepId);
        const thisIndex = prev.findIndex(s => s.id === step.id);
        if (thisIndex === currentIndex + 1) {
          return { ...step, started: true };
        }
      }
      return step;
    }));
  };

  try {
    setGenerationStatus('Starting listicle generation...');
    updateStepStatus('init', 'pending');
    
    // Get WordPress site data first
    const site = sites.find(s => s.id === selectedSite);
    if (!site) throw new Error('Selected site not found');

    const wpClient = new WordPressClient(site);
    const credentials = await authenticatedRequest(() => 
      apiClient.getWordPressCredentials(selectedSite)
    );
    wpClient.setCredentials(credentials.username, credentials.password);

    // Structure the prompt
    const structuredPrompt = `${DEFAULT_LISTICLE_PROMPT}

Article Title: ${title}

List Items:
${listItems.map((item, index) => {
  let section = `${index + 1}. ${item.title}`;
  if (item.notes) section += `\nNotes: ${item.notes}`;
  section += `\nTarget word count: ${item.wordCount} words`;
  return section;
}).join('\n\n')}

${optimizeForSearch ? `
Additional Search Optimization Requirements:
- Identify and naturally incorporate semantic variations of the main topic
- Structure content to address common user questions and search intents
- Include relevant LSI keywords naturally
- Ensure comprehensive coverage of related subtopics
- Structure headings and subheadings to target featured snippets
- Include clear definitions and explanations of key concepts
- Use natural language that matches search queries
` : ''}`;

    updateStepStatus('init', 'completed');
    updateStepStatus('content', 'pending');

    // Generate content
    setGenerationStatus(`Generating article content for "${title}"`);
    const rawContent = await aiClient.generateArticleContent({
      title,
      modelId: getModelId(model),
      customPrompt: structuredPrompt
    });

    let processedContent = formatContent(rawContent, title, { isListicle: true });

    

    updateStepStatus('content', 'completed');
    updateStepStatus('images', 'pending');
    let featuredMediaId = null;

    // Handle featured image
    if (featuredImage.enabled) {
      setGenerationStatus(`Generating featured image for "${title}"`);
      try {
        const imageData = await imageClient.generateImage({
          prompt: featuredImagePrompt || title,
          model: featuredImage.imageModel,
          dimension: featuredImage.imageDimension,
          style: featuredImage.imageStyle,
          enhancePrompt: useAIEnhancement
        });

        if (imageData?.image_url) {
          setGenerationStatus('Uploading featured image...');
          featuredMediaId = await wpClient.uploadFeaturedImage(
            imageData.image_url,
            title
          );
        }
      } catch (error) {
        console.error('Featured image error:', error);
        setGenerationStatus('Featured image generation failed, continuing...');
      }
    }

    // Process list item images
   
    // In ListicleGenerator.js, replace the list item image processing section with:

if (listItems.some(item => item.generateImage)) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(processedContent, 'text/html');

  for (const item of listItems) {
    if (!item.generateImage) continue;

    try {
      console.log(`Generating image for list item: "${item.title}"`);
      const imageData = await imageClient.generateImage({
        prompt: item.imagePrompt || item.title,
        model: item.imageModel || itemImageModel,
        dimension: item.imageDimension || dimension,
        style: item.imageStyle || itemImageStyle,
        enhancePrompt: useAIEnhancement
      });

      if (imageData?.image_url) {
        setGenerationStatus(`Uploading image for "${item.title}"`);
        
        // Upload to WordPress
        const uploadResult = await wpClient.uploadMedia(
          await (await fetch(imageData.image_url)).blob(),
          `list-item-${item.title.replace(/[^a-zA-Z0-9]/g, '-')}.png`
        );

        if (uploadResult) {
          // Find matching heading (case-insensitive)
          const headings = Array.from(doc.querySelectorAll('h2'));
          const matchingHeading = headings.find(h => {
            const headingText = h.textContent.toLowerCase().replace(/^[0-9]+\.\s*/, '');
            const itemText = item.title.toLowerCase();
            return headingText.includes(itemText) || itemText.includes(headingText);
          });

          if (matchingHeading) {
            const img = doc.createElement('img');
            img.src = uploadResult.url; // Use URL from upload response
            img.alt = item.title;
            img.className = `wp-image-${uploadResult.id}`;
            img.style.cssText = 'max-width: 100%; height: auto; margin: 20px 0;';
            matchingHeading.parentNode.insertBefore(img, matchingHeading.nextSibling);
            console.log(`Successfully inserted image for: ${item.title}`);
          } else {
            console.warn(`No matching heading found for: ${item.title}`);
          }
        }
      }
    } catch (error) {
      console.error(`Failed to process image for list item "${item.title}":`, error);
      setGenerationStatus(`Image generation failed for "${item.title}", continuing...`);
    }
  }

  processedContent = doc.body.innerHTML;
}

    updateStepStatus('images', 'completed');
    updateStepStatus('upload', 'pending');

    // Create WordPress post
    setGenerationStatus('Creating WordPress post...');
    
    const post = await wpClient.createPost({
      title,
      content: processedContent,
      categoryId: category,
      featuredMediaId,
      status: 'draft'
    });

    updateStepStatus('upload', 'completed');
    setGenerationStatus('✓ Article published successfully!');

    setGeneratedArticle({
      title,
      content: processedContent,
      featuredImage: featuredMediaId ? await wpClient.getMediaUrl(featuredMediaId) : null,
      link: post.link,
      postId: post.id
    });
    
    setResponseDialogOpen(true);
    setError(null);

  } catch (err) {
    console.error('Error generating listicle:', err);
    const failedStep = generationSteps.find(step => step.status === 'pending');
    if (failedStep) {
      updateStepStatus(failedStep.id, 'error', err.message);
    }
    
    setError(err.message || 'Failed to generate listicle');
    setGenerationStatus('Generation failed. Please check the error message and try again.');
    setResponseDialogOpen(true);
  } finally {
    setLoading(false);
  }
};

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4 
      }}>
        <PageHeader 
          title="Create Listicle Article"
          description="Create engaging list-based articles with AI-powered content generation. Perfect for top 10 lists, roundups, and ranked content."
          icon={<FileText size={24} color="#E04C16" />} 
        />
        <Button
          onClick={() => setHelpDialogOpen(true)}
          startIcon={<HelpCircle size={18} />}
          sx={{
            bgcolor: '#E04C16',
            color: 'white',
            borderRadius: '20px',
            px: 3,
            '&:hover': {
              bgcolor: '#c43d0f'
            }
          }}
        >
          How to Use
        </Button>
      </Box>

      <Grid container spacing={3}>
        {/* Form Section */}
        <Grid item xs={12} md={7}>
          <Paper sx={{ p: 3 }}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* WordPress Settings */}
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>WordPress Site</InputLabel>
                    <Select
                      value={selectedSite}
                      label="WordPress Site"
                      onChange={(e) => setSelectedSite(e.target.value)}
                      disabled={loading}
                    >
                      {sites.map((site) => (
                        <MenuItem key={site.id} value={site.id}>
                          {site.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel>Category</InputLabel>
                    <Select
                      value={category}
                      label="Category"
                      onChange={(e) => setCategory(e.target.value)}
                      disabled={loading || !selectedSite}
                    >
                      {categories.map((cat) => (
                        <MenuItem key={cat.id} value={cat.id}>
                          {cat.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* AI Model Selection */}
                <Grid item xs={12}>
                  <ModelSelector
                    value={model}
                    onChange={(e) => setModel(e.target.value)}
                    required
                    tooltip="Choose the AI model that will generate your listicle content"
                    label="AI MODEL"
                    disabled={loading || modelsLoading}
                    showDescription={true}
                  />
                </Grid>

                {/* Title Input */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Article Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                    disabled={loading}
                    placeholder="e.g., Top 10 Ways to Improve Your Productivity"
                  />
                </Grid>

                {/* Search Optimization Toggle */}
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={optimizeForSearch}
                        onChange={(e) => setOptimizeForSearch(e.target.checked)}
                        disabled={loading}
                      />
                    }
                    label="Optimize for Search Intent"
                  />
                </Grid>
                <Grid item xs={12}>
  {(featuredImage || listItems.some(item => item.generateImage)) && (
    <Box sx={{ bgcolor: 'background.default', borderRadius: 1 }}>
     
      <FormControlLabel
        control={
          <Switch
            checked={useAIEnhancement}
            onChange={(e) => setUseAIEnhancement(e.target.checked)}
            disabled={loading}
          />
        }
        label="Enhance Image Prompts with AI"
      />
      <FormHelperText>
        AI will automatically enhance image prompts for better results
      </FormHelperText>
    </Box>
  )}
</Grid>
               {/* Featured Image Settings */}
<Grid item xs={12}>
  <FormControlLabel
    control={
      <Checkbox
        checked={featuredImage.enabled}
        onChange={(e) => setFeaturedImage(prev => ({
          ...prev,
          enabled: e.target.checked
        }))}
        disabled={loading}
      />
    }
    label="Generate Featured Image"
  />
  {featuredImage.enabled && (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="Featured Image Prompt (optional)"
            placeholder="Leave blank to let AI generate based on article title and content"
            value={featuredImagePrompt}
            onChange={(e) => setFeaturedImagePrompt(e.target.value)}
          />
        </Grid>
       
        <Grid item xs={12}>
          <ImageControls
            model={featuredImage.imageModel}
            setModel={(value) => handleUpdateFeaturedImage('imageModel', value)}
            style={featuredImage.imageStyle}
            setStyle={(value) => handleUpdateFeaturedImage('imageStyle', value)}
            dimension={featuredImage.imageDimension}
            setDimension={(value) => handleUpdateFeaturedImage('imageDimension', value)}
            prompt={featuredImagePrompt}
            setPrompt={setFeaturedImagePrompt}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Box>
  )}
</Grid>

                {/* List Items Section */}
                <Grid item xs={12}>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
  <Button
    onClick={handleGenerateItems}
    startIcon={<Wand2 />}
    variant="contained"
    disabled={!title || isGeneratingItems}
    sx={{ flexGrow: 1 }}
  >
    {isGeneratingItems ? 'Generating...' : 'Generate List Items'}
  </Button>
  <Button
    onClick={handleAddItem}
    startIcon={<Plus />}
    variant="outlined"
  >
    Add Custom Item
  </Button>
  <Button
    onClick={() => setClearDialogOpen(true)}
    color="error"
    variant="outlined"
    disabled={listItems.length === 0}
    startIcon={<Trash2 />}
  >
    Clear All
  </Button>
</Box>


                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="listItems">
                      {(provided) => (
                        <List
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          sx={{ 
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            border: '1px solid',
                            borderColor: 'divider'
                          }}
                        >
                          {listItems.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <ListItem
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  sx={{
                                    borderBottom: '1px solid',
                                    borderColor: 'divider',
                                    '&:last-child': {
                                      borderBottom: 'none'
                                    }
                                  }}
                                >
                                  <Box sx={{ 
                                    display: 'flex', 
                                    alignItems: 'flex-start',
                                    width: '100%'
                                  }}>
                                    <Box
                                      {...provided.dragHandleProps}
                                      sx={{ 
                                        display: 'flex',
                                        alignItems: 'center',
                                        mr: 2,
                                        cursor: 'grab'
                                      }}
                                    >
                                      <GripVertical size={20} />
                                    </Box>

                                    <Box sx={{ flexGrow: 1 }}>
                                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                        <TextField
                                          fullWidth
                                          placeholder={`Item ${index + 1}`}
                                          value={item.title}
                                          onChange={(e) => handleUpdateItem(
                                            item.id,
                                            'title',
                                            e.target.value
                                          )}
                                          variant="standard"
                                          sx={{ mr: 2 }}
                                        />
                                        <Tooltip title="Expand item settings">
                                          <IconButton
                                            onClick={() => setExpandedItem(
                                              expandedItem === item.id ? null : item.id
                                            )}
                                            size="small"
                                          >
                                            {expandedItem === item.id ?
                                              <ChevronDown size={20} /> :
                                              <ChevronRight size={20} />
                                            }
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Generate image for this item">
                                          <IconButton
                                            onClick={() => handleUpdateItem(
                                              item.id,
                                              'generateImage',
                                              !item.generateImage
                                            )}
                                            size="small"
                                            color={item.generateImage ? "primary" : "default"}
                                          >
                                            <Image size={20} />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete item">
                                          <IconButton
                                            onClick={() => handleDeleteItem(item.id)}
                                            size="small"
                                            color="error"
                                          >
                                            <Trash2 size={20} />
                                          </IconButton>
                                        </Tooltip>
                                      </Box>

                                      <Collapse in={expandedItem === item.id}>
                                        <Box sx={{ mt: 2 }}>
                                          <TextField
                                            fullWidth
                                            multiline
                                            rows={2}
                                            label="Item Notes (optional)"
                                            placeholder="Add key points or details for this list item..."
                                            value={item.notes}
                                            onChange={(e) => handleUpdateItem(
                                              item.id,
                                              'notes',
                                              e.target.value
                                            )}
                                            sx={{ mb: 2 }}
                                          />
                                          <TextField
                                            type="number"
                                            label="Target Word Count"
                                            value={item.wordCount}
                                            onChange={(e) => handleUpdateItem(
                                              item.id,
                                              'wordCount',
                                              parseInt(e.target.value) || 0
                                            )}
                                            InputProps={{ inputProps: { min: 50 } }}
                                            sx={{ mb: 2 }}
                                          />
                                          {item.generateImage && (
                                            <Box sx={{ mt: 2 }}>
                                              <TextField
                                                fullWidth
                                                multiline
                                                rows={2}
                                                label="Image Prompt (optional)"
                                                placeholder="Leave blank for AI to generate based on item content"
                                                value={item.imagePrompt || ''}
                                                onChange={(e) => handleUpdateItem(
                                                  item.id,
                                                  'imagePrompt',
                                                  e.target.value
                                                )}
                                                sx={{ mb: 2 }}
                                              />
                                             <ImageControls
  model={item.imageModel || itemImageModel}
  setModel={(value) => handleUpdateItem(item.id, 'imageModel', value)}
  style={item.imageStyle || itemImageStyle}
  setStyle={(value) => handleUpdateItem(item.id, 'imageStyle', value)}
  dimension={item.imageDimension || dimension}
  setDimension={(value) => handleUpdateItem(item.id, 'imageDimension', value)}
  prompt={item.imagePrompt}
  setPrompt={(value) => handleUpdateItem(item.id, 'imagePrompt', value)}
  loading={loading}
/>
                                            </Box>
                                          )}
                                        </Box>
                                      </Collapse>
                                    </Box>
                                  </Box>
                                </ListItem>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </List>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Grid>

                {/* Error Display */}
                <Grid item xs={12}>
                  {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                      {error}
                    </Alert>
                  )}
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!title || listItems.length === 0 || loading}
                  >
                    {loading ? 'Generating Listicle...' : 'Generate Listicle'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

        {/* Preview Section */}
        <Grid item xs={12} md={5}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>
              Article Structure Preview
            </Typography>
            <Box sx={{ mb: 3 }}>
              <Typography 
                variant="h6" 
                color="primary" 
                gutterBottom 
                sx={{ mb: 3 }}
              >
                {title || 'Your Listicle Title'}
              </Typography>
              {loading ? (
  <Box sx={{ mb: 3 }}>
    <Typography 
      variant="subtitle1" 
      sx={{ mb: 2, fontWeight: 500 }}
    >
      Generation Progress:
    </Typography>
    {/* Only show steps that have been started */}
    {generationSteps.map((step) => 
      step.started && (
        <Box 
          key={step.id}
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 1,
            mb: 1.5,
            opacity: step.started ? 1 : 0,
            transition: 'opacity 0.3s ease-in',
            height: step.started ? 'auto' : 0,
          }}
        >
          {step.status === 'pending' && (
            <CircularProgress size={16} />
          )}
          {step.status === 'completed' && (
            <CheckCircle 
              size={16} 
              style={{ color: '#2e7d32' }} 
            />
          )}
          {step.status === 'error' && (
            <X 
              size={16}
              style={{ color: '#d32f2f' }}
            />
          )}
          <Typography 
            variant="body1" 
            sx={{ 
              fontSize: '1rem',
              color: step.status === 'error' ? 'error.main' : 
                     step.status === 'completed' ? 'success.main' : 
                     'text.secondary'
            }}
          >
            {step.text}
            {step.error && (
              <Typography 
                component="span" 
                color="error" 
                sx={{ ml: 1, fontSize: '0.875rem' }}
              >
                ({step.error})
              </Typography>
            )}
          </Typography>
        </Box>
      )
    )}
    {/* Show current status message below steps */}
    <Typography 
      variant="body1" 
      color="primary" 
      sx={{ mt: 2, fontSize: '1rem' }}
    >
      {generationStatus}
    </Typography>
  </Box>
) : (
              
                listItems.length > 0 ? (
                  <List>
                    {listItems.map((item, index) => (
                      <ListItem 
                        key={item.id} 
                        sx={{ 
                          px: 0,
                          mb: 2,
                          display: 'block'
                        }}
                      >
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            fontSize: '1.1rem',
                            fontWeight: 500,
                            mb: 1
                          }}
                        >
                          {`${index + 1}. ${item.title || 'Untitled Item'}`}
                        </Typography>
                        <Typography 
                          variant="body1" 
                          color="text.secondary"
                          sx={{ fontSize: '1rem' }}
                        >
                          {item.notes && (
                            <>
                              Notes: {item.notes}
                              <br />
                            </>
                          )}
                          Target: {item.wordCount} words
                          {item.generateImage && (
                            <>
                              <br />
                              {item.imagePrompt ? 
                                `Custom image prompt: ${item.imagePrompt}` : 
                                'AI-generated image enabled'
                              }
                            </>
                          )}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography 
                    color="text.secondary" 
                    sx={{ fontSize: '1rem' }}
                  >
                    No list items added yet. Click "Add List Item" to get started.
                  </Typography>
                )
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {/* Response Dialog */}
      <Dialog
        open={responseDialogOpen}
        onClose={() => setResponseDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          color: error ? 'error.main' : 'success.main'
        }}>
          {error ? (
            <>
              <AlertCircle size={24} />
              Generation Failed
            </>
          ) : (
            <>
              <CheckCircle size={24} />
              Listicle Generated Successfully
            </>
          )}
        </DialogTitle>
        
        <DialogContent>
          {error ? (
            <Alert severity="error" sx={{ mt: 1 }}>
              {error}
            </Alert>
          ) : (
            <Box sx={{ mt: 1 }}>
              <Typography variant="body1" gutterBottom>
                Your listicle has been successfully generated and uploaded to WordPress as a draft.
              </Typography>
              {generatedArticle && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2" sx={{ mb: 1 }}>
                    Article Details:
                  </Typography>
                  <Typography variant="body2">
                    Title: {generatedArticle.title}
                  </Typography>
                  <Typography variant="body2">
                    Status: Draft
                  </Typography>
                  {generatedArticle.link && (
                    <Typography variant="body2">
                      WordPress Link: <Link href={generatedArticle.link} target="_blank" rel="noopener">
                        View in WordPress
                      </Link>
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )}
        </DialogContent>

        <DialogActions sx={{ px: 3, pb: 2 }}>
          {!error && (
            <Button 
              onClick={() => {
                setResponseDialogOpen(false);
                setPreviewDialogOpen(true);
              }}
              variant="outlined"
              sx={{ mr: 'auto' }}
            >
              Preview Article
            </Button>
          )}
          <Button 
            onClick={() => setResponseDialogOpen(false)}
            variant={error ? "outlined" : "contained"}
          >
            {error ? 'Close' : 'Done'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview Dialog */}
      <Dialog
        open={previewDialogOpen}
        onClose={() => setPreviewDialogOpen(false)}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: { minHeight: '80vh' }
        }}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}>
          <Typography variant="h6">Article Preview</Typography>
          <IconButton onClick={() => setPreviewDialogOpen(false)} size="small">
            <X size={20} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {generatedArticle && (
            <>
              <Typography variant="h4" gutterBottom>
                {generatedArticle.title}
              </Typography>
              
              {generatedArticle.featuredImage && (
                <Box sx={{ my: 2 }}>
                  <img 
                    src={generatedArticle.featuredImage} 
                    alt={generatedArticle.title}
                    style={{ 
                      maxWidth: '100%',
                      height: 'auto',
                      borderRadius: '8px'
                    }} 
                  />
                </Box>
              )}

              <Box 
                sx={{ mt: 3 }}
                dangerouslySetInnerHTML={{ __html: generatedArticle.content }}
              />
            </>
          )}
        </DialogContent>

        <DialogActions sx={{ 
          borderTop: '1px solid',
          borderColor: 'divider',
          px: 3,
          py: 2
        }}>
          <Button onClick={() => setPreviewDialogOpen(false)}>
            Close Preview
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
  open={clearDialogOpen}
  onClose={() => setClearDialogOpen(false)}
  maxWidth="xs"
  fullWidth
>
  <DialogTitle sx={{ pb: 1 }}>
    Clear All List Items?
  </DialogTitle>
  <DialogContent>
    <Typography variant="body1">
      Are you sure you want to delete all list items? This action cannot be undone.
    </Typography>
  </DialogContent>
  <DialogActions sx={{ px: 3, pb: 2 }}>
    <Button 
      onClick={() => setClearDialogOpen(false)}
    >
      Cancel
    </Button>
    <Button
      onClick={() => {
        setListItems([]);
        setClearDialogOpen(false);
      }}
      variant="contained"
      color="error"
    >
      Clear All
    </Button>
  </DialogActions>
</Dialog>

      {/* Help Dialog */}
      <Dialog 
        open={helpDialogOpen}
        onClose={() => setHelpDialogOpen(false)}
        PaperProps={{
          sx: {
            position: 'fixed',
            right: 32,
            top: '50%',
            transform: 'translateY(-50%)',
            m: 0,
            width: 400,
            maxWidth: '90vw',
            maxHeight: '80vh',
            borderRadius: 2
          }
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          p: 2,
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}>
          <DialogTitle sx={{ p: 0, fontSize: '1.25rem' }}>
            How to Use Listicle Generator
          </DialogTitle>
          <IconButton onClick={() => setHelpDialogOpen(false)} size="small">
            <X size={18} />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
            Getting Started
          </Typography>
          <Typography variant="body2" sx={{ mb: 4 }}>
            Create engaging list-based articles with AI assistance. Start by entering your listicle title, then add and organize your list items. Each item can include detailed notes and custom images.
          </Typography>

          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
            Creating Your List Structure
          </Typography>
          <Typography variant="body2" sx={{ mb: 4 }}>
            1. Enter your listicle title
            2. Click "Add List Item" for each point
            3. Drag and drop to reorder items
            4. Add notes and word count targets
            5. Optionally enable images for items
          </Typography>

          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
            Customizing List Items
          </Typography>
          <Typography variant="body2" sx={{ mb: 4 }}>
            • Give each item a clear, descriptive title
            • Add notes to guide content generation
            • Set word count targets for consistent length
            • Enable and customize images for visual appeal
            • Use custom image prompts for better results
          </Typography>

          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 600 }}>
            Best Practices
          </Typography>
          <Typography variant="body2">
            • Use clear, engaging titles for your listicle
            • Keep item descriptions focused and specific
            • Balance item lengths for better readability
            • Add relevant notes for comprehensive coverage
            • Consider using images for key items
            • Enable search optimization for better visibility
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ListicleGenerator;